import {
	ArrowBendUpLeft,
	ArrowsClockwise,
	BookmarkSimple,
	BugBeetle,
	ClipboardText,
	type Icon,
	ImageSquare,
	Pencil,
	PushPin,
	Smiley,
	Snowflake,
	Trash,
} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import invariant from 'tiny-invariant';
import {MessageFlags, MessageStates, Permissions} from '~/Constants';
import * as ChannelPinActionCreators from '~/actions/ChannelPinsActionCreators';
import * as MessageActionCreators from '~/actions/MessageActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as PopoutActionCreators from '~/actions/PopoutActionCreators';
import * as ReactionActionCreators from '~/actions/ReactionActionCreators';
import * as SavedMessageActionCreators from '~/actions/SavedMessageActionCreators';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import styles from '~/components/channel/MessageActionBar.module.css';
import {ConfirmModal} from '~/components/modals/ConfirmModal';
import {DebugModal} from '~/components/modals/DebugModal';
import {PerformanceDebugModal} from '~/components/modals/PerformanceDebugModal';
import {EmojiPickerPopout} from '~/components/popouts/EmojiPickerPopout';
import {Popout} from '~/components/uikit/Popout/Popout';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import {MarkdownContext, parseWithMetrics} from '~/lib/markdown/renderers';
import type {MessageRecord} from '~/records/MessageRecord';
import ChannelStore from '~/stores/ChannelStore';
import GuildStore from '~/stores/GuildStore';
import SavedMessagesStore from '~/stores/SavedMessagesStore';
import UploadAttachmentStore from '~/stores/UploadAttachmentStore';
import messageStyles from '~/styles/Message.module.css';
import * as PermissionUtils from '~/utils/PermissionUtils';
import {toReactionEmoji} from '~/utils/ReactionUtils';

type MessageActionBarButtonProps = {
	label: string;
	icon: Icon;
	onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void;
	danger?: boolean;
	outline?: boolean;
	isActive?: boolean;
};

const MessageActionBarButton = React.forwardRef<HTMLDivElement, MessageActionBarButtonProps>((props, ref) => {
	const {label, icon: Icon, onClick, danger, outline, isActive} = props;

	const handleClick = (event: React.MouseEvent | React.KeyboardEvent) => {
		event.preventDefault();
		event.stopPropagation();
		onClick?.(event);
	};

	return (
		<Tooltip text={label}>
			<div
				ref={ref}
				aria-label={label}
				onClick={handleClick}
				onKeyDown={(event) => event.key === 'Enter' && handleClick(event)}
				role="button"
				tabIndex={0}
				className={clsx(styles.button, danger && styles.danger, isActive && styles.active)}
			>
				<Icon className={styles.actionBarIcon} weight={outline ? 'bold' : 'fill'} />
			</div>
		</Tooltip>
	);
});

MessageActionBarButton.displayName = 'MessageActionBarButton';

export const MessageActionBar = ({
	message,
	handleDelete,
}: {
	message: MessageRecord;
	handleDelete: (bypassConfirm?: boolean) => void;
}) => {
	const [emojiPickerOpen, setEmojiPickerOpen] = React.useState(false);
	const isSaved = SavedMessagesStore.useSavedMessage(message.id);
	const channel = ChannelStore.getChannel(message.channelId)!;
	const guild = GuildStore.getGuild(channel.guildId)!;
	const canSendMessages = PermissionUtils.can(Permissions.SEND_MESSAGES, {
		guildId: guild.id,
		channelId: message.channelId,
	});
	const canAddReactions = PermissionUtils.can(Permissions.ADD_REACTIONS, {
		guildId: guild.id,
		channelId: message.channelId,
	});
	const canManageMessages = PermissionUtils.can(Permissions.SEND_MESSAGES | Permissions.MANAGE_MESSAGES, {
		guildId: guild.id,
		channelId: message.channelId,
	});
	const shouldRenderSuppressEmbeds =
		message.isUserMessage() &&
		(canManageMessages || message.isCurrentUserAuthor()) &&
		(message.shouldSuppressEmbeds || message.embeds.length > 0);

	return (
		<div
			className={clsx(
				styles.actionBarContainer,
				messageStyles.buttons,
				emojiPickerOpen && messageStyles.emojiPickerOpen,
			)}
		>
			<div aria-label={i18n.Messages.MESSAGE_ACTIONS} className={styles.actionBar}>
				<MessageActionBarButton
					icon={BugBeetle}
					label="Debug Message Record"
					onClick={() => {
						ModalActionCreators.push(() => <DebugModal title="Message Record" data={message.toJSON()} />);
					}}
				/>
				{message.content && (
					<MessageActionBarButton
						icon={BugBeetle}
						label="Debug Message AST (with Performance)"
						onClick={() => {
							ModalActionCreators.push(() => {
								const {nodes, performanceMetrics} = parseWithMetrics({
									content: message.content,
									context: MarkdownContext.STANDARD_WITH_JUMBO,
								});
								invariant(performanceMetrics, 'Performance metrics are missing');
								return (
									<PerformanceDebugModal
										title="Message AST with Performance Metrics"
										astData={nodes}
										performanceMetrics={performanceMetrics}
									/>
								);
							});
						}}
					/>
				)}
				{message.state === MessageStates.SENT && (
					<>
						<MessageActionBarButton
							icon={Snowflake}
							outline={true}
							label={i18n.Messages.COPY_MESSAGE_ID}
							onClick={() => TextCopyActionCreators.copy(message.id)}
						/>
						{message.content && (
							<MessageActionBarButton
								icon={ClipboardText}
								label={i18n.Messages.COPY_MESSAGE}
								onClick={() => TextCopyActionCreators.copy(message.content)}
							/>
						)}
						{message.isUserMessage() && (
							<MessageActionBarButton
								icon={BookmarkSimple}
								label={isSaved ? i18n.Messages.UNSAVE_MESSAGE : i18n.Messages.SAVE_MESSAGE}
								outline={!isSaved}
								onClick={(event) => {
									if (isSaved) {
										SavedMessageActionCreators.remove(message.id);
									} else {
										SavedMessageActionCreators.create(message.channelId, message.id).then(() => {
											if (!event.shiftKey) {
												ComponentDispatch.dispatch('SAVED_MESSAGES_OPEN');
											}
										});
									}
								}}
							/>
						)}
						{shouldRenderSuppressEmbeds && (
							<MessageActionBarButton
								icon={ImageSquare}
								label={message.shouldSuppressEmbeds ? i18n.Messages.UNSUPPRESS_EMBEDS : i18n.Messages.SUPPRESS_EMBEDS}
								onClick={() => {
									if (message.flags & MessageFlags.SUPPRESS_EMBEDS) {
										MessageActionCreators.edit(message.channelId, message.id, {
											flags: message.flags & ~MessageFlags.SUPPRESS_EMBEDS,
										}).then(() => {
											ToastActionCreators.createToast({
												type: 'success',
												children: i18n.Messages.EMBEDS_UNSUPPRESSED,
											});
										});
									} else {
										MessageActionCreators.edit(message.channelId, message.id, {
											flags: message.flags | MessageFlags.SUPPRESS_EMBEDS,
										}).then(() => {
											ToastActionCreators.createToast({
												type: 'success',
												children: i18n.Messages.EMBEDS_SUPPRESSED,
											});
										});
									}
								}}
							/>
						)}
						{canAddReactions && (
							<Popout
								render={() => (
									<EmojiPickerPopout
										channelId={message.channelId}
										handleSelect={(emoji) => {
											ReactionActionCreators.addReaction(message.channelId, message.id, toReactionEmoji(emoji));
											PopoutActionCreators.close(`emoji-picker-${message.id}`);
										}}
									/>
								)}
								position="left-start"
								uniqueId={`emoji-picker-${message.id}`}
								animationType="none"
								onOpen={() => setEmojiPickerOpen(true)}
								onClose={() => setEmojiPickerOpen(false)}
							>
								<MessageActionBarButton icon={Smiley} label={i18n.Messages.ADD_REACTION} isActive={emojiPickerOpen} />
							</Popout>
						)}
						{message.isUserMessage() && canSendMessages && (
							<MessageActionBarButton
								icon={ArrowBendUpLeft}
								label={i18n.Messages.REPLY}
								onClick={(event) =>
									Dispatcher.dispatch({
										type: 'MESSAGE_REPLY_START',
										channelId: message.channelId,
										messageId: message.id,
										mentioning: !event.shiftKey,
									})
								}
							/>
						)}
						{message.isUserMessage() && canManageMessages && (
							<MessageActionBarButton
								icon={PushPin}
								label={message.flags & MessageFlags.PINNED ? i18n.Messages.UNPIN_MESSAGE : i18n.Messages.PIN_MESSAGE}
								onClick={(event) => {
									if (message.flags & MessageFlags.PINNED) {
										if (event.shiftKey) {
											ChannelPinActionCreators.unpin(message.channelId, message.id);
										} else {
											ModalActionCreators.push(() => (
												<ConfirmModal
													title={i18n.Messages.UNPIN_MESSAGE_MODAL_TITLE}
													description={i18n.Messages.UNPIN_MESSAGE_MODAL_DESCRIPTION}
													message={message}
													primaryText={i18n.Messages.UNPIN_MESSAGE_PRIMARY_ACTION}
													onPrimary={() => ChannelPinActionCreators.unpin(message.channelId, message.id)}
												/>
											));
										}
									} else if (event.shiftKey) {
										ChannelPinActionCreators.pin(message.channelId, message.id);
									} else {
										ModalActionCreators.push(() => (
											<ConfirmModal
												title={i18n.Messages.PIN_MESSAGE_MODAL_TITLE}
												description={i18n.Messages.PIN_MESSAGE_MODAL_DESCRIPTION}
												message={message}
												primaryText={i18n.Messages.PIN_MESSAGE_PRIMARY_ACTION}
												primaryVariant="brand"
												onPrimary={() => ChannelPinActionCreators.pin(message.channelId, message.id)}
											/>
										));
									}
								}}
							/>
						)}
						{message.isCurrentUserAuthor() && message.isUserMessage() && (
							<MessageActionBarButton
								icon={Pencil}
								label={i18n.Messages.EDIT_MESSAGE}
								onClick={() =>
									Dispatcher.dispatch({
										type: 'MESSAGE_EDIT_START',
										channelId: message.channelId,
										messageId: message.id,
									})
								}
							/>
						)}
						{(message.isCurrentUserAuthor() || canManageMessages) && (
							<MessageActionBarButton
								danger={true}
								icon={Trash}
								label={i18n.Messages.DELETE_MESSAGE}
								onClick={(event) => handleDelete(event.shiftKey)}
							/>
						)}
					</>
				)}
				{message.state === MessageStates.FAILED && (
					<>
						<MessageActionBarButton
							icon={ArrowsClockwise}
							label={i18n.Messages.RETRY}
							onClick={() => {
								if (!message.nonce) {
									return;
								}
								MessageActionCreators.send(message.channelId, {
									content: message.content,
									nonce: message.nonce,
									uploadAttachments: UploadAttachmentStore.getUploadAttachments(message.channelId),
									allowedMentions: message._allowedMentions,
									messageReference: message.messageReference,
									flags: message.flags,
								});
							}}
						/>
						<MessageActionBarButton
							danger={true}
							icon={Trash}
							label={i18n.Messages.DELETE_MESSAGE}
							onClick={() =>
								Dispatcher.dispatch({
									type: 'MESSAGE_DELETE',
									channelId: message.channelId,
									messageId: message.id,
								})
							}
						/>
					</>
				)}
			</div>
		</div>
	);
};
