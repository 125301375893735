import punycode from 'punycode/';

/**
 * Utility functions for URL validation and processing
 */

// Pre-compile regex pattern for better performance
const EMAIL_REGEX =
	/^[a-zA-Z0-9._%+\-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Cache commonly used protocol strings
const HTTP_PROTOCOL = 'http:';
const HTTPS_PROTOCOL = 'https:';
const MAILTO_PROTOCOL = 'mailto:';

/**
 * Validates a URL string
 *
 * @param urlStr - URL string to validate
 * @returns Whether the URL is valid
 */
export function isValidUrl(urlStr: string): boolean {
	// Early return for obvious invalid cases
	if (!urlStr || typeof urlStr !== 'string') return false;

	try {
		const url = new URL(urlStr);
		const protocol = url.protocol;
		// Use direct comparison instead of multiple conditions
		return protocol === HTTP_PROTOCOL || protocol === HTTPS_PROTOCOL || protocol === MAILTO_PROTOCOL;
	} catch {
		return false;
	}
}

/**
 * Validates an email address
 * Uses a simplified regex that doesn't allow quoted parts to prevent spoofing
 *
 * @param email - Email address to validate
 * @returns Whether the email is valid
 */
export function isValidEmail(email: string): boolean {
	// Early return for obvious invalid cases
	if (!email || typeof email !== 'string') return false;

	// Use pre-compiled regex for better performance
	return EMAIL_REGEX.test(email);
}

/**
 * Converts a URL to ASCII representation of its domain
 * (Handles internationalized domain names)
 *
 * @param target - URL to convert
 * @returns URL with ASCII domain
 */
export function convertToAsciiUrl(target: string): string {
	// Early return for obvious invalid cases
	if (!target || typeof target !== 'string') return target;

	try {
		const url = new URL(target);
		const hostname = url.hostname;
		url.hostname = punycode.toASCII(hostname);
		return url.toString();
	} catch {
		return target;
	}
}
