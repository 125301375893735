import {type ListItem, type ListNode, type Node, NodeType} from '../types';
import {MAX_AST_NODES} from '../types/constants';
import {performanceMetrics} from '../utils/performance-metrics';
import {parseCodeBlock} from './block-parsers';

/**
 * Result of parsing a list
 */
export interface ListParseResult {
	node: ListNode;
	newLineIndex: number;
	newNodeCount: number;
}

/**
 * Parses a list (ordered or unordered)
 *
 * @param lines - All lines of the input
 * @param currentLineIndex - The current line being processed
 * @param isOrdered - Whether this is an ordered list
 * @param indentLevel - The indentation level of the list
 * @param depth - The nesting depth of the list
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param nodeCount - Current count of nodes created
 * @param parseInline - Function to parse inline content
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parsed list node, updated line index, and updated node count
 */
export function parseList(
	lines: Array<string>,
	currentLineIndex: number,
	isOrdered: boolean,
	indentLevel: number,
	depth: number,
	parserFlags: number,
	nodeCount: number,
	parseInline: (text: string) => Array<Node>,
	collectMetrics = false,
): ListParseResult {
	if (collectMetrics) {
		performanceMetrics.startOperation('ListParsers.parseList');
	}

	const items: Array<ListItem> = [];
	const startLine = currentLineIndex;
	const firstLineContent = lines[startLine];
	let newLineIndex = currentLineIndex;
	let newNodeCount = nodeCount;

	while (newLineIndex < lines.length) {
		if (newNodeCount > MAX_AST_NODES) break;
		const currentLine = lines[newLineIndex];
		const trimmed = currentLine.trimStart();

		// Check if we've hit a block that should break the list
		if (isBlockBreak(trimmed)) break;

		if (collectMetrics) {
			performanceMetrics.startOperation('ListParsers.matchListItem');
		}

		const listMatch = matchListItem(currentLine);

		if (collectMetrics) {
			performanceMetrics.endOperation('ListParsers.matchListItem');
		}

		if (listMatch) {
			const [itemOrdered, itemIndent, content] = listMatch;

			// If indentation level is less than the list's, we're done with this list
			if (itemIndent < indentLevel) break;

			// If at the same indentation level, it should be an item in this list
			if (itemIndent === indentLevel) {
				// If ordered status doesn't match, we're done with this list
				// Unless it's the first item, in which case we'll create a simple list
				if (itemOrdered !== isOrdered) {
					if (newLineIndex === startLine) {
						const simpleList = createSimpleList(firstLineContent);

						if (collectMetrics) {
							performanceMetrics.endOperation('ListParsers.parseList');
						}

						return {
							node: simpleList,
							newLineIndex: newLineIndex + 1,
							newNodeCount: newNodeCount + 1,
						};
					}
					break;
				}

				// Parse this item
				if (collectMetrics) {
					performanceMetrics.startOperation('ListParsers.handleSameIndentLevel');
				}

				const result = handleSameIndentLevel(
					items,
					content,
					indentLevel,
					depth,
					parseInline,
					(parentIndent, depth) => {
						if (collectMetrics) {
							performanceMetrics.startOperation('ListParsers.tryParseNestedContent');
						}

						const tryResult = tryParseNestedContent(
							lines,
							newLineIndex + 1,
							parentIndent,
							depth,
							(isOrdered, indentLevel, depth) =>
								parseList(
									lines,
									newLineIndex + 1,
									isOrdered,
									indentLevel,
									depth,
									parserFlags,
									newNodeCount,
									parseInline,
									collectMetrics,
								),
							collectMetrics,
						);

						if (collectMetrics) {
							performanceMetrics.endOperation('ListParsers.tryParseNestedContent');
						}

						return tryResult;
					},
					newLineIndex,
					collectMetrics,
				);

				if (collectMetrics) {
					performanceMetrics.endOperation('ListParsers.handleSameIndentLevel');
				}

				newLineIndex = result.newLineIndex;
				newNodeCount = result.newNodeCount;
			}
			// If at a deeper indentation level, it's a nested list
			else if (itemIndent === indentLevel + 1) {
				// Handle nested list
				if (collectMetrics) {
					performanceMetrics.startOperation('ListParsers.handleNestedIndentLevel');
				}

				const result = handleNestedIndentLevel(
					items,
					currentLine,
					itemOrdered,
					itemIndent,
					depth,
					(isOrdered, indentLevel, depth) =>
						parseList(
							lines,
							newLineIndex,
							isOrdered,
							indentLevel,
							depth,
							parserFlags,
							newNodeCount,
							parseInline,
							collectMetrics,
						),
					newLineIndex,
					newNodeCount,
					collectMetrics,
				);

				if (collectMetrics) {
					performanceMetrics.endOperation('ListParsers.handleNestedIndentLevel');
				}

				newLineIndex = result.newLineIndex;
				newNodeCount = result.newNodeCount;
			}
			// If indentation is too deep, break out
			else {
				break;
			}
		}
		// Handle bullet points at the root level
		else if (isBulletPointText(currentLine)) {
			if (collectMetrics) {
				performanceMetrics.startOperation('ListParsers.handleBulletPointText');
			}

			const result = handleBulletPointText(items, currentLine, newLineIndex, newNodeCount);

			if (collectMetrics) {
				performanceMetrics.endOperation('ListParsers.handleBulletPointText');
			}

			newLineIndex = result.newLineIndex;
			newNodeCount = result.newNodeCount;
		}
		// Handle list item continuations (indented text)
		else if (isListContinuation(currentLine, indentLevel)) {
			if (collectMetrics) {
				performanceMetrics.startOperation('ListParsers.handleListContinuation');
			}

			const result = handleListContinuation(items, currentLine, newLineIndex, newNodeCount);

			if (collectMetrics) {
				performanceMetrics.endOperation('ListParsers.handleListContinuation');
			}

			newLineIndex = result.newLineIndex;
			newNodeCount = result.newNodeCount;
		}
		// If none of the above, we're done with this list
		else {
			break;
		}

		// Prevent excessive list length
		if (items.length > MAX_AST_NODES) break;
	}

	// If we didn't add any items and we're still at the start line,
	// return a simple list with just the content of the first line
	if (items.length === 0 && newLineIndex === startLine) {
		const simpleList = createSimpleList(firstLineContent);

		if (collectMetrics) {
			performanceMetrics.endOperation('ListParsers.parseList');
		}

		return {
			node: simpleList,
			newLineIndex: newLineIndex + 1,
			newNodeCount: newNodeCount + 1,
		};
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('ListParsers.parseList');
	}

	return {
		node: {
			type: NodeType.List,
			ordered: isOrdered,
			items,
		},
		newLineIndex,
		newNodeCount,
	};
}

/**
 * Checks if a line starts a block element that would break a list
 */
function isBlockBreak(trimmed: string): boolean {
	return (
		trimmed.startsWith('#') ||
		// trimmed.startsWith('```') ||
		trimmed.startsWith('>') ||
		trimmed.startsWith('>>> ')
	);
}

/**
 * Creates a simple list with a single item containing just text
 */
export function createSimpleList(content: string): ListNode {
	return {
		type: NodeType.List,
		ordered: false,
		items: [{children: [{type: NodeType.Text, content}]}],
	};
}

/**
 * Handles a list item at the same indentation level as the current list
 */
export function handleSameIndentLevel(
	items: Array<ListItem>,
	content: string,
	indentLevel: number,
	depth: number,
	parseInline: (text: string) => Array<Node>,
	tryParseNestedContent: (parentIndent: number, depth: number) => {node: Node | null; newLineIndex: number},
	currentLineIndex: number,
	collectMetrics = false,
): {newItems: Array<ListItem>; newLineIndex: number; newNodeCount: number} {
	// Parse the content of this item as inline elements
	if (collectMetrics) {
		performanceMetrics.startOperation('ListParsers.parseInlineForListItem');
	}

	const itemNodes = parseInline(content);

	if (collectMetrics) {
		performanceMetrics.endOperation('ListParsers.parseInlineForListItem');
	}

	// Check if there's nested content following this item
	const nestedContent = tryParseNestedContent(indentLevel, depth);
	let newNodeCount = itemNodes.length;
	let newLineIndex = currentLineIndex + 1;

	if (nestedContent.node) {
		// Add the nested content to the current list item
		itemNodes.push(nestedContent.node);
		newNodeCount++;
		newLineIndex = nestedContent.newLineIndex;
	}

	// Add this item to the list
	items.push({children: itemNodes});

	return {
		newItems: items,
		newLineIndex,
		newNodeCount,
	};
}

/**
 * Handles a list item at a deeper indentation level than the current list
 */
export function handleNestedIndentLevel(
	items: Array<ListItem>,
	currentLine: string,
	isOrdered: boolean,
	indentLevel: number,
	depth: number,
	parseList: (isOrdered: boolean, indentLevel: number, depth: number) => ListParseResult,
	currentLineIndex: number,
	nodeCount: number,
	_collectMetrics = false,
): {newItems: Array<ListItem>; newLineIndex: number; newNodeCount: number} {
	// If we're too deep, just add the line as text to the last item
	if (depth >= 5) {
		if (items.length > 0) {
			items[items.length - 1].children.push({
				type: NodeType.Text,
				content: currentLine.trim(),
			});
			return {
				newItems: items,
				newLineIndex: currentLineIndex + 1,
				newNodeCount: nodeCount + 1,
			};
		}
		return {
			newItems: items,
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount,
		};
	}

	// Otherwise, parse the nested list
	const nested = parseList(isOrdered, indentLevel, depth + 1);

	// Add the nested list to the last item if there is one
	if (items.length > 0) {
		items[items.length - 1].children.push(nested.node);
	}

	return {
		newItems: items,
		newLineIndex: nested.newLineIndex,
		newNodeCount: nested.newNodeCount,
	};
}

/**
 * Handles bullet point text at the root level
 */
export function handleBulletPointText(
	items: Array<ListItem>,
	currentLine: string,
	currentLineIndex: number,
	nodeCount: number,
): {newItems: Array<ListItem>; newLineIndex: number; newNodeCount: number} {
	// Add the text to the last item if there is one
	if (items.length > 0) {
		items[items.length - 1].children.push({
			type: NodeType.Text,
			content: currentLine.trim(),
		});
		return {
			newItems: items,
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};
	}

	return {
		newItems: items,
		newLineIndex: currentLineIndex + 1,
		newNodeCount: nodeCount,
	};
}

/**
 * Handles indented text that continues a list item
 */
export function handleListContinuation(
	items: Array<ListItem>,
	currentLine: string,
	currentLineIndex: number,
	nodeCount: number,
): {newItems: Array<ListItem>; newLineIndex: number; newNodeCount: number} {
	// Add the text to the last item if there is one
	if (items.length > 0) {
		items[items.length - 1].children.push({
			type: NodeType.Text,
			content: currentLine.trimStart(),
		});
		return {
			newItems: items,
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};
	}

	return {
		newItems: items,
		newLineIndex: currentLineIndex + 1,
		newNodeCount: nodeCount,
	};
}

/**
 * Tries to parse nested content after a list item
 */
export function tryParseNestedContent(
	lines: Array<string>,
	currentLineIndex: number,
	parentIndent: number,
	depth: number,
	parseList: (isOrdered: boolean, indentLevel: number, depth: number) => ListParseResult,
	collectMetrics = false,
): {node: Node | null; newLineIndex: number} {
	if (currentLineIndex >= lines.length) return {node: null, newLineIndex: currentLineIndex};

	const line = lines[currentLineIndex];
	const trimmed = line.trimStart();

	// Check for code blocks inside list items
	if (trimmed.startsWith('```')) {
		if (collectMetrics) {
			performanceMetrics.startOperation('BlockParsers.parseCodeBlock');
		}

		const result = parseCodeBlock(lines, currentLineIndex);

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseCodeBlock');
		}

		return {
			node: result.node,
			newLineIndex: result.newLineIndex,
		};
	}

	if (collectMetrics) {
		performanceMetrics.startOperation('ListParsers.matchListItem for nested');
	}

	const listMatch = matchListItem(line);

	if (collectMetrics) {
		performanceMetrics.endOperation('ListParsers.matchListItem for nested');
	}

	if (listMatch) {
		const [isOrdered, indent, _] = listMatch;
		if (indent > parentIndent && depth < 5) {
			const result = parseList(isOrdered, indent, depth + 1);
			return {
				node: result.node,
				newLineIndex: result.newLineIndex,
			};
		}
	}

	return {node: null, newLineIndex: currentLineIndex};
}

/**
 * Checks if a line is a continuation of a list item
 * This is determined by the indentation level
 */
export function isListContinuation(line: string, indentLevel: number): boolean {
	// Count leading spaces
	let spaceCount = 0;
	for (let i = 0; i < line.length; i++) {
		if (line[i] === ' ') spaceCount++;
		else break;
	}
	return spaceCount > indentLevel * 2;
}

/**
 * Checks if a line is a bullet point at the root level
 */
export function isBulletPointText(text: string): boolean {
	const trimmed = text.trimStart();
	return trimmed.startsWith('- ') && !text.startsWith('  ');
}

/**
 * Matches a list item and returns its properties
 * Returns [isOrdered, indentLevel, content] if matched, null otherwise
 */
export function matchListItem(line: string): [boolean, number, string] | null {
	let indent = 0;
	let pos = 0;

	// Count leading spaces
	while (pos < line.length && line[pos] === ' ') {
		indent++;
		pos++;
	}

	// The indentation must be even (multiple of 2)
	if (indent > 0 && indent < 2) return null;
	const indentLevel = Math.floor(indent / 2);

	// Check if we've reached the end of the line
	if (pos >= line.length) return null;

	const marker = line[pos];
	if (marker === '*' || marker === '-') {
		return handleUnorderedListMarker(line, pos, indentLevel);
	}
	if (/[0-9]/.test(marker)) {
		return handleOrderedListMarker(line, pos, indentLevel);
	}

	return null;
}

/**
 * Handles an unordered list marker (* or -)
 */
export function handleUnorderedListMarker(
	line: string,
	pos: number,
	indentLevel: number,
): [boolean, number, string] | null {
	// The marker must be followed by a space
	if (line[pos + 1] === ' ') {
		return [false, indentLevel, line.slice(pos + 2)];
	}
	return null;
}

/**
 * Handles an ordered list marker (1., 2., etc.)
 */
export function handleOrderedListMarker(
	line: string,
	pos: number,
	indentLevel: number,
): [boolean, number, string] | null {
	let currentPos = pos;

	// Read all digits
	while (currentPos < line.length && /[0-9]/.test(line[currentPos])) {
		currentPos++;
	}

	// The number must be followed by a period and a space
	if (line[currentPos] === '.' && line[currentPos + 1] === ' ') {
		return [true, indentLevel, line.slice(currentPos + 2)];
	}

	return null;
}
