import {type Node, NodeType, TableAlignment, type TableCellNode, type TableNode, type TableRowNode} from '../types';
import {performanceMetrics} from '../utils/performance-metrics';

export interface TableParseResult {
	node: TableNode | null;
	newLineIndex: number;
}

// Character codes for faster comparisons
const PIPE = 124; // '|'
const SPACE = 32; // ' '
const BACKSLASH = 92; // '\'
const DASH = 45; // '-'
const COLON = 58; // ':'
const HASH = 35; // '#'
const GREATER_THAN = 62; // '>'
const ASTERISK = 42; // '*'
const DIGIT_0 = 48; // '0'
const DIGIT_9 = 57; // '9'
const PERIOD = 46; // '.'

// Maximum cache size to prevent memory leaks
const MAX_CACHE_SIZE = 1000;

// Cache for parsed inline content to avoid repeated parsing of identical content
const inlineContentCache = new Map<string, Array<Node>>();

/**
 * Main entry point for parsing tables
 *
 * @param lines - Array of text lines
 * @param currentLineIndex - Current line index in the array
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param parseInline - Function to parse inline content
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Table parse result with node and new line index
 */
export function parseTable(
	lines: Array<string>,
	currentLineIndex: number,
	_parserFlags: number,
	parseInline: (text: string) => Array<Node>,
	collectMetrics = false,
): TableParseResult {
	if (collectMetrics) {
		performanceMetrics.startOperation('TableParsers.parseTable');
	}

	const startIndex = currentLineIndex;

	// Fast path: Check if we have at least 2 more lines
	if (startIndex + 2 >= lines.length) {
		if (collectMetrics) {
			performanceMetrics.endOperation('TableParsers.parseTable');
		}
		return {node: null, newLineIndex: currentLineIndex};
	}

	// Fast path: Check if basic structure looks like a table
	const headerLine = lines[currentLineIndex];
	const alignmentLine = lines[currentLineIndex + 1];

	if (!containsPipe(headerLine) || !containsPipe(alignmentLine)) {
		if (collectMetrics) {
			performanceMetrics.endOperation('TableParsers.parseTable');
		}
		return {node: null, newLineIndex: currentLineIndex};
	}

	try {
		// Parse header row
		if (collectMetrics) {
			performanceMetrics.startOperation('TableParsers.parseTableRow for header');
		}

		const headerCells = fastSplitTableCells(headerLine.trim());
		// Fast check if any cell has content
		if (headerCells.length === 0 || !hasContent(headerCells)) {
			if (collectMetrics) {
				performanceMetrics.endOperation('TableParsers.parseTableRow for header');
				performanceMetrics.endOperation('TableParsers.parseTable');
			}
			return {node: null, newLineIndex: currentLineIndex};
		}

		const headerRow = createTableRow(headerCells, parseInline);

		if (collectMetrics) {
			performanceMetrics.endOperation('TableParsers.parseTableRow for header');
		}

		const columnCount = headerRow.cells.length;
		currentLineIndex++;

		// Parse alignment row - properly validate format
		if (collectMetrics) {
			performanceMetrics.startOperation('TableParsers.parseTableAlignments');
		}

		const alignmentCells = fastSplitTableCells(alignmentLine.trim());

		// Check each alignment cell format
		if (!validateAlignmentRow(alignmentCells)) {
			if (collectMetrics) {
				performanceMetrics.endOperation('TableParsers.parseTableAlignments');
				performanceMetrics.endOperation('TableParsers.parseTable');
			}
			return {node: null, newLineIndex: startIndex};
		}

		const alignments = parseAlignments(alignmentCells);

		if (collectMetrics) {
			performanceMetrics.endOperation('TableParsers.parseTableAlignments');
		}

		if (!alignments || headerRow.cells.length !== alignments.length) {
			if (collectMetrics) {
				performanceMetrics.endOperation('TableParsers.parseTable');
			}
			return {node: null, newLineIndex: startIndex};
		}

		currentLineIndex++;

		// Parse data rows
		const rows: Array<TableRowNode> = [];

		if (collectMetrics) {
			performanceMetrics.startOperation('TableParsers.parseDataRows');
		}

		// Keep parsing rows until we hit a non-table line
		while (currentLineIndex < lines.length) {
			const line = lines[currentLineIndex];

			// Fast check if line doesn't contain a pipe
			if (!containsPipe(line)) break;

			// Quick check for block breaks without full regex for performance
			const trimmed = line.trim();
			if (isBlockBreakFast(trimmed)) break;

			// Parse the row
			if (collectMetrics) {
				performanceMetrics.startOperation('TableParsers.parseTableRow for data');
			}

			const cellContents = fastSplitTableCells(trimmed);

			// Ensure correct number of cells
			if (cellContents.length !== columnCount) {
				normalizeColumnCount(cellContents, columnCount);
			}

			const row = createTableRow(cellContents, parseInline);

			if (collectMetrics) {
				performanceMetrics.endOperation('TableParsers.parseTableRow for data');
			}

			rows.push(row);
			currentLineIndex++;
		}

		if (collectMetrics) {
			performanceMetrics.endOperation('TableParsers.parseDataRows');
		}

		// Valid table should have at least one data row
		if (rows.length === 0) {
			if (collectMetrics) {
				performanceMetrics.endOperation('TableParsers.parseTable');
			}
			return {node: null, newLineIndex: startIndex};
		}

		// Check if ANY cell has content (for empty table validation)
		let hasAnyContent = hasRowContent(headerRow);

		if (!hasAnyContent) {
			for (const row of rows) {
				if (hasRowContent(row)) {
					hasAnyContent = true;
					break;
				}
			}
		}

		if (!hasAnyContent) {
			if (collectMetrics) {
				performanceMetrics.endOperation('TableParsers.parseTable');
			}
			return {node: null, newLineIndex: startIndex};
		}

		if (collectMetrics) {
			performanceMetrics.endOperation('TableParsers.parseTable');
		}

		// Clear cache periodically to prevent memory leaks
		if (inlineContentCache.size > MAX_CACHE_SIZE) {
			inlineContentCache.clear();
		}

		return {
			node: {
				type: NodeType.Table,
				header: headerRow,
				alignments: alignments,
				rows,
			},
			newLineIndex: currentLineIndex,
		};
	} catch (_err) {
		// Reset the line index and return null on any error
		if (collectMetrics) {
			performanceMetrics.endOperation('TableParsers.parseTable');
		}
		return {node: null, newLineIndex: startIndex};
	}
}

/**
 * Fast check to see if string contains a pipe character
 */
function containsPipe(text: string): boolean {
	return text.indexOf('|') !== -1;
}

/**
 * Fast check to see if any cell in the array has content
 */
function hasContent(cells: Array<string>): boolean {
	for (const cell of cells) {
		if (cell.trim().length > 0) {
			return true;
		}
	}
	return false;
}

/**
 * Check if a table row has any meaningful content
 */
function hasRowContent(row: TableRowNode): boolean {
	for (const cell of row.cells) {
		if (
			cell.children.length > 0 &&
			!(cell.children.length === 1 && cell.children[0].type === NodeType.Text && cell.children[0].content.trim() === '')
		) {
			return true;
		}
	}
	return false;
}

/**
 * Validate that the alignment row has the proper format
 */
function validateAlignmentRow(cells: Array<string>): boolean {
	if (cells.length === 0) return false;

	for (const cell of cells) {
		const trimmed = cell.trim();

		// Must contain at least one dash
		if (trimmed.length === 0 || trimmed.indexOf('-') === -1) {
			return false;
		}

		// Only allowed characters: spaces, colons, dashes, and pipes
		for (let i = 0; i < trimmed.length; i++) {
			const charCode = trimmed.charCodeAt(i);
			// space (32), colon (58), dash (45), pipe (124)
			if (charCode !== SPACE && charCode !== COLON && charCode !== DASH && charCode !== PIPE) {
				return false;
			}
		}
	}

	return true;
}

/**
 * Faster cell splitting that doesn't use regex
 */
function fastSplitTableCells(line: string): Array<string> {
	// Remove leading/trailing pipes
	let start = 0;
	let end = line.length;

	if (line.length > 0 && line.charCodeAt(0) === PIPE) {
		start = 1;
	}

	if (line.length > 0 && end > start && line.charCodeAt(end - 1) === PIPE) {
		end--;
	}

	if (start >= end) {
		return [];
	}

	const content = line.substring(start, end);
	const cells: Array<string> = [];
	let currentCell = '';
	let i = 0;

	while (i < content.length) {
		// Handle escaped pipes
		if (content.charCodeAt(i) === BACKSLASH && i + 1 < content.length && content.charCodeAt(i + 1) === PIPE) {
			currentCell += '|';
			i += 2;
			continue;
		}

		// Handle pipe as delimiter
		if (content.charCodeAt(i) === PIPE) {
			cells.push(currentCell);
			currentCell = '';
			i++;
			continue;
		}

		// Regular character
		currentCell += content[i];
		i++;
	}

	cells.push(currentCell);
	return cells;
}

/**
 * Parse alignments without regex for better performance
 */
function parseAlignments(cells: Array<string>): Array<TableAlignment> | null {
	if (cells.length === 0) return null;

	const alignments: Array<TableAlignment> = [];

	for (const cell of cells) {
		const trimmed = cell.trim();
		if (!trimmed || trimmed.indexOf('-') === -1) return null;

		const left = trimmed.charCodeAt(0) === COLON;
		const right = trimmed.charCodeAt(trimmed.length - 1) === COLON;

		if (left && right) {
			alignments.push(TableAlignment.Center);
		} else if (left) {
			alignments.push(TableAlignment.Left);
		} else if (right) {
			alignments.push(TableAlignment.Right);
		} else {
			alignments.push(TableAlignment.None);
		}
	}

	return alignments;
}

/**
 * Creates a table row efficiently from cell contents
 */
function createTableRow(cellContents: Array<string>, parseInline: (text: string) => Array<Node>): TableRowNode {
	const cells: Array<TableCellNode> = [];

	for (const cellContent of cellContents) {
		const trimmed = cellContent.trim();

		// Use cached parsed content if available
		let inlineNodes: Array<Node>;
		if (inlineContentCache.has(trimmed)) {
			inlineNodes = inlineContentCache.get(trimmed)!;
		} else {
			inlineNodes = parseInline(trimmed);
			// Cache the result for future use
			inlineContentCache.set(trimmed, inlineNodes);
		}

		cells.push({
			type: NodeType.TableCell,
			children: inlineNodes.length > 0 ? inlineNodes : [{type: NodeType.Text, content: trimmed}],
		});
	}

	return {type: NodeType.TableRow, cells};
}

/**
 * Normalize column count - more efficient than redistributing
 */
function normalizeColumnCount(cells: Array<string>, expectedColumns: number): void {
	if (cells.length > expectedColumns) {
		// Combine excess cells into the last column
		const lastCellIndex = expectedColumns - 1;
		cells[lastCellIndex] = `${cells[lastCellIndex]}|${cells.slice(expectedColumns).join('|')}`;
		cells.length = expectedColumns;
	} else {
		// Add empty cells if needed
		while (cells.length < expectedColumns) {
			cells.push('');
		}
	}
}

/**
 * Fast check for block breaks using character codes instead of regex
 */
function isBlockBreakFast(text: string): boolean {
	if (!text || text.length === 0) return false;

	const firstChar = text.charCodeAt(0);

	// # (35), > (62), - (45), * (42)
	if (firstChar === HASH || firstChar === GREATER_THAN || firstChar === DASH || firstChar === ASTERISK) {
		return true;
	}

	// Check for ">>> "
	if (
		text.length >= 4 &&
		text.charCodeAt(0) === GREATER_THAN &&
		text.charCodeAt(1) === GREATER_THAN &&
		text.charCodeAt(2) === GREATER_THAN &&
		text.charCodeAt(3) === SPACE
	) {
		return true;
	}

	// Check for "-#"
	if (text.length >= 2 && text.charCodeAt(0) === DASH && text.charCodeAt(1) === HASH) {
		return true;
	}

	// Quick check for numbered list (digit followed by period)
	if (firstChar >= DIGIT_0 && firstChar <= DIGIT_9) {
		for (let i = 1; i < Math.min(text.length, 4); i++) {
			if (text.charCodeAt(i) === PERIOD) {
				return true;
			}
		}
	}

	return false;
}
