import {Parser} from '../parser/parser';
import {
	type AlertNode,
	AlertType,
	type CodeBlockNode,
	type HeadingNode,
	type Node,
	NodeType,
	ParserFlags,
	type SpoilerNode,
	type SubtextNode,
	type TextNode,
} from '../types';
import {MAX_AST_NODES, MAX_LINE_LENGTH} from '../types/constants';
import {flattenChildren} from '../utils/ast-utils';
import {performanceMetrics} from '../utils/performance-metrics';
import * as InlineParsers from './inline-parsers';
import * as ListParsers from './list-parsers';
import * as TableParsers from './table-parsers';

// Pre-compile frequently used regular expressions
const ALERT_PATTERN = /^\[!([A-Z]+)\]\s*\n?/;

/**
 * Result of parsing a block element
 */
export interface BlockParseResult {
	node: Node | null;
	newLineIndex: number;
	newNodeCount: number;
}

/**
 * Cache for common string operations to avoid redundant processing
 */
const stringCache = new Map<string, boolean>();

/**
 * Cached string check for better performance
 */
function cachedStartsWith(str: string, search: string): boolean {
	const key = `${str}:${search}:startsWith`;
	if (!stringCache.has(key)) {
		stringCache.set(key, str.startsWith(search));
	}
	return stringCache.get(key)!;
}

/**
 * Clear string cache when done parsing to prevent memory leaks
 */
export function clearCache(): void {
	stringCache.clear();
}

/**
 * Determines which block parser to use and parses the current block
 *
 * @param parser - Not used in this function but matches expected interface
 * @param lines - All lines of the input
 * @param currentLineIndex - The current line being processed
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param nodeCount - Current count of nodes created
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parsed node, updated line index, and updated node count
 */
export function parseBlock(
	_parser: Parser,
	lines: Array<string>,
	currentLineIndex: number,
	parserFlags: number,
	nodeCount: number,
	collectMetrics = false,
): BlockParseResult {
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseBlock');
	}

	// Early return check
	if (currentLineIndex >= lines.length) {
		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlock');
		}
		return {node: null, newLineIndex: currentLineIndex, newNodeCount: nodeCount};
	}

	const line = lines[currentLineIndex];
	const trimmed = line.trimStart();

	// Check for multiline blockquotes
	if (cachedStartsWith(trimmed, '>>> ')) {
		if (!(parserFlags & ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES)) {
			const result = {
				node: parseBlockAsText(lines, currentLineIndex, '>>> '),
				newLineIndex: currentLineIndex + 1,
				newNodeCount: nodeCount + 1,
			};
			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseBlock');
			}
			return result;
		}
		const result = parseMultilineBlockquote(lines, currentLineIndex, parserFlags, nodeCount, collectMetrics);
		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlock');
		}
		return result;
	}

	// Check for single-line blockquotes
	if (cachedStartsWith(trimmed, '>')) {
		if (!(parserFlags & ParserFlags.ALLOW_BLOCKQUOTES)) {
			const result = {
				node: parseBlockAsText(lines, currentLineIndex, '>'),
				newLineIndex: currentLineIndex + 1,
				newNodeCount: nodeCount + 1,
			};
			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseBlock');
			}
			return result;
		}
		const result = parseBlockquote(lines, currentLineIndex, parserFlags, nodeCount, collectMetrics);
		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlock');
		}
		return result;
	}

	// Check for lists
	if (collectMetrics) {
		performanceMetrics.startOperation('ListParsers.matchListItem');
	}

	const listMatch = ListParsers.matchListItem(line);

	if (collectMetrics) {
		performanceMetrics.endOperation('ListParsers.matchListItem');
	}

	if (listMatch) {
		const [isOrdered, indentLevel, _content] = listMatch;
		if (parserFlags & ParserFlags.ALLOW_LISTS) {
			if (collectMetrics) {
				performanceMetrics.startOperation('ListParsers.parseList');
			}

			const result = ListParsers.parseList(
				lines,
				currentLineIndex,
				isOrdered,
				indentLevel,
				1,
				parserFlags,
				nodeCount,
				(text) => InlineParsers.parseInline(text, parserFlags, collectMetrics),
				collectMetrics,
			);

			if (collectMetrics) {
				performanceMetrics.endOperation('ListParsers.parseList');
			}

			const finalResult = {
				node: result.node,
				newLineIndex: result.newLineIndex,
				newNodeCount: result.newNodeCount,
			};

			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseBlock');
			}

			return finalResult;
		}

		const result = {
			node: {type: NodeType.Text, content: line} as TextNode,
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlock');
		}

		return result;
	}

	// Check for block spoilers - fast path with direct check
	if (trimmed.startsWith('||') && !trimmed.slice(2).includes('||')) {
		if (parserFlags & ParserFlags.ALLOW_SPOILERS) {
			if (collectMetrics) {
				performanceMetrics.startOperation('BlockParsers.parseSpoiler');
			}

			const result = parseSpoiler(lines, currentLineIndex, parserFlags, collectMetrics);

			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseSpoiler');
			}

			const finalResult = {
				node: result.node,
				newLineIndex: result.newLineIndex,
				newNodeCount: nodeCount + 1,
			};

			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseBlock');
			}

			return finalResult;
		}

		const result = {
			node: {type: NodeType.Text, content: line} as TextNode,
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlock');
		}

		return result;
	}

	// Check for code blocks
	if (cachedStartsWith(trimmed, '```')) {
		if (parserFlags & ParserFlags.ALLOW_CODE_BLOCKS) {
			if (collectMetrics) {
				performanceMetrics.startOperation('BlockParsers.parseCodeBlock');
			}

			const result = parseCodeBlock(lines, currentLineIndex);

			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseCodeBlock');
			}

			const finalResult = {
				node: result.node,
				newLineIndex: result.newLineIndex,
				newNodeCount: nodeCount + 1,
			};

			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseBlock');
			}

			return finalResult;
		}

		// For disabled code blocks, find the end marker and preserve newlines
		let codeBlockText = lines[currentLineIndex];
		let endLineIndex = currentLineIndex + 1;

		// Look for the closing delimiter
		while (endLineIndex < lines.length) {
			const line = lines[endLineIndex];

			if (line.trim() === '```') {
				codeBlockText += `\n${line}`;
				endLineIndex++;
				break;
			}

			codeBlockText += `\n${line}`;
			endLineIndex++;
		}

		const result = {
			node: {type: NodeType.Text, content: codeBlockText} as TextNode,
			newLineIndex: endLineIndex,
			newNodeCount: nodeCount + 1,
		};

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlock');
		}

		return result;
	}

	// Check for subtext
	if (cachedStartsWith(trimmed, '-#')) {
		if (parserFlags & ParserFlags.ALLOW_SUBTEXT) {
			if (collectMetrics) {
				performanceMetrics.startOperation('BlockParsers.parseSubtext');
			}

			const subtextNode = parseSubtext(
				trimmed,
				(text) => InlineParsers.parseInline(text, parserFlags, collectMetrics),
				collectMetrics,
			);

			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseSubtext');
			}

			if (subtextNode) {
				const result = {
					node: subtextNode,
					newLineIndex: currentLineIndex + 1,
					newNodeCount: nodeCount + 1,
				};

				if (collectMetrics) {
					performanceMetrics.endOperation('BlockParsers.parseBlock');
				}

				return result;
			}
		}

		const result = {
			node: {type: NodeType.Text, content: handleLineAsText(lines, currentLineIndex)} as TextNode,
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlock');
		}

		return result;
	}

	// Check for headings
	if (cachedStartsWith(trimmed, '#')) {
		if (parserFlags & ParserFlags.ALLOW_HEADINGS) {
			if (collectMetrics) {
				performanceMetrics.startOperation('BlockParsers.parseHeading');
			}

			const headingNode = parseHeading(
				trimmed,
				(text) => InlineParsers.parseInline(text, parserFlags, collectMetrics),
				collectMetrics,
			);

			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseHeading');
			}

			if (headingNode) {
				const result = {
					node: headingNode,
					newLineIndex: currentLineIndex + 1,
					newNodeCount: nodeCount + 1,
				};

				if (collectMetrics) {
					performanceMetrics.endOperation('BlockParsers.parseBlock');
				}

				return result;
			}
		}

		const result = {
			node: {type: NodeType.Text, content: handleLineAsText(lines, currentLineIndex)} as TextNode,
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlock');
		}

		return result;
	}

	// Check for tables - expensive check moved last
	if (trimmed.includes('|') && parserFlags & ParserFlags.ALLOW_TABLES) {
		const startIndex = currentLineIndex;

		if (collectMetrics) {
			performanceMetrics.startOperation('TableParsers.parseTable');
		}

		const tableResult = TableParsers.parseTable(
			lines,
			currentLineIndex,
			parserFlags,
			(text) => InlineParsers.parseInline(text, parserFlags, collectMetrics),
			collectMetrics,
		);

		if (collectMetrics) {
			performanceMetrics.endOperation('TableParsers.parseTable');
		}

		if (tableResult.node) {
			const result = {
				node: tableResult.node,
				newLineIndex: tableResult.newLineIndex,
				newNodeCount: nodeCount + 1,
			};

			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseBlock');
			}

			return result;
		}

		currentLineIndex = startIndex;
	}

	// No block element was found
	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseBlock');
	}

	return {node: null, newLineIndex: currentLineIndex, newNodeCount: nodeCount};
}

/**
 * Creates a text node from a line, handling newlines appropriately
 */
function handleLineAsText(lines: Array<string>, currentLineIndex: number): string {
	const isLastLine = currentLineIndex === lines.length - 1;
	return isLastLine ? lines[currentLineIndex] : `${lines[currentLineIndex]}\n`;
}

/**
 * Parses a block element as plain text (when its feature flag is disabled)
 */
function parseBlockAsText(lines: Array<string>, currentLineIndex: number, marker: string): TextNode {
	const originalContent = lines[currentLineIndex];

	// If this is a blockquote marker, we need special handling to avoid duplication
	if (marker === '>' || marker === '>>> ') {
		// For a disabled blockquote, just return the current line and let the parser handle
		// subsequent lines normally without additional processing
		return {
			type: NodeType.Text,
			content: originalContent + (currentLineIndex < lines.length - 1 ? '\n' : ''),
		};
	}

	// For other block markers (like code blocks), just return the text as-is
	// without trying to find the end marker
	return {
		type: NodeType.Text,
		content: originalContent,
	};
}

/**
 * Checks if a line starts with a block-level element - optimized version
 */
export function isBlockStart(line: string): boolean {
	const key = `${line}:isBlockStart`;
	if (stringCache.has(key)) {
		return stringCache.get(key)!;
	}

	const result =
		line.startsWith('#') || ListParsers.matchListItem(line) !== null || line.startsWith('>') || line.startsWith('>>> ');

	stringCache.set(key, result);
	return result;
}

/**
 * Checks if a line starts a different block-level element that would break
 * the current block - optimized version
 */
export function isBlockBreak(trimmed: string): boolean {
	const key = `${trimmed}:isBlockBreak`;
	if (stringCache.has(key)) {
		return stringCache.get(key)!;
	}

	const result = trimmed.startsWith('#') || trimmed.startsWith('>') || trimmed.startsWith('>>> ');

	stringCache.set(key, result);
	return result;
}

/**
 * Parses a heading (# Heading)
 */
export function parseHeading(
	trimmed: string,
	parseInline: (text: string) => Array<Node>,
	collectMetrics = false,
): HeadingNode | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseHeading:detail');
	}

	// Optimize counting # characters
	let level = 0;
	for (let i = 0; i < trimmed.length && i < 3; i++) {
		if (trimmed[i] === '#') level++;
		else break;
	}

	// Discord Markdown supports headings with 1-3 #s, and requires a space after
	if (level >= 1 && level <= 3 && trimmed[level] === ' ') {
		const content = trimmed.slice(level + 1);

		if (collectMetrics) {
			performanceMetrics.startOperation('parseInline for heading');
		}

		const inlineNodes = parseInline(content);

		if (collectMetrics) {
			performanceMetrics.endOperation('parseInline for heading');
		}

		const result: HeadingNode = {
			type: NodeType.Heading,
			level,
			children: inlineNodes,
		};

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseHeading:detail');
		}

		return result;
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseHeading:detail');
	}

	return null;
}

/**
 * Parses a subtext (-# Subtext)
 */
export function parseSubtext(
	trimmed: string,
	parseInline: (text: string) => Array<Node>,
	collectMetrics = false,
): SubtextNode | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseSubtext:detail');
	}

	if (trimmed.startsWith('-#')) {
		// Valid format: "-# content" (there must be a space after #)
		if ((trimmed.length > 2 && trimmed[2] !== ' ') || (trimmed.length > 3 && trimmed[3] === ' ')) {
			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseSubtext:detail');
			}
			return null;
		}

		const content = trimmed.slice(3);

		if (collectMetrics) {
			performanceMetrics.startOperation('parseInline for subtext');
		}

		const inlineNodes = parseInline(content);

		if (collectMetrics) {
			performanceMetrics.endOperation('parseInline for subtext');
		}

		const result: SubtextNode = {
			type: NodeType.Subtext,
			children: inlineNodes,
		};

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseSubtext:detail');
		}

		return result;
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseSubtext:detail');
	}

	return null;
}

/**
 * Parses a single-line blockquote (> Quote)
 * In Discord-style Markdown, blockquotes must have a space after the ">" character.
 */
export function parseBlockquote(
	lines: Array<string>,
	currentLineIndex: number,
	parserFlags: number,
	nodeCount: number,
	collectMetrics = false,
): BlockParseResult {
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseBlockquote');
	}

	let blockquoteContent = '';
	const startLine = currentLineIndex;
	let newLineIndex = currentLineIndex;

	while (newLineIndex < lines.length) {
		if (nodeCount > MAX_AST_NODES) break;
		const line = lines[newLineIndex];
		const trimmed = line.trimStart();

		// Handle empty blockquote lines (just "> " or ">  ")
		if (trimmed === '> ' || trimmed === '>  ') {
			// Empty quote line should add a single newline, not double
			if (blockquoteContent.length > 0) blockquoteContent += '\n';
			newLineIndex++;
		} else if (trimmed.startsWith('> ')) {
			// Regular quote line with content
			const content = trimmed.slice(2);
			if (blockquoteContent.length > 0) blockquoteContent += '\n';
			blockquoteContent += content;
			newLineIndex++;
		} else {
			break;
		}

		if (blockquoteContent.length > MAX_LINE_LENGTH * 100) break;
	}

	if (blockquoteContent === '' && newLineIndex === startLine) {
		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseBlockquote');
		}
		return {node: null, newLineIndex, newNodeCount: nodeCount};
	}

	// Check if this should be an alert instead of a regular blockquote
	if (parserFlags & ParserFlags.ALLOW_ALERTS) {
		if (collectMetrics) {
			performanceMetrics.startOperation('BlockParsers.parseAlert');
		}

		const alertNode = parseAlert(blockquoteContent, parserFlags, collectMetrics);

		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseAlert');
		}

		if (alertNode) {
			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseBlockquote');
			}
			return {
				node: alertNode,
				newLineIndex,
				newNodeCount: nodeCount + 1,
			};
		}
	}

	// Parse as a regular blockquote
	const childFlags = parserFlags & ~ParserFlags.ALLOW_BLOCKQUOTES;

	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseBlockquote.newParser');
	}

	const childParser = new Parser(blockquoteContent, childFlags, {collectMetrics});
	const {nodes: childNodes} = childParser.parse();

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseBlockquote.newParser');
	}

	// Force blockquote text nodes to merge using a special flag
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseBlockquote.flattenChildren');
	}

	flattenChildren(childNodes, true);

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseBlockquote.flattenChildren');
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseBlockquote');
	}

	return {
		node: {
			type: NodeType.Blockquote,
			children: childNodes,
		},
		newLineIndex,
		newNodeCount: nodeCount + 1,
	};
}

/**
 * Parses a multiline blockquote (>>> Quote)
 */
export function parseMultilineBlockquote(
	lines: Array<string>,
	currentLineIndex: number,
	parserFlags: number,
	nodeCount: number,
	collectMetrics = false,
): BlockParseResult {
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseMultilineBlockquote');
	}

	const line = lines[currentLineIndex];
	const trimmed = line.trimStart();

	if (!trimmed.startsWith('>>> ')) {
		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseMultilineBlockquote');
		}
		return {
			node: {type: NodeType.Text, content: ''},
			newLineIndex: currentLineIndex,
			newNodeCount: nodeCount,
		};
	}

	let content = trimmed.slice(4);
	let newLineIndex = currentLineIndex + 1;

	// In multiline blockquotes, everything until the end of the message is included
	while (newLineIndex < lines.length) {
		const current = lines[newLineIndex];
		content += `\n${current}`;
		newLineIndex++;
		if (content.length > MAX_LINE_LENGTH * 100) break;
	}

	// Use modified parser flags for the blockquote content
	const childFlags = (parserFlags & ~ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES) | ParserFlags.ALLOW_BLOCKQUOTES;

	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseMultilineBlockquote.newParser');
	}

	const childParser = new Parser(content, childFlags, {collectMetrics});
	const {nodes: childNodes} = childParser.parse();

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseMultilineBlockquote.newParser');
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseMultilineBlockquote');
	}

	return {
		node: {
			type: NodeType.Blockquote,
			children: childNodes,
		},
		newLineIndex,
		newNodeCount: nodeCount + 1,
	};
}

/**
 * Parses a code block (```language\ncode\n```) with improved performance
 */
export function parseCodeBlock(
	lines: Array<string>,
	currentLineIndex: number,
): {node: CodeBlockNode; newLineIndex: number} {
	const line = lines[currentLineIndex];
	const trimmed = line.trimStart();

	// Calculate list item indentation (if any)
	const indentSpaces = line.length - trimmed.length;
	const listIndent = indentSpaces > 0 ? ' '.repeat(indentSpaces) : '';

	// Extract language if provided
	const language = trimmed.length > 3 ? trimmed.slice(3).trim() || undefined : undefined;
	let newLineIndex = currentLineIndex + 1;

	// Preallocate capacity for content string
	let tempIndex = newLineIndex;
	let lineCount = 0;

	// First pass to estimate capacity needed
	while (tempIndex < lines.length && !lines[tempIndex].trimStart().startsWith('```')) {
		tempIndex++;
		lineCount++;
		if (lineCount > 1000) break; // Safety limit
	}

	// Use string builder approach for better performance with large code blocks
	const contentParts: Array<string> = [];
	let contentLength = 0;

	while (newLineIndex < lines.length) {
		const current = lines[newLineIndex];
		const trimmedLine = current.trimStart();

		// Check for the ending backticks
		if (trimmedLine.startsWith('```')) {
			newLineIndex++;
			break;
		}

		// Strip list item indentation if present, but preserve internal indentation
		let contentLine = current;
		if (indentSpaces > 0 && current.startsWith(listIndent)) {
			contentLine = current.slice(indentSpaces);
		}

		contentParts.push(contentLine);
		contentParts.push('\n');
		contentLength += contentLine.length + 1;

		if (contentLength > MAX_LINE_LENGTH * 100) break;
		newLineIndex++;
	}

	return {
		node: {
			type: NodeType.CodeBlock,
			language,
			content: contentParts.join(''),
		},
		newLineIndex,
	};
}

/**
 * Parses a block spoiler (||spoiler content||)
 */
export function parseSpoiler(
	lines: Array<string>,
	currentLineIndex: number,
	parserFlags: number,
	collectMetrics = false,
): {node: SpoilerNode | TextNode; newLineIndex: number} {
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseSpoiler:detail');
	}

	const startLine = currentLineIndex;
	let foundEnd = false;
	let blockContent = '';
	let newLineIndex = currentLineIndex;

	while (newLineIndex < lines.length) {
		const line = lines[newLineIndex];

		if (newLineIndex === startLine) {
			const startIdx = line.indexOf('||');
			if (startIdx !== -1) {
				blockContent += line.slice(startIdx + 2);
			}
		} else {
			const endIdx = line.indexOf('||');
			if (endIdx !== -1) {
				blockContent += line.slice(0, endIdx);
				foundEnd = true;
				newLineIndex++;
				break;
			}
			blockContent += line;
		}

		blockContent += '\n';
		newLineIndex++;

		if (blockContent.length > MAX_LINE_LENGTH * 10) break;
	}

	if (!foundEnd) {
		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseSpoiler:detail');
		}
		return {
			node: {
				type: NodeType.Text,
				content: `||${blockContent.trimEnd()}`,
			},
			newLineIndex,
		};
	}

	// Parse the content inside the spoiler
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseSpoiler.newParser');
	}

	const childParser = new Parser(blockContent.trim(), parserFlags, {collectMetrics});
	const {nodes: innerNodes} = childParser.parse();

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseSpoiler.newParser');
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseSpoiler:detail');
	}

	return {
		node: {
			type: NodeType.Spoiler,
			children: innerNodes,
			isBlock: true,
		},
		newLineIndex,
	};
}

/**
 * Parses an alert block ([!NOTE], [!TIP], etc.)
 */
export function parseAlert(blockquoteText: string, parserFlags: number, collectMetrics = false): AlertNode | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseAlert:detail');
	}

	// Use pre-compiled regex for better performance
	const alertMatch = blockquoteText.match(ALERT_PATTERN);
	if (!alertMatch) {
		if (collectMetrics) {
			performanceMetrics.endOperation('BlockParsers.parseAlert:detail');
		}
		return null;
	}

	const alertTypeStr = alertMatch[1].toUpperCase();
	let alertType: AlertType;

	// Map the alert type string to the AlertType enum
	switch (alertTypeStr) {
		case 'NOTE':
			alertType = AlertType.Note;
			break;
		case 'TIP':
			alertType = AlertType.Tip;
			break;
		case 'IMPORTANT':
			alertType = AlertType.Important;
			break;
		case 'WARNING':
			alertType = AlertType.Warning;
			break;
		case 'CAUTION':
			alertType = AlertType.Caution;
			break;
		default:
			if (collectMetrics) {
				performanceMetrics.endOperation('BlockParsers.parseAlert:detail');
			}
			return null;
	}

	// Extract the content after the alert marker
	const content = blockquoteText.slice(alertMatch[0].length);

	// Use modified parser flags for alert content
	const childFlags =
		(parserFlags & ~ParserFlags.ALLOW_BLOCKQUOTES) | ParserFlags.ALLOW_LISTS | ParserFlags.ALLOW_HEADINGS;

	// Process content for alerts, which have special handling for lists
	const lines = content.split('\n');
	const processedLines = lines.map((line) => {
		const trimmed = line.trim();
		if (trimmed.startsWith('-') || /^\d+\./.test(trimmed)) {
			return line;
		}
		return trimmed;
	});

	const processedContent = processedLines
		.join('\n')
		.replace(/\n{3,}/g, '\n\n')
		.trim();

	// Parse the content inside the alert
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.parseAlert.newParser');
	}

	const childParser = new Parser(processedContent, childFlags, {collectMetrics});
	const {nodes: childNodes} = childParser.parse();

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseAlert.newParser');
	}

	// Merge adjacent text nodes - optimized version
	const mergedNodes: Array<Node> = [];
	let currentText = '';

	for (const node of childNodes) {
		if (node.type === NodeType.Text) {
			if (currentText) {
				currentText += node.content;
			} else {
				currentText = node.content;
			}
		} else {
			if (currentText) {
				mergedNodes.push({type: NodeType.Text, content: currentText});
				currentText = '';
			}
			mergedNodes.push(node);
		}
	}

	if (currentText) {
		mergedNodes.push({type: NodeType.Text, content: currentText});
	}

	// Post-process the nodes for alert-specific formatting
	if (collectMetrics) {
		performanceMetrics.startOperation('BlockParsers.postProcessAlertNodes');
	}

	const finalNodes = postProcessAlertNodes(mergedNodes);

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.postProcessAlertNodes');
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('BlockParsers.parseAlert:detail');
	}

	return {
		type: NodeType.Alert,
		alertType,
		children: finalNodes,
	};
}

/**
 * Post-processes alert nodes to handle special formatting cases
 */
export function postProcessAlertNodes(nodes: Array<Node>): Array<Node> {
	if (nodes.length <= 1) return nodes;

	const result: Array<Node> = [];
	let i = 0;

	while (i < nodes.length) {
		const node = nodes[i];

		if (node.type === NodeType.Text && i + 1 < nodes.length) {
			if (nodes[i + 1].type === NodeType.List) {
				const trimmedContent = node.content.replace(/\s+$/, '\n');
				if (trimmedContent) {
					result.push({type: NodeType.Text, content: trimmedContent});
				}
			} else {
				result.push(node);
			}
		} else if (node.type === NodeType.List && i + 1 < nodes.length) {
			result.push(node);

			const nextNode = nodes[i + 1];
			if (nextNode.type === NodeType.Text) {
				const content = nextNode.content.trim();
				if (content) {
					result.push({type: NodeType.Text, content: `\n${content}`});
					i++;
				}
			}
		} else {
			result.push(node);
		}

		i++;
	}

	return result;
}
