import {FormattingContext} from '../parser/formatting-context';
import {MentionKind, type Node, NodeType, ParserFlags, type ParserResult} from '../types';
import {MAX_LINE_LENGTH} from '../types/constants';
import * as ASTUtils from '../utils/ast-utils';
import {performanceMetrics} from '../utils/performance-metrics';
import * as StringUtils from '../utils/string-utils';
import * as EmojiParsers from './emoji-parsers';
import * as LinkParsers from './link-parsers';
import * as MentionParsers from './mention-parsers';
import * as TimestampParsers from './timestamp-parsers';

// Character codes for common characters - faster than string comparisons
const BACKSLASH = 92; // '\'
const UNDERSCORE = 95; // '_'
const ASTERISK = 42; // '*'
const TILDE = 126; // '~'
const PIPE = 124; // '|'
const BACKTICK = 96; // '`'
const LESS_THAN = 60; // '<'
const AT_SIGN = 64; // '@'
const HASH = 35; // '#'
const AMPERSAND = 38; // '&'
const SLASH = 47; // '/'
const OPEN_BRACKET = 91; // '['
const COLON = 58; // ':'
const LETTER_I = 105; // 'i'
const LETTER_T = 116; // 't'
const LETTER_A = 97; // 'a'

// Special character sets for faster lookup
const FORMATTING_CHARS = new Set([ASTERISK, UNDERSCORE, TILDE, PIPE, BACKTICK]);

// Cache for previously parsed inline content
const parseInlineCache = new Map<string, Array<Node>>();
// Cache for formatting marker info lookups
const formattingMarkerCache = new Map<string, ReturnType<typeof getFormattingMarkerInfo>>();
// Maximum cache size to prevent memory leaks
const MAX_CACHE_SIZE = 500;

/**
 * Main entry point for parsing inline content
 * Parses text into an array of inline nodes
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Array of parsed nodes
 */
export function parseInline(text: string, parserFlags: number, collectMetrics = false): Array<Node> {
	if (collectMetrics) {
		performanceMetrics.startOperation('InlineParsers.parseInline');
	}

	// Fast path for empty or very short text
	if (!text || text.length === 0) {
		if (collectMetrics) {
			performanceMetrics.endOperation('InlineParsers.parseInline');
		}
		return [];
	}

	// Check cache first for exact matches
	const cacheKey = `${text}:${parserFlags}`;
	if (parseInlineCache.has(cacheKey)) {
		const cachedResult = parseInlineCache.get(cacheKey)!;
		if (collectMetrics) {
			performanceMetrics.endOperation('InlineParsers.parseInline');
		}
		return [...cachedResult]; // Return a copy to prevent mutation of cached items
	}

	const context = new FormattingContext();

	if (collectMetrics) {
		performanceMetrics.startOperation('InlineParsers.parseInlineWithContext');
	}

	const nodes = parseInlineWithContext(text, context, parserFlags, collectMetrics);

	if (collectMetrics) {
		performanceMetrics.endOperation('InlineParsers.parseInlineWithContext');
		performanceMetrics.startOperation('ASTUtils.flattenAST for inline');
	}

	ASTUtils.flattenAST(nodes);

	if (collectMetrics) {
		performanceMetrics.endOperation('ASTUtils.flattenAST for inline');
		performanceMetrics.endOperation('InlineParsers.parseInline');
	}

	// Cache the result for future use (only for reasonable sizes)
	if (text.length < 1000) {
		parseInlineCache.set(cacheKey, [...nodes]);

		// Prevent cache from growing too large - evict oldest entries
		if (parseInlineCache.size > MAX_CACHE_SIZE) {
			const keysToDelete = Array.from(parseInlineCache.keys()).slice(0, 100);
			for (const key of keysToDelete) {
				parseInlineCache.delete(key);
			}
		}
	}

	return nodes;
}

/**
 * Parses inline content with a given formatting context
 * The context tracks the state of active formatting markers
 *
 * @param text - The text to parse
 * @param context - The formatting context to use
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Array of parsed nodes
 */
export function parseInlineWithContext(
	text: string,
	context: FormattingContext,
	parserFlags: number,
	collectMetrics = false,
): Array<Node> {
	if (!text) {
		return [];
	}

	const nodes: Array<Node> = [];
	let accumulatedText = '';
	let position = 0;

	if (collectMetrics) {
		performanceMetrics.startOperation('InlineParsers.parseCharacters');
	}

	const textLength = text.length;

	// Only create character array if needed (for specific functions)
	let characters: Array<string> | null = null;

	while (position < textLength) {
		const currentCharCode = text.charCodeAt(position);

		// Handle escaped characters
		if (currentCharCode === BACKSLASH && position + 1 < textLength) {
			const nextChar = text.charAt(position + 1);
			if (StringUtils.isEscapableCharacter(nextChar)) {
				accumulatedText += nextChar;
				position += 2;
				continue;
			}
		}

		// Get remaining text for specific parsers (only when needed)
		const remainingText = text.slice(position);

		// Check if we're in a quoted angle bracket context
		const insideQuotedAngleBracket = accumulatedText.endsWith('<"') || accumulatedText.endsWith("<'");

		// Skip URL extraction if inside quoted angle brackets
		if (!insideQuotedAngleBracket && StringUtils.startsWithUrl(remainingText)) {
			if (collectMetrics) {
				performanceMetrics.startOperation('LinkParsers.extractUrlSegment');
			}

			const urlResult = LinkParsers.extractUrlSegment(remainingText, parserFlags, collectMetrics);

			if (collectMetrics) {
				performanceMetrics.endOperation('LinkParsers.extractUrlSegment');
			}

			if (urlResult) {
				if (accumulatedText.length > 0) {
					ASTUtils.addTextNode(nodes, accumulatedText);
					accumulatedText = '';
				}
				nodes.push(urlResult.node);
				position += urlResult.advance;
				continue;
			}
		}

		// Handle underscore that's part of a word, not formatting
		if (currentCharCode === UNDERSCORE) {
			// Initialize character array only when needed for word underscore check
			if (characters === null) {
				characters = [...text];
			}
			const isWordUnderscore = StringUtils.isWordUnderscore(characters, position);
			if (isWordUnderscore) {
				accumulatedText += '_';
				position += 1;
				continue;
			}
		}

		// Check for custom emojis - highest priority
		if (currentCharCode === LESS_THAN && position + 2 < textLength) {
			if (
				text.charCodeAt(position + 1) === COLON ||
				(text.charCodeAt(position + 1) === LETTER_A && text.charCodeAt(position + 2) === COLON)
			) {
				if (collectMetrics) {
					performanceMetrics.startOperation('EmojiParsers.parseCustomEmoji');
				}

				const emojiResult = EmojiParsers.parseCustomEmoji(remainingText, collectMetrics);

				if (collectMetrics) {
					performanceMetrics.endOperation('EmojiParsers.parseCustomEmoji');
				}

				if (emojiResult) {
					if (accumulatedText.length > 0) {
						ASTUtils.addTextNode(nodes, accumulatedText);
						accumulatedText = '';
					}
					nodes.push(emojiResult.node);
					position += emojiResult.advance;
					continue;
				}
			}
		}

		// Check for timestamp pattern at the start: <t:123456789>
		if (
			currentCharCode === LESS_THAN &&
			position + 3 < textLength &&
			text.charCodeAt(position + 1) === LETTER_T &&
			text.charCodeAt(position + 2) === COLON
		) {
			if (collectMetrics) {
				performanceMetrics.startOperation('TimestampParsers.parseTimestamp');
			}

			const timestampResult = TimestampParsers.parseTimestamp(remainingText, collectMetrics);

			if (collectMetrics) {
				performanceMetrics.endOperation('TimestampParsers.parseTimestamp');
			}

			if (timestampResult) {
				if (accumulatedText.length > 0) {
					ASTUtils.addTextNode(nodes, accumulatedText);
					accumulatedText = '';
				}
				nodes.push(timestampResult.node);
				position += timestampResult.advance;
				continue;
			}
		}

		// Try to parse emoji shortcodes
		if (currentCharCode === COLON) {
			if (collectMetrics) {
				performanceMetrics.startOperation('EmojiParsers.parseEmojiShortcode');
			}

			const emojiResult = EmojiParsers.parseEmojiShortcode(remainingText, collectMetrics);

			if (collectMetrics) {
				performanceMetrics.endOperation('EmojiParsers.parseEmojiShortcode');
			}

			if (emojiResult) {
				if (accumulatedText.length > 0) {
					ASTUtils.addTextNode(nodes, accumulatedText);
					accumulatedText = '';
				}
				nodes.push(emojiResult.node);
				position += emojiResult.advance;
				continue;
			}
		}

		// Try to parse standard emojis at current position (Unicode characters)
		if (collectMetrics) {
			performanceMetrics.startOperation('EmojiParsers.parseStandardEmoji');
		}

		const emojiResult = EmojiParsers.parseStandardEmoji(text, position, collectMetrics);

		if (collectMetrics) {
			performanceMetrics.endOperation('EmojiParsers.parseStandardEmoji');
		}

		if (emojiResult) {
			if (accumulatedText.length > 0) {
				ASTUtils.addTextNode(nodes, accumulatedText);
				accumulatedText = '';
			}
			nodes.push(emojiResult.node);
			position += emojiResult.advance;
			continue;
		}

		// Try to parse mentions
		if (currentCharCode === LESS_THAN && position + 1 < textLength) {
			const nextCharCode = text.charCodeAt(position + 1);

			if (
				nextCharCode === AT_SIGN ||
				nextCharCode === HASH ||
				nextCharCode === AMPERSAND ||
				nextCharCode === SLASH ||
				nextCharCode === LETTER_I
			) {
				// Special handling for role mentions which start with <@&
				if (
					nextCharCode === AT_SIGN &&
					position + 2 < textLength &&
					text.charCodeAt(position + 2) === AMPERSAND &&
					parserFlags & ParserFlags.ALLOW_ROLE_MENTIONS
				) {
					const mentionResult = MentionParsers.parseMention(remainingText, parserFlags, collectMetrics);
					if (mentionResult) {
						if (accumulatedText.length > 0) {
							ASTUtils.addTextNode(nodes, accumulatedText);
							accumulatedText = '';
						}
						nodes.push(mentionResult.node);
						position += mentionResult.advance;
						continue;
					}
				}
				// User mentions
				else if (nextCharCode === AT_SIGN && parserFlags & ParserFlags.ALLOW_USER_MENTIONS) {
					const mentionResult = MentionParsers.parseMention(remainingText, parserFlags, collectMetrics);
					if (mentionResult) {
						if (accumulatedText.length > 0) {
							ASTUtils.addTextNode(nodes, accumulatedText);
							accumulatedText = '';
						}
						nodes.push(mentionResult.node);
						position += mentionResult.advance;
						continue;
					}
				}
				// Channel mentions
				else if (nextCharCode === HASH && parserFlags & ParserFlags.ALLOW_CHANNEL_MENTIONS) {
					const mentionResult = MentionParsers.parseMention(remainingText, parserFlags, collectMetrics);
					if (mentionResult) {
						if (accumulatedText.length > 0) {
							ASTUtils.addTextNode(nodes, accumulatedText);
							accumulatedText = '';
						}
						nodes.push(mentionResult.node);
						position += mentionResult.advance;
						continue;
					}
				}
				// Command mentions
				else if (nextCharCode === SLASH && parserFlags & ParserFlags.ALLOW_COMMAND_MENTIONS) {
					const mentionResult = MentionParsers.parseMention(remainingText, parserFlags, collectMetrics);
					if (mentionResult) {
						if (accumulatedText.length > 0) {
							ASTUtils.addTextNode(nodes, accumulatedText);
							accumulatedText = '';
						}
						nodes.push(mentionResult.node);
						position += mentionResult.advance;
						continue;
					}
				}
				// Guild navigation mentions
				else if (
					nextCharCode === LETTER_I &&
					remainingText.startsWith('<id:') &&
					parserFlags & ParserFlags.ALLOW_GUILD_NAVIGATIONS
				) {
					const mentionResult = MentionParsers.parseMention(remainingText, parserFlags, collectMetrics);
					if (mentionResult) {
						if (accumulatedText.length > 0) {
							ASTUtils.addTextNode(nodes, accumulatedText);
							accumulatedText = '';
						}
						nodes.push(mentionResult.node);
						position += mentionResult.advance;
						continue;
					}
				}
			}

			// Check for autolinks and email links
			if (parserFlags & ParserFlags.ALLOW_AUTOLINKS) {
				// Try autolinks: <https://example.com>
				if (collectMetrics) {
					performanceMetrics.startOperation('LinkParsers.parseAutolink');
				}

				const autolinkResult = LinkParsers.parseAutolink(remainingText, parserFlags, collectMetrics);

				if (collectMetrics) {
					performanceMetrics.endOperation('LinkParsers.parseAutolink');
				}

				if (autolinkResult) {
					if (accumulatedText.length > 0) {
						ASTUtils.addTextNode(nodes, accumulatedText);
						accumulatedText = '';
					}
					nodes.push(autolinkResult.node);
					position += autolinkResult.advance;
					continue;
				}

				// Try email links: <user@example.com>
				if (collectMetrics) {
					performanceMetrics.startOperation('LinkParsers.parseEmailLink');
				}

				const emailLinkResult = LinkParsers.parseEmailLink(remainingText, parserFlags, collectMetrics);

				if (collectMetrics) {
					performanceMetrics.endOperation('LinkParsers.parseEmailLink');
				}

				if (emailLinkResult) {
					if (accumulatedText.length > 0) {
						ASTUtils.addTextNode(nodes, accumulatedText);
						accumulatedText = '';
					}
					nodes.push(emailLinkResult.node);
					position += emailLinkResult.advance;
					continue;
				}
			}
		}

		// Check for @everyone and @here mentions
		if (currentCharCode === AT_SIGN && parserFlags & ParserFlags.ALLOW_EVERYONE_MENTIONS) {
			if (remainingText.startsWith('@everyone')) {
				if (accumulatedText.length > 0) {
					ASTUtils.addTextNode(nodes, accumulatedText);
					accumulatedText = '';
				}
				nodes.push({
					type: NodeType.Mention,
					kind: {kind: MentionKind.Everyone},
				});
				position += 9; // Length of '@everyone'
				continue;
			}

			if (remainingText.startsWith('@here')) {
				if (accumulatedText.length > 0) {
					ASTUtils.addTextNode(nodes, accumulatedText);
					accumulatedText = '';
				}
				nodes.push({
					type: NodeType.Mention,
					kind: {kind: MentionKind.Here},
				});
				position += 5; // Length of '@here'
				continue;
			}
		}

		// Try to parse special sequence (links, formatting, mentions, etc.)
		if (FORMATTING_CHARS.has(currentCharCode) || currentCharCode === OPEN_BRACKET) {
			context.setCurrentText(accumulatedText);

			if (collectMetrics) {
				performanceMetrics.startOperation('InlineParsers.parseSpecialSequence');
			}

			const specialResult = parseSpecialSequence(remainingText, context, parserFlags, collectMetrics);

			if (collectMetrics) {
				performanceMetrics.endOperation('InlineParsers.parseSpecialSequence');
			}

			if (specialResult) {
				if (accumulatedText.length > 0) {
					ASTUtils.addTextNode(nodes, accumulatedText);
					accumulatedText = '';
				}
				nodes.push(specialResult.node);
				position += specialResult.advance;
				continue;
			}
		}

		// Add current character to accumulated text
		accumulatedText += text.charAt(position);
		position += 1;

		// Prevent excessive text length
		if (accumulatedText.length > MAX_LINE_LENGTH) {
			ASTUtils.addTextNode(nodes, accumulatedText);
			accumulatedText = '';
			break;
		}
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('InlineParsers.parseCharacters');
	}

	// Add any remaining text
	if (accumulatedText.length > 0) {
		ASTUtils.addTextNode(nodes, accumulatedText);
	}

	// Merge adjacent text nodes for efficiency
	if (collectMetrics) {
		performanceMetrics.startOperation('ASTUtils.mergeTextNodes');
	}

	const result = ASTUtils.mergeTextNodes(nodes);

	if (collectMetrics) {
		performanceMetrics.endOperation('ASTUtils.mergeTextNodes');
	}

	return result;
}

/**
 * Tries to parse a special sequence like formatting, links, mentions, etc.
 *
 * @param text - The text to parse
 * @param context - The formatting context
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no special sequence found
 */
export function parseSpecialSequence(
	text: string,
	context: FormattingContext,
	parserFlags: number,
	collectMetrics = false,
): ParserResult | null {
	// Fast path: check first character code to determine which parser to try
	if (text.length === 0) return null;

	const firstCharCode = text.charCodeAt(0);

	// Try parsers based on first character
	switch (firstCharCode) {
		case LESS_THAN: // '<'
			// Try to parse mentions based on the second character
			if (text.length > 1) {
				const nextCharCode = text.charCodeAt(1);

				if (nextCharCode === SLASH) {
					// '/'
					// Command mention
					if (parserFlags & ParserFlags.ALLOW_COMMAND_MENTIONS) {
						if (collectMetrics) {
							performanceMetrics.startOperation('MentionParsers.parseMention');
						}

						const mentionResult = MentionParsers.parseMention(text, parserFlags, collectMetrics);

						if (collectMetrics) {
							performanceMetrics.endOperation('MentionParsers.parseMention');
						}

						if (mentionResult) return mentionResult;
					}
				} else if (nextCharCode === LETTER_I && text.startsWith('<id:')) {
					// Guild navigation mention
					if (parserFlags & ParserFlags.ALLOW_GUILD_NAVIGATIONS) {
						if (collectMetrics) {
							performanceMetrics.startOperation('MentionParsers.parseMention');
						}

						const mentionResult = MentionParsers.parseMention(text, parserFlags, collectMetrics);

						if (collectMetrics) {
							performanceMetrics.endOperation('MentionParsers.parseMention');
						}

						if (mentionResult) return mentionResult;
					}
				}
			}

			break;

		case ASTERISK: // '*'
		case UNDERSCORE: // '_'
		case TILDE: // '~'
		case PIPE: // '|'
		case BACKTICK: {
			// '`'
			// Try formatting parser
			if (collectMetrics) {
				performanceMetrics.startOperation('InlineParsers.parseFormatting');
			}

			const formattingResult = parseFormatting(text, context, parserFlags, collectMetrics);

			if (collectMetrics) {
				performanceMetrics.endOperation('InlineParsers.parseFormatting');
			}

			if (formattingResult) return formattingResult;

			break;
		}

		case AT_SIGN: // '@'
			// Check for @everyone and @here mentions
			if (parserFlags & ParserFlags.ALLOW_EVERYONE_MENTIONS) {
				if (text.startsWith('@everyone')) {
					return {
						node: {
							type: NodeType.Mention,
							kind: {kind: MentionKind.Everyone},
						},
						advance: 9, // Length of '@everyone'
					};
				}

				if (text.startsWith('@here')) {
					return {
						node: {
							type: NodeType.Mention,
							kind: {kind: MentionKind.Here},
						},
						advance: 5, // Length of '@here'
					};
				}
			}

			break;

		case OPEN_BRACKET: {
			// '['
			// Try timestamp and links
			if (collectMetrics) {
				performanceMetrics.startOperation('TimestampParsers.parseTimestamp');
			}

			const timestampResult = TimestampParsers.parseTimestamp(text, collectMetrics);

			if (collectMetrics) {
				performanceMetrics.endOperation('TimestampParsers.parseTimestamp');
			}

			if (timestampResult) return timestampResult;

			if (parserFlags & ParserFlags.ALLOW_MASKED_LINKS) {
				if (collectMetrics) {
					performanceMetrics.startOperation('LinkParsers.parseLink');
				}

				const linkResult = LinkParsers.parseLink(
					text,
					parserFlags,
					(t) => parseInline(t, parserFlags, collectMetrics),
					collectMetrics,
				);

				if (collectMetrics) {
					performanceMetrics.endOperation('LinkParsers.parseLink');
				}

				if (linkResult) return linkResult;
			}

			break;
		}
	}

	// Try timestamps for non-bracket formats
	if (firstCharCode !== OPEN_BRACKET) {
		if (collectMetrics) {
			performanceMetrics.startOperation('TimestampParsers.parseTimestamp');
		}

		const timestampResult = TimestampParsers.parseTimestamp(text, collectMetrics);

		if (collectMetrics) {
			performanceMetrics.endOperation('TimestampParsers.parseTimestamp');
		}

		if (timestampResult) return timestampResult;
	}

	// Try links only if special characters didn't match anything else
	if (firstCharCode !== LESS_THAN && firstCharCode !== OPEN_BRACKET && parserFlags & ParserFlags.ALLOW_MASKED_LINKS) {
		if (collectMetrics) {
			performanceMetrics.startOperation('LinkParsers.parseLink');
		}

		const linkResult = LinkParsers.parseLink(
			text,
			parserFlags,
			(t) => parseInline(t, parserFlags, collectMetrics),
			collectMetrics,
		);

		if (collectMetrics) {
			performanceMetrics.endOperation('LinkParsers.parseLink');
		}

		if (linkResult) return linkResult;
	}

	return null;
}

/**
 * Parses text formatting markers like bold, italic, etc.
 *
 * @param text - The text to parse
 * @param context - The formatting context
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no formatting found
 */
export function parseFormatting(
	text: string,
	context: FormattingContext,
	parserFlags: number,
	collectMetrics = false,
): ParserResult | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('InlineParsers.getFormattingMarkerInfo');
	}

	// Fast path for short text
	if (text.length < 2) {
		if (collectMetrics) {
			performanceMetrics.endOperation('InlineParsers.getFormattingMarkerInfo');
		}
		return null;
	}

	// Use cached marker info when possible
	let markerInfo: FormattingMarkerInfo | null | undefined;
	const prefix = text.slice(0, Math.min(3, text.length));

	if (formattingMarkerCache.has(prefix)) {
		markerInfo = formattingMarkerCache.get(prefix);
	} else {
		markerInfo = getFormattingMarkerInfo(text);
		formattingMarkerCache.set(prefix, markerInfo);

		// Prevent cache from growing too large
		if (formattingMarkerCache.size > MAX_CACHE_SIZE) {
			const keysToDelete = Array.from(formattingMarkerCache.keys()).slice(0, 50);
			for (const key of keysToDelete) {
				formattingMarkerCache.delete(key);
			}
		}
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('InlineParsers.getFormattingMarkerInfo');
	}

	if (!markerInfo) return null;

	const {marker, nodeType, markerLength} = markerInfo;

	// For spoilers, check if they're allowed
	if (nodeType === NodeType.Spoiler && !(parserFlags & ParserFlags.ALLOW_SPOILERS)) {
		return null;
	}

	// Check if we can enter this formatting in the current context
	if (!context.canEnterFormatting(marker[0], marker.length > 1)) return null;

	if (collectMetrics) {
		performanceMetrics.startOperation('InlineParsers.findFormattingEnd');
	}

	const endResult = findFormattingEnd(text, marker, markerLength, nodeType);

	if (collectMetrics) {
		performanceMetrics.endOperation('InlineParsers.findFormattingEnd');
	}

	if (!endResult) return null;

	const {endPosition, innerContent} = endResult;
	const isBlock = context.isFormattingActive(marker[0], marker.length > 1);

	// Create the formatting node
	if (collectMetrics) {
		performanceMetrics.startOperation('InlineParsers.createFormattingNode');
	}

	const formattingNode = createFormattingNode(
		nodeType,
		innerContent,
		marker,
		isBlock,
		(text: string, ctx: FormattingContext) => parseInlineWithContext(text, ctx, parserFlags, collectMetrics),
		collectMetrics,
	);

	if (collectMetrics) {
		performanceMetrics.endOperation('InlineParsers.createFormattingNode');
	}

	return {node: formattingNode, advance: endPosition + markerLength};
}

interface FormattingMarkerInfo {
	marker: string;
	nodeType: NodeType;
	markerLength: number;
}

/**
 * Gets information about a formatting marker at the start of text
 * Optimized to use character codes for faster comparisons
 *
 * @param text - The text to analyze
 * @returns Information about the marker or null if no marker found
 */
export function getFormattingMarkerInfo(text: string): FormattingMarkerInfo | null {
	if (!text || text.length === 0) return null;

	const firstCharCode = text.charCodeAt(0);

	// Fast path: if not a formatting character, return null immediately
	if (!FORMATTING_CHARS.has(firstCharCode)) return null;

	const secondCharCode = text.length > 1 ? text.charCodeAt(1) : 0;
	const thirdCharCode = text.length > 2 ? text.charCodeAt(2) : 0;

	// Check for triple markers (***text*** or ___text___)
	if (firstCharCode === ASTERISK && secondCharCode === ASTERISK && thirdCharCode === ASTERISK) {
		return {marker: '***', nodeType: NodeType.TripleAsterisk, markerLength: 3};
	}
	if (firstCharCode === UNDERSCORE && secondCharCode === UNDERSCORE && thirdCharCode === UNDERSCORE) {
		return {marker: '___', nodeType: NodeType.TripleUnderscore, markerLength: 3};
	}

	// Check for double markers (**text**, __text__, ~~text~~, ||text||)
	if (firstCharCode === PIPE && secondCharCode === PIPE) {
		return {marker: '||', nodeType: NodeType.Spoiler, markerLength: 2};
	}
	if (firstCharCode === TILDE && secondCharCode === TILDE) {
		return {marker: '~~', nodeType: NodeType.Strikethrough, markerLength: 2};
	}
	if (firstCharCode === ASTERISK && secondCharCode === ASTERISK) {
		return {marker: '**', nodeType: NodeType.Strong, markerLength: 2};
	}
	if (firstCharCode === UNDERSCORE && secondCharCode === UNDERSCORE) {
		return {marker: '__', nodeType: NodeType.Underline, markerLength: 2};
	}

	// Check for single markers (`code`, *text*, _text_)
	if (firstCharCode === BACKTICK) {
		return {marker: '`', nodeType: NodeType.InlineCode, markerLength: 1};
	}
	if (firstCharCode === ASTERISK) {
		return {marker: '*', nodeType: NodeType.Emphasis, markerLength: 1};
	}
	if (firstCharCode === UNDERSCORE) {
		return {marker: '_', nodeType: NodeType.Emphasis, markerLength: 1};
	}

	return null;
}

/**
 * Finds the end of a formatting section
 * Optimized to avoid string spread and use charCodeAt for faster comparisons
 *
 * @param text - The text to search
 * @param marker - The formatting marker to find
 * @param markerLength - The length of the marker
 * @param nodeType - The type of formatting node
 * @returns The end position and inner content, or null if no end found
 */
export function findFormattingEnd(
	text: string,
	marker: string,
	markerLength: number,
	nodeType: NodeType,
): {endPosition: number; innerContent: string} | null {
	let position = markerLength;
	let nestedLevel = 0;
	let endPosition: number | null = null;
	const textLength = text.length;

	// Minimum viable content check - text must be longer than marker * 2
	if (textLength < markerLength * 2) return null;

	// Special fast path for shorter text
	if (textLength < 500 && nodeType === NodeType.InlineCode && markerLength === 1) {
		const endIdx = text.indexOf('`', markerLength);
		if (endIdx !== -1) {
			return {
				endPosition: endIdx,
				innerContent: text.slice(markerLength, endIdx),
			};
		}
		return null;
	}

	// Inline code has simpler rules - just find the next backtick
	if (nodeType === NodeType.InlineCode) {
		while (position < textLength) {
			if (text.charCodeAt(position) === BACKTICK) {
				endPosition = position;
				break;
			}
			position++;
			if (position > MAX_LINE_LENGTH) break;
		}
	} else {
		// For other formatting, handle nesting and escaping
		const firstMarkerChar = marker.charCodeAt(0);
		const isDoubleMarker = marker.length > 1;

		while (position < textLength) {
			// Check for escaped characters first
			if (text.charCodeAt(position) === BACKSLASH && position + 1 < textLength) {
				position += 2;
				continue;
			}

			// Check if we've found our closing marker
			let isClosingMarker = true;
			if (position + marker.length <= textLength) {
				for (let i = 0; i < marker.length; i++) {
					if (text.charCodeAt(position + i) !== marker.charCodeAt(i)) {
						isClosingMarker = false;
						break;
					}
				}
			} else {
				isClosingMarker = false;
			}

			if (isClosingMarker) {
				if (nestedLevel === 0) {
					endPosition = position;
					break;
				}
				nestedLevel--;
				position += marker.length;
				continue;
			}

			// Handle potential nested markers of the same type
			if (
				isDoubleMarker &&
				position + 1 < textLength &&
				text.charCodeAt(position) === firstMarkerChar &&
				text.charCodeAt(position + 1) === firstMarkerChar
			) {
				nestedLevel++;
			}

			position++;
			if (position > MAX_LINE_LENGTH) break;
		}
	}

	if (endPosition === null) return null;

	// Extract inner content without using array operations
	const innerContent = text.slice(markerLength, endPosition);
	return {endPosition, innerContent};
}

/**
 * Creates a formatting node with the given parameters
 *
 * @param nodeType - The type of formatting node
 * @param innerContent - The content inside the formatting markers
 * @param marker - The formatting marker
 * @param isBlock - Whether this is a block-level element
 * @param parseInlineWithContext - Function to parse the inner content
 * @param collectMetrics - Whether to collect performance metrics
 * @returns The created formatting node
 */
export function createFormattingNode(
	nodeType: NodeType,
	innerContent: string,
	marker: string,
	isBlock: boolean,
	parseInlineWithContext: (text: string, context: FormattingContext) => Array<Node>,
	collectMetrics = false,
): Node {
	// Fast path for inline code which doesn't need further parsing
	if (nodeType === NodeType.InlineCode) {
		return {type: NodeType.InlineCode, content: innerContent};
	}

	// Create a new context for nested formatting
	const newContext = new FormattingContext();
	newContext.pushFormatting(marker[0], marker.length > 1);

	switch (nodeType) {
		case NodeType.TripleAsterisk:
		case NodeType.TripleUnderscore: {
			// Triple markers create nested strong + emphasis
			const emphasisContext = new FormattingContext();
			emphasisContext.pushFormatting('*', true);

			if (collectMetrics) {
				performanceMetrics.startOperation('createFormattingNode.parseNested');
			}

			const innerNodes = parseInlineWithContext(innerContent, emphasisContext);

			if (collectMetrics) {
				performanceMetrics.endOperation('createFormattingNode.parseNested');
			}

			return {
				type: NodeType.Emphasis,
				children: [{type: NodeType.Strong, children: innerNodes}],
			};
		}

		case NodeType.Spoiler: {
			if (collectMetrics) {
				performanceMetrics.startOperation('createFormattingNode.parseNested');
			}

			const innerNodes = parseInlineWithContext(innerContent, newContext);

			if (collectMetrics) {
				performanceMetrics.endOperation('createFormattingNode.parseNested');
			}

			return {
				type: nodeType,
				children: innerNodes,
				isBlock,
			};
		}

		case NodeType.Strong:
		case NodeType.Emphasis:
		case NodeType.Underline:
		case NodeType.Strikethrough:
		case NodeType.Sequence: {
			if (collectMetrics) {
				performanceMetrics.startOperation('createFormattingNode.parseNested');
			}

			const innerNodes = parseInlineWithContext(innerContent, newContext);

			if (collectMetrics) {
				performanceMetrics.endOperation('createFormattingNode.parseNested');
			}

			return {
				type: nodeType,
				children: innerNodes,
			};
		}

		default:
			// This should never happen if we've defined all formatting node types
			throw new Error(`Unexpected node type: ${nodeType}`);
	}
}
