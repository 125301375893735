import {GuildNavKind, MentionKind, type MentionNode, NodeType, ParserFlags, type ParserResult} from '../types';
import {performanceMetrics} from '../utils/performance-metrics';

// Character codes for faster comparisons
const LESS_THAN = 60; // '<'
const AT_SIGN = 64; // '@'
const HASH = 35; // '#'
const AMPERSAND = 38; // '&'
const SLASH = 47; // '/'
const LETTER_I = 105; // 'i'
const LETTER_D = 100; // 'd'
const COLON = 58; // ':'
const DIGIT_ZERO = 48; // '0'
const DIGIT_NINE = 57; // '9'

/**
 * Parses a mention in angle brackets
 * This handles user, channel, role, command, and guild navigation mentions
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no mention found
 */
export function parseMention(text: string, parserFlags: number, collectMetrics = false): ParserResult | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('MentionParsers.parseMention');
	}

	// Fast path: Check if first character is '<'
	if (text.length < 2 || text.charCodeAt(0) !== LESS_THAN) {
		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseMention');
		}
		return null;
	}

	const end = text.indexOf('>');
	if (end === -1) {
		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseMention');
		}
		return null;
	}

	const secondCharCode = text.charCodeAt(1);

	// Fast type detection based on second character
	let mentionNode: MentionNode | null = null;

	if (secondCharCode === AT_SIGN) {
		if (collectMetrics) {
			performanceMetrics.startOperation('MentionParsers.parseUserOrRoleMention');
		}

		mentionNode = parseUserOrRoleMention(text.slice(1, end), parserFlags);

		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseUserOrRoleMention');
		}
	} else if (secondCharCode === HASH) {
		if (collectMetrics) {
			performanceMetrics.startOperation('MentionParsers.parseChannelMention');
		}

		mentionNode = parseChannelMention(text.slice(1, end), parserFlags);

		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseChannelMention');
		}
	} else if (secondCharCode === SLASH) {
		if (collectMetrics) {
			performanceMetrics.startOperation('MentionParsers.parseCommandMention');
		}

		mentionNode = parseCommandMention(text.slice(1, end), parserFlags);

		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseCommandMention');
		}
	} else if (
		secondCharCode === LETTER_I &&
		text.length > 3 &&
		text.charCodeAt(2) === LETTER_D &&
		text.charCodeAt(3) === COLON
	) {
		if (collectMetrics) {
			performanceMetrics.startOperation('MentionParsers.parseGuildNavigation');
		}

		mentionNode = parseGuildNavigation(text.slice(1, end), parserFlags);

		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseGuildNavigation');
		}
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('MentionParsers.parseMention');
	}

	return mentionNode ? {node: mentionNode, advance: end + 1} : null;
}

/**
 * Parses a simple mention (@everyone, @here) that doesn't use angle brackets
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no simple mention found
 */
export function parseSimpleMention(text: string, parserFlags: number, collectMetrics = false): ParserResult | null {
	if (collectMetrics) {
		performanceMetrics.startOperation('MentionParsers.parseSimpleMention');
	}

	// Fast path: Check feature flag and first character
	if (!(parserFlags & ParserFlags.ALLOW_EVERYONE_MENTIONS) || text.length < 2 || text.charCodeAt(0) !== AT_SIGN) {
		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseSimpleMention');
		}
		return null;
	}

	// Check for @everyone (9 characters)
	if (text.length >= 9 && text.startsWith('@everyone')) {
		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseSimpleMention');
		}
		return {
			node: {type: NodeType.Mention, kind: {kind: MentionKind.Everyone}},
			advance: 9,
		};
	}

	// Check for @here (5 characters)
	if (text.length >= 5 && text.startsWith('@here')) {
		if (collectMetrics) {
			performanceMetrics.endOperation('MentionParsers.parseSimpleMention');
		}
		return {
			node: {type: NodeType.Mention, kind: {kind: MentionKind.Here}},
			advance: 5,
		};
	}

	if (collectMetrics) {
		performanceMetrics.endOperation('MentionParsers.parseSimpleMention');
	}

	return null;
}

/**
 * Checks if a string contains only digits
 * Faster than using regex for short strings
 */
function isDigitOnly(text: string): boolean {
	for (let i = 0; i < text.length; i++) {
		const charCode = text.charCodeAt(i);
		if (charCode < DIGIT_ZERO || charCode > DIGIT_NINE) {
			return false;
		}
	}
	return text.length > 0;
}

/**
 * Parses a user or role mention
 * User mentions: <@123456789>, <@!123456789>
 * Role mentions: <@&123456789>
 *
 * @param inner - The content inside the angle brackets
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Mention node or null if not a valid user/role mention
 */
export function parseUserOrRoleMention(inner: string, parserFlags: number): MentionNode | null {
	// Fast path: Check minimum length and first character
	if (inner.length < 2 || inner.charCodeAt(0) !== AT_SIGN) {
		return null;
	}

	// Check for role mention <@&123456789>
	if (inner.length > 2 && inner.charCodeAt(1) === AMPERSAND) {
		// Role mention
		const roleId = inner.slice(2);
		if (isDigitOnly(roleId) && parserFlags & ParserFlags.ALLOW_ROLE_MENTIONS) {
			return {
				type: NodeType.Mention,
				kind: {kind: MentionKind.Role, id: roleId},
			};
		}
	} else {
		// User mention - can be <@123456> or <@!123456> (the ! was used for nicknames)
		const userId = inner.startsWith('@!') ? inner.slice(2) : inner.slice(1);
		if (isDigitOnly(userId) && parserFlags & ParserFlags.ALLOW_USER_MENTIONS) {
			return {
				type: NodeType.Mention,
				kind: {kind: MentionKind.User, id: userId},
			};
		}
	}

	return null;
}

/**
 * Parses a channel mention <#123456789>
 *
 * @param inner - The content inside the angle brackets
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Mention node or null if not a valid channel mention
 */
export function parseChannelMention(inner: string, parserFlags: number): MentionNode | null {
	// Fast path: Check minimum length and first character
	if (inner.length < 2 || inner.charCodeAt(0) !== HASH || !(parserFlags & ParserFlags.ALLOW_CHANNEL_MENTIONS)) {
		return null;
	}

	const channelId = inner.slice(1);
	if (isDigitOnly(channelId)) {
		return {
			type: NodeType.Mention,
			kind: {kind: MentionKind.Channel, id: channelId},
		};
	}

	return null;
}

/**
 * Parses a command mention </command:123456789>
 * Command mentions can have subcommands and subcommand groups
 *
 * @param inner - The content inside the angle brackets
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Mention node or null if not a valid command mention
 */
export function parseCommandMention(inner: string, parserFlags: number): MentionNode | null {
	// Fast path: Check feature flag and first character
	if (!(parserFlags & ParserFlags.ALLOW_COMMAND_MENTIONS) || inner.length < 2 || inner.charCodeAt(0) !== SLASH) {
		return null;
	}

	// Command mentions are formatted as: /command:id or /command subcommand:id or /command subcommandgroup subcommand:id
	const colonIndex = inner.indexOf(':');
	if (colonIndex === -1) return null;

	const commandPart = inner.slice(0, colonIndex);
	const idPart = inner.slice(colonIndex + 1);

	if (!idPart || !isDigitOnly(idPart)) return null;

	// Split the command part to get the command name and possible subcommands
	const segments = commandPart.slice(1).trim().split(' ');
	if (segments.length === 0) return null;

	return {
		type: NodeType.Mention,
		kind: {
			kind: MentionKind.Command,
			name: segments[0],
			// If we have 3 segments, the middle one is a subcommand group
			subcommandGroup: segments.length === 3 ? segments[1] : undefined,
			// If we have 2 or more segments, the last one is a subcommand
			subcommand: segments.length >= 2 ? segments[segments.length - 1] : undefined,
			id: idPart,
		},
	};
}

/**
 * Parses a guild navigation mention <id:type> or <id:type:id>
 * These are used for specific Discord UI navigations
 *
 * @param inner - The content inside the angle brackets
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Mention node or null if not a valid guild navigation mention
 */
export function parseGuildNavigation(inner: string, parserFlags: number): MentionNode | null {
	// Fast path: Check feature flag and minimum length
	if (!(parserFlags & ParserFlags.ALLOW_GUILD_NAVIGATIONS) || inner.length < 5) {
		return null;
	}

	// Fast check for "id:" prefix
	if (inner.charCodeAt(0) !== LETTER_I || inner.charCodeAt(1) !== LETTER_D || inner.charCodeAt(2) !== COLON) {
		return null;
	}

	const parts = inner.split(':');
	if (parts.length < 2 || parts.length > 3) return null;

	const [idLabel, navType, navId] = parts;
	if (idLabel !== 'id') return null;

	const navigationType = getNavigationType(navType);
	if (!navigationType) return null;

	// Special case for linked roles
	if (navigationType === GuildNavKind.LinkedRoles) {
		return createLinkedRolesNavigation(parts.length === 3 ? navId : undefined);
	}

	// Basic navigation types require exactly 2 parts
	if (parts.length !== 2) return null;
	return createBasicNavigation(navigationType);
}

/**
 * Gets the navigation type from a string
 *
 * @param navTypeLower - The navigation type string
 * @returns The corresponding GuildNavKind or null if invalid
 */
export function getNavigationType(navTypeLower: string): GuildNavKind | null {
	switch (navTypeLower) {
		case 'customize':
			return GuildNavKind.Customize;
		case 'browse':
			return GuildNavKind.Browse;
		case 'guide':
			return GuildNavKind.Guide;
		case 'linked-roles':
			return GuildNavKind.LinkedRoles;
		default:
			return null;
	}
}

/**
 * Creates a LinkedRoles navigation mention
 *
 * @param id - Optional ID for the linked role
 * @returns Mention node for LinkedRoles navigation
 */
export function createLinkedRolesNavigation(id?: string): MentionNode {
	return {
		type: NodeType.Mention,
		kind: {
			kind: MentionKind.GuildNavigation,
			navigationType: GuildNavKind.LinkedRoles,
			id,
		},
	};
}

/**
 * Creates a basic navigation mention
 *
 * @param navigationType - The type of navigation
 * @returns Mention node for basic navigation
 */
export function createBasicNavigation(navigationType: GuildNavKind): MentionNode {
	return {
		type: NodeType.Mention,
		kind: {
			kind: MentionKind.GuildNavigation,
			navigationType,
		},
	};
}
