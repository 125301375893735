import {At, BookmarkSimple, Download, type Icon, List, PushPin, Users} from '@phosphor-icons/react';
import {clsx} from 'clsx';
import React from 'react';
import {useParams} from 'react-router-dom';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {ChannelMembers} from '~/components/channel/ChannelMembers';
import {ChannelTextarea} from '~/components/channel/ChannelTextarea';
import {Chat} from '~/components/channel/Chat';
import {ChannelTopicModal} from '~/components/modals/ChannelTopicModal';
import {ChannelPinsPopout} from '~/components/popouts/ChannelPinsPopout';
import {RecentMentionsPopout} from '~/components/popouts/RecentMentionsPopout';
import {SavedMessagesPopout} from '~/components/popouts/SavedMessagesPopout';
import {Popout} from '~/components/uikit/Popout/Popout';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import {SafeMarkdown} from '~/lib/markdown';
import {MarkdownContext} from '~/lib/markdown/renderers';
import type {ChannelRecord} from '~/records/ChannelRecord';
import AutoUpdateStore from '~/stores/AutoUpdateStore';
import ChannelStore from '~/stores/ChannelStore';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import GuildStore from '~/stores/GuildStore';
import MemberListStore from '~/stores/MemberListStore';
import * as ChannelUtils from '~/utils/ChannelUtils';

type ChannelHeaderIconProps = {
	icon: Icon;
	label: string;
	isSelected?: boolean;
	onClick?: () => void;
};

const ChannelHeaderIcon = React.forwardRef((props: ChannelHeaderIconProps, ref: React.Ref<HTMLDivElement>) => {
	const {icon: Icon, label, isSelected = false, onClick, ...rest} = props;
	return (
		<Tooltip text={label} position="bottom">
			<div
				{...rest}
				ref={ref}
				className={clsx(
					'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full transition-colors duration-200',
					isSelected ? 'text-text-primary' : 'text-text-primary-muted hover:text-text-primary',
				)}
				aria-label={label}
				onClick={onClick}
				onKeyDown={(event) => event.key === 'Enter' && onClick?.()}
				role="button"
				tabIndex={0}
			>
				<Icon className="h-6 w-6" />
			</div>
		</Tooltip>
	);
});

ChannelHeaderIcon.displayName = 'ChannelHeaderIcon';

const ChannelPinsButton = ({channel}: {channel: ChannelRecord}) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Popout
			uniqueId="channel-pins"
			tooltip={i18n.Messages.PINNED_MESSAGES}
			tooltipPosition="bottom"
			render={() => <ChannelPinsPopout channel={channel} />}
			position="bottom-end"
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			subscribeTo="CHANNEL_PINS_OPEN" // Let the Popout handle the subscription
		>
			<div
				aria-label={i18n.Messages.PINNED_MESSAGES}
				role="button"
				tabIndex={0}
				className={clsx(
					'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full transition-colors duration-200',
					isOpen ? 'text-text-primary' : 'text-text-primary-muted hover:text-text-primary',
				)}
			>
				<PushPin className="h-6 w-6" />
			</div>
		</Popout>
	);
};

const SavedMessagesButton = () => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<Popout
			uniqueId="saved-messages"
			tooltip={i18n.Messages.SAVED_MESSAGES}
			tooltipPosition="bottom"
			render={() => <SavedMessagesPopout />}
			position="bottom-end"
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			subscribeTo="SAVED_MESSAGES_OPEN"
		>
			<div
				aria-label={i18n.Messages.SAVED_MESSAGES}
				role="button"
				tabIndex={0}
				className={clsx(
					'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full transition-colors duration-200',
					isOpen ? 'text-text-primary' : 'text-text-primary-muted hover:text-text-primary',
				)}
			>
				<BookmarkSimple className="h-6 w-6" />
			</div>
		</Popout>
	);
};

const RecentMentionsButton = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	return (
		<Popout
			uniqueId="recent-mentions"
			tooltip={i18n.Messages.RECENT_MENTIONS}
			tooltipPosition="bottom"
			tooltipAlign="right"
			render={() => <RecentMentionsPopout />}
			position="bottom-end"
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
		>
			<div
				aria-label={i18n.Messages.RECENT_MENTIONS}
				role="button"
				tabIndex={0}
				className={clsx(
					'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full transition-colors duration-200',
					isOpen ? 'text-text-primary' : 'text-text-primary-muted hover:text-text-primary',
				)}
			>
				<At weight="bold" className="h-6 w-6" />
			</div>
		</Popout>
	);
};

const ChannelHeader = ({channel}: {channel: ChannelRecord}) => {
	const {forceUpdateReady} = DeveloperOptionsStore.useStore();
	const {updateReady} = AutoUpdateStore.useStore();
	const {isMembersOpen} = MemberListStore.useStore();

	return (
		<div className="flex w-full min-w-0 flex-none items-center justify-between gap-8 border-theme-border border-b-theme-border-width bg-background-channel-header px-4 py-2 text-text-primary">
			<div className="flex min-w-0 items-center gap-3">
				<div className="relative flex min-w-0 flex-auto flex-shrink-0 items-center gap-1.5 overflow-hidden">
					<button
						type="button"
						className="flex-shrink-0 md:hidden"
						onClick={() =>
							Dispatcher.dispatch({
								type: 'MOBILE_LAYOUT_STATE_UPDATE',
								navExpanded: true,
								chatExpanded: false,
							})
						}
					>
						<List className="h-6 w-6" />
					</button>

					<div className="flex items-center gap-1">
						{ChannelUtils.getIcon(channel.type, {className: 'h-6 w-6 flex-shrink-0 text-text-primary-muted'})}
						<div className="truncate font-medium">{channel.name}</div>
					</div>
				</div>

				{channel.topic && (
					<div className="relative min-w-0 flex-auto cursor-pointer truncate font-medium text-sm text-text-primary-muted leading-[18px]">
						<div
							className="absolute inset-0"
							role="button"
							tabIndex={0}
							onClick={() => ModalActionCreators.push(() => <ChannelTopicModal channelId={channel.id} />)}
							onKeyDown={(event) =>
								event.key === 'Enter' && ModalActionCreators.push(() => <ChannelTopicModal channelId={channel.id} />)
							}
						/>
						<SafeMarkdown
							content={channel.topic}
							options={{
								context: MarkdownContext.RESTRICTED_INLINE_REPLY,
								channelId: channel.id,
							}}
						/>
					</div>
				)}
			</div>

			<div className="flex min-w-0 flex-none items-center gap-1 text-text-primary">
				<ChannelPinsButton channel={channel} />

				<ChannelHeaderIcon
					icon={Users}
					isSelected={isMembersOpen}
					label={isMembersOpen ? i18n.Messages.MEMBERS_HIDE : i18n.Messages.MEMBERS_SHOW}
					onClick={() => Dispatcher.dispatch({type: 'MEMBERS_TOGGLE', isOpen: !isMembersOpen})}
				/>

				{(updateReady || forceUpdateReady) && (
					<Tooltip text={i18n.Messages.UPDATE_READY} position="bottom">
						<div
							className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-green-500 transition-colors duration-200"
							onClick={() => location.reload()}
							onKeyDown={(event) => event.key === 'Enter' && location.reload()}
							role="button"
							tabIndex={0}
						>
							<Download className="h-6 w-6" />
						</div>
					</Tooltip>
				)}

				<SavedMessagesButton />
				<RecentMentionsButton />
			</div>
		</div>
	);
};

export const ChannelIndexPage = () => {
	const {guildId, channelId} = useParams<{guildId: string; channelId: string}>();
	const guild = GuildStore.useGuild(guildId);
	const channel = ChannelStore.useChannel(channelId);
	const {isMembersOpen} = MemberListStore.useStore();

	if (!(guild && channel)) {
		return null;
	}

	return (
		<div className="grid h-full min-h-0 w-full min-w-0 grid-rows-[3.5rem,1fr] bg-background-chat-primary">
			<ChannelHeader channel={channel} />
			<div className="grid h-full min-h-0 w-full min-w-0 grid-cols-[1fr,auto]">
				<div className="flex min-h-0 min-w-0 flex-col overflow-hidden">
					<Chat channel={channel} />
					<ChannelTextarea channel={channel} guild={guild} />
				</div>
				{isMembersOpen && <ChannelMembers channel={channel} guild={guild} />}
			</div>
		</div>
	);
};
