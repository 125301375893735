import {ArrowBendUpLeft, ArrowRight, type Icon, PushPin} from '@phosphor-icons/react';
import {clsx} from 'clsx';
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {ChannelTypes, MessageEmbedTypes, type MessageState, MessageStates, MessageTypes, UserTypes} from '~/Constants';
import * as MessageActionCreators from '~/actions/MessageActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ReadStateActionCreators from '~/actions/ReadStateActionCreators';
import {Invite} from '~/components/channel/Invite';
import {MessageActionBar} from '~/components/channel/MessageActionBar';
import {MessageReactions} from '~/components/channel/MessageReactions';
import {PreloadableUserPopout} from '~/components/channel/PreloadableUserPopout';
import {UserTag} from '~/components/channel/UserTag';
import {Embed} from '~/components/channel/embeds/Embed';
import {Attachment} from '~/components/channel/embeds/attachments/Attachment';
import {ConfirmModal} from '~/components/modals/ConfirmModal';
import {Avatar} from '~/components/uikit/Avatar';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import Dispatcher from '~/flux/Dispatcher';
import {useHover} from '~/hooks/useHover';
import {i18n} from '~/i18n';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import {SafeMarkdown} from '~/lib/markdown';
import {NodeType} from '~/lib/markdown/parser';
import {MarkdownContext, parse} from '~/lib/markdown/renderers';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {GuildRecord} from '~/records/GuildRecord';
import type {MessageRecord} from '~/records/MessageRecord';
import type {UserRecord} from '~/records/UserRecord';
import ChannelStore from '~/stores/ChannelStore';
import GuildMemberStore from '~/stores/GuildMemberStore';
import GuildStore from '~/stores/GuildStore';
import MessageEditStore from '~/stores/MessageEditStore';
import MessageReferenceStore, {MessageReferenceState} from '~/stores/MessageReferenceStore';
import MessageReplyStore from '~/stores/MessageReplyStore';
import MessageStore from '~/stores/MessageStore';
import ReadStateStore, {type ReadState} from '~/stores/ReadStateStore';
import UserSettingsStore, {type UserSettings} from '~/stores/UserSettingsStore';
import UserStore from '~/stores/UserStore';
import markupStyles from '~/styles/Markup.module.css';
import styles from '~/styles/Message.module.css';
import * as DateUtils from '~/utils/DateUtils';
import * as NicknameUtils from '~/utils/NicknameUtils';
import * as ReadStateUtils from '~/utils/ReadStateUtils';
import {SystemMessageUtils} from '~/utils/SystemMessageUtils';

const MESSAGE_GROUP_INTERVAL = 10 * 60 * 1000;

const MessageStateToClassName: Record<MessageState, string> = {
	[MessageStates.SENT]: styles.messageSent,
	[MessageStates.SENDING]: styles.messageSending,
	[MessageStates.FAILED]: styles.messageFailed,
};

const isSameAuthor = (message: MessageRecord, prevMessage: MessageRecord) => {
	if (message.webhookId) {
		return (
			message.author.username === prevMessage.author.username && message.author.avatar === prevMessage.author.avatar
		);
	}
	return message.author.id === prevMessage.author.id;
};

const isWithinGroupInterval = (prevMessage: MessageRecord, message: MessageRecord) =>
	prevMessage.createdAt + MESSAGE_GROUP_INTERVAL > message.createdAt;

const isFirstMessageOfDay = (current: MessageRecord, previous?: MessageRecord) => {
	if (!previous) return true;
	const currentDate = new Date(current.createdAt);
	const previousDate = new Date(previous.createdAt);
	return (
		currentDate.getDate() !== previousDate.getDate() ||
		currentDate.getMonth() !== previousDate.getMonth() ||
		currentDate.getFullYear() !== previousDate.getFullYear()
	);
};

const shouldGroupMessages = (channel: ChannelRecord, message: MessageRecord, prevMessage?: MessageRecord) => {
	if (!prevMessage) return false;
	if (isFirstMessageOfDay(message, prevMessage)) return false;

	if (channel.type === ChannelTypes.GUILD_DOCUMENT) return true;

	const isUserMessage = message.type === MessageTypes.DEFAULT && prevMessage.isUserMessage();
	if (isUserMessage && isSameAuthor(message, prevMessage) && isWithinGroupInterval(prevMessage, message)) {
		return true;
	}

	const isNonUserMessage = !message.isUserMessage() && !prevMessage.isUserMessage();
	const isSameType = message.type === prevMessage.type;
	if (isNonUserMessage && isSameType) return true;

	return false;
};

const handleAltClickEvent = (event: React.MouseEvent, message: MessageRecord, prevMessage?: MessageRecord) => {
	if (!event.altKey) return;
	const messageId = ReadStateUtils.getPreviousMessageId(message, prevMessage ?? null);
	ReadStateActionCreators.ack({
		channelId: message.channelId,
		messageId,
		mentionCount: MessageStore.getCountBelowMessage(message.channelId, messageId, true),
		manual: true,
	});
};

const handleDeleteMessage = (bypassConfirm: boolean, message: MessageRecord) => {
	if (bypassConfirm) {
		MessageActionCreators.remove(message.channelId, message.id);
		return;
	}
	ModalActionCreators.push(() => (
		<ConfirmModal
			title={i18n.Messages.DELETE_MESSAGE}
			description={i18n.Messages.DELETE_MESSAGE_CONFIRM_DESCRIPTION}
			message={message}
			primaryText={i18n.Messages.DELETE}
			onPrimary={() => MessageActionCreators.remove(message.channelId, message.id)}
		/>
	));
};

const SystemMessageUsername = ({author, guild}: {author: UserRecord; guild: GuildRecord}) => {
	const member = GuildMemberStore.getMember(guild.id, author.id);
	return (
		<PreloadableUserPopout user={author} isWebhook={false} guildId={guild.id}>
			<span
				className="relative inline cursor-pointer overflow-hidden align-baseline font-medium text-text-primary leading-[1.375rem] hover:underline"
				style={{color: member?.getHighestRoleColor()}}
			>
				{NicknameUtils.getNickname(author, guild.id)}
			</span>
		</PreloadableUserPopout>
	);
};

const SystemMessage = ({
	icon: Icon,
	iconWeight,
	iconClassname,
	message,
	messageContent,
}: {
	icon: Icon;
	iconWeight: 'regular' | 'fill';
	iconClassname?: string;
	message: MessageRecord;
	messageContent: React.ReactNode;
}) => {
	const formattedDate = DateUtils.getRelativeDateString(message.createdAt);
	return (
		<>
			<div className="static ml-0 pl-0 indent-0">
				<div className="-ml-[72px] relative select-text overflow-hidden break-words pl-[72px] text-text-chat leading-[1.375rem]">
					<div className="relative flex items-start whitespace-nowrap py-[.125rem] text-text-primary-muted">
						<div className="absolute right-full flex w-[2.5rem] items-center justify-center pt-[.15em]">
							<Icon weight={iconWeight} className={clsx('h-[18px] w-[18px]', iconClassname)} />
						</div>
						<div className="-ml-[72px] relative select-text overflow-hidden break-words pl-[72px] text-text-chat leading-[1.375rem]">
							{messageContent}{' '}
							<span className="inline-block h-5 cursor-default align-baseline font-medium text-text-chat-muted text-xs leading-[1.375rem]">
								<Tooltip
									delay={750}
									text={DateUtils.getFormattedDateTimeWithSeconds(message.createdAt)}
									maxWidth="none"
								>
									<time aria-label={formattedDate} dateTime={new Date(message.createdAt).toISOString()}>
										<i className="absolute inline-block not-italic opacity-0">{' — '}</i>
										{formattedDate}
									</time>
								</Tooltip>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.container}>
				{UserSettingsStore.getRenderReactions() && message.reactions.length > 0 && (
					<MessageReactions message={message} />
				)}
			</div>
		</>
	);
};

const GuildJoinMessage = ({message}: {message: MessageRecord}) => {
	const author = UserStore.useUser(message.author.id)!;
	const channel = ChannelStore.getChannel(message.channelId)!;
	const guild = GuildStore.getGuild(channel.guildId)!;
	const i18nMessage = SystemMessageUtils.getGuildJoinMessage(message.id);
	const messageContent = i18n.format(i18nMessage, {
		username: <SystemMessageUsername author={author} guild={guild} key={author.id} />,
	});
	return (
		<SystemMessage
			icon={ArrowRight}
			iconWeight="regular"
			iconClassname="text-green-500"
			message={message}
			messageContent={messageContent}
		/>
	);
};

const PinSystemMessage = ({message}: {message: MessageRecord}) => {
	const author = UserStore.useUser(message.author.id)!;
	const channel = ChannelStore.getChannel(message.channelId)!;
	const guild = GuildStore.getGuild(channel.guildId)!;

	const jumpToMessage = React.useCallback(() => {
		ComponentDispatch.dispatch('MESSAGE_JUMP', {
			messageId: message.messageReference?.message_id ?? '',
		});
	}, [message.messageReference?.message_id]);

	const openPins = React.useCallback(() => {
		ComponentDispatch.dispatch('CHANNEL_PINS_OPEN');
	}, []);

	const messageContent = i18n.format(i18n.Messages.PIN_SYSTEM_MESSAGE, {
		username: <SystemMessageUsername key={author.id} author={author} guild={guild} />,
		message: (
			<span
				key={`pin-${message.id}`}
				className="inline-block cursor-pointer align-baseline font-medium text-text-primary leading-[1.375rem] hover:underline"
				role="button"
				tabIndex={0}
				onClick={jumpToMessage}
				onKeyDown={(event) => event.key === 'Enter' && jumpToMessage()}
			>
				{i18n.Messages.PIN_SYSTEM_MESSAGE_1}
			</span>
		),
		all: (
			<span
				key={`pin-all-${message.id}`}
				className="inline-block cursor-pointer align-baseline font-medium text-text-primary leading-[1.375rem] hover:underline"
				role="button"
				tabIndex={0}
				onClick={openPins}
				onKeyDown={(event) => event.key === 'Enter' && openPins()}
			>
				{i18n.Messages.PIN_SYSTEM_MESSAGE_2}
			</span>
		),
	});

	return <SystemMessage icon={PushPin} iconWeight="fill" message={message} messageContent={messageContent} />;
};

const ReplyPreview = ({
	message,
	channelId,
	animateEmoji,
}: {
	message: MessageRecord;
	channelId: string;
	animateEmoji: boolean;
}) => {
	if (!message.messageReference) return null;

	const {message: referencedMessage, state: messageState} = MessageReferenceStore.useMessageWithStatus(
		message.messageReference.channel_id,
		message.messageReference.message_id ?? '',
	);

	const guildId = ChannelStore.getChannel(channelId)!.guildId;

	const jumpToRepliedMessage = React.useCallback(() => {
		if (message.messageReference?.message_id) {
			ComponentDispatch.dispatch('MESSAGE_JUMP', {
				messageId: message.messageReference.message_id,
			});
		}
	}, [message.messageReference]);

	const isLoaded = messageState === MessageReferenceState.LOADED && referencedMessage;

	return (
		<div
			className={clsx(
				'relative flex select-none items-center whitespace-pre text-sm text-text-primary-muted leading-[1.125rem]',
				styles.repliedMessage,
			)}
		>
			{isLoaded ? (
				<PreloadableUserPopout
					user={referencedMessage.author}
					isWebhook={referencedMessage.webhookId != null}
					guildId={guildId}
				>
					<Avatar
						user={referencedMessage.author}
						size={16}
						className="active:translate-z-0 mr-[.25rem] flex-none transform-gpu cursor-pointer active:translate-y-px"
					/>
				</PreloadableUserPopout>
			) : (
				<div className="mr-[.25rem] flex h-4 w-4 items-center justify-center rounded-full bg-background-primary text-text-primary">
					<ArrowBendUpLeft weight="bold" className="h-[7.2px] w-[10.8px]" />
				</div>
			)}

			{isLoaded && (
				<PreloadableUserPopout
					user={referencedMessage.author}
					isWebhook={referencedMessage.webhookId != null}
					guildId={guildId}
				>
					<span
						className="relative mr-[.25rem] inline flex-shrink-0 cursor-pointer overflow-hidden align-baseline font-medium text-text-primary leading-[inherit] opacity-[.64] hover:underline"
						style={{
							color: GuildMemberStore.getMember(guildId, referencedMessage.author.id)?.getHighestRoleColor(),
						}}
					>
						{message.mentions.some((mention) => mention.id === referencedMessage.author.id) && '@'}
						{NicknameUtils.getNickname(referencedMessage.author, guildId)}
					</span>
				</PreloadableUserPopout>
			)}

			<div
				className={clsx(
					'flex max-h-[1.25em] flex-initial cursor-default text-text-chat-muted',
					styles.repliedTextPreview,
					isLoaded && 'cursor-pointer hover:text-text-chat',
				)}
				role="button"
				tabIndex={0}
				onClick={isLoaded ? jumpToRepliedMessage : undefined}
				onKeyDown={(event) => event.key === 'Enter' && isLoaded && jumpToRepliedMessage()}
			>
				{isLoaded ? (
					referencedMessage.content ? (
						<span className={clsx(styles.repliedTextContent, markupStyles.markup, styles.messageContent)}>
							<SafeMarkdown
								content={referencedMessage.content}
								options={{
									context: MarkdownContext.RESTRICTED_INLINE_REPLY,
									messageId: referencedMessage.id,
									channelId,
									disableAnimatedEmoji: !animateEmoji,
								}}
							/>
						</span>
					) : (
						<span className={clsx(styles.repliedTextContent, 'pr-[2px] italic')}>
							{i18n.Messages.MESSAGE_CONTAINS_ATTACHED_MEDIA}
						</span>
					)
				) : messageState === MessageReferenceState.DELETED ? (
					<span className="pr-[2px] italic">{i18n.Messages.ORIGINAL_MESSAGE_DELETED}</span>
				) : (
					<span className="pr-[2px] italic">{i18n.Messages.ORIGINAL_MESSAGE_FAILED_TO_LOAD}</span>
				)}
			</div>
		</div>
	);
};

const EditingMessageInput = ({
	channel,
	onCancel,
	onSubmit,
	textareaRef,
	value,
	setValue,
}: {
	channel: ChannelRecord;
	onCancel: () => void;
	onSubmit: () => void;
	textareaRef: React.RefObject<HTMLTextAreaElement>;
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
}) => (
	<div className="relative">
		<TextareaAutosize
			autoFocus
			className="no-scrollbar relative mt-2 flex h-full max-h-[50vh] min-h-[44px] w-full resize-none overflow-x-hidden overflow-y-scroll whitespace-pre-wrap break-words rounded-md bg-background-textarea p-[11px] text-text-chat leading-[1.375rem] caret-text-chat"
			maxLength={channel.type === ChannelTypes.GUILD_DOCUMENT ? undefined : 4000}
			ref={textareaRef}
			value={value}
			onChange={(e) => setValue(e.target.value)}
			onKeyDown={(e) => {
				if (e.key === 'Enter' && !e.shiftKey) {
					e.preventDefault();
					onSubmit();
				} else if (e.key === 'Escape') {
					e.preventDefault();
					onCancel();
				}
			}}
		/>
		<div className="py-2 text-text-chat text-xs">
			{i18n.format(i18n.Messages.EDITING_MESSAGE_HELPER_TEXT_CANCEL, {
				cancel: (
					<button type="button" className="text-text-link hover:underline" onClick={onCancel} key="cancel">
						{i18n.Messages.EDITING_MESSAGE_HELPER_TEXT_CANCEL_TEXT}
					</button>
				),
			})}
			<div aria-hidden={true} className="mx-1 inline-block h-1 w-1 rounded-full bg-text-chat-muted align-middle" />
			{i18n.format(i18n.Messages.EDITING_MESSAGE_HELPER_TEXT_SAVE, {
				save: (
					<button type="button" className="text-text-link hover:underline" onClick={onSubmit} key="save">
						{i18n.Messages.EDITING_MESSAGE_HELPER_TEXT_SAVE_TEXT}
					</button>
				),
			})}
		</div>
	</div>
);

const UserMessage = ({
	message,
	channel,
	handleDelete,
	isHovering,
	shouldGroup,
	isPreview,
}: {
	message: MessageRecord;
	channel: ChannelRecord;
	handleDelete: (bypassConfirm?: boolean) => void;
	isHovering: boolean;
	shouldGroup: boolean;
	isPreview?: boolean;
}) => {
	const [animateEmoji, setAnimateEmoji] = React.useState(UserSettingsStore.getAnimateEmoji());
	const [value, setValue] = React.useState('');
	const isEditing = MessageEditStore.useIsEditing(message.channelId, message.id);
	const author = message.webhookId != null ? message.author : UserStore.useUser(message.author.id)!;
	const formattedDate = DateUtils.getRelativeDateString(message.createdAt);
	const textareaRef = React.useRef<HTMLTextAreaElement>(null);
	const {nodes: astNodes} = React.useMemo(
		() =>
			parse({
				content: message.content,
				context: MarkdownContext.STANDARD_WITH_JUMBO,
			}),
		[message.content],
	);

	const shouldHideContent =
		UserSettingsStore.getRenderEmbeds() &&
		message.embeds.length > 0 &&
		message.embeds.every((embed) => embed.type === MessageEmbedTypes.IMAGE || embed.type === MessageEmbedTypes.GIFV) &&
		astNodes.length === 1 &&
		astNodes[0].type === NodeType.Link &&
		!message.shouldSuppressEmbeds;

	const guild = GuildStore.getGuild(channel.guildId)!;
	const member = GuildMemberStore.getMember(guild.id, author?.id ?? '');
	const shouldAppearAuthorless = channel.type === ChannelTypes.GUILD_DOCUMENT && !isPreview;

	React.useLayoutEffect(() => {
		if (isEditing) {
			setValue(message.content);
			textareaRef.current?.focus();
			textareaRef.current?.setSelectionRange(message.content.length, message.content.length);
		} else {
			setValue('');
		}
	}, [isEditing, message.content]);

	React.useEffect(() => {
		if (animateEmoji) return;
		const emojiImgs = document.querySelectorAll(
			`img[data-message-id="${message.id}"][data-animated="true"]`,
		) as NodeListOf<HTMLImageElement>;

		for (const img of emojiImgs) {
			const src = img.src;
			img.src = isHovering ? src.replace('.webp', '.gif') : src.replace('.gif', '.webp');
		}
	}, [animateEmoji, isHovering, message.id]);

	React.useEffect(() => {
		const handleUserSettingsUpdate = (userSettings: UserSettings) => {
			setAnimateEmoji(userSettings.animate_emoji);
			if (userSettings.animate_emoji) {
				const emojiImgs = document.querySelectorAll(
					`img[data-message-id="${message.id}"][data-animated="true"]`,
				) as NodeListOf<HTMLImageElement>;

				for (const img of emojiImgs) {
					const src = img.src;
					img.src = src.replace('.webp', '.gif');
				}
			}
		};
		return UserSettingsStore.subscribe(handleUserSettingsUpdate);
	}, [message.id]);

	const onSubmit = React.useCallback(() => {
		const content = value.trim();
		if (!content) {
			handleDelete();
			return;
		}
		MessageActionCreators.edit(channel.id, message.id, {content}).then(() => {
			Dispatcher.dispatch({type: 'MESSAGE_EDIT_STOP', channelId: channel.id});
		});
	}, [channel.id, handleDelete, message.id, value]);

	const cancelEditing = React.useCallback(() => {
		Dispatcher.dispatch({type: 'MESSAGE_EDIT_STOP', channelId: message.channelId});
	}, [message.channelId]);

	const renderAuthorInfo = () => {
		if (shouldAppearAuthorless) return null;

		if (!shouldGroup) {
			return (
				<>
					<PreloadableUserPopout user={author} isWebhook={message.webhookId != null} guildId={guild.id}>
						<Avatar
							user={author}
							size={40}
							className="active:translate-z-0 absolute left-4 z-[1] mt-[.125rem] transform-gpu cursor-pointer active:translate-y-px"
							forceAnimate={isHovering}
						/>
					</PreloadableUserPopout>

					<h3 className="relative min-h-[1.375rem] overflow-hidden whitespace-break-spaces text-text-chat-muted leading-[1.375rem]">
						<span className="mr-1 items-center">
							<PreloadableUserPopout user={author} isWebhook={message.webhookId != null} guildId={guild.id}>
								<span
									className="relative inline cursor-pointer font-medium text-text-primary leading-[1.375rem] hover:underline"
									style={{color: member?.getHighestRoleColor()}}
								>
									{NicknameUtils.getNickname(author, guild.id)}
								</span>
							</PreloadableUserPopout>

							{author.type === UserTypes.AUTOMATED && <UserTag className="mt-[.2em]" />}
						</span>

						<span className="ml-1 inline-block h-5 cursor-default align-baseline font-medium text-text-chat-muted text-xs leading-[1.375rem]">
							<Tooltip delay={750} text={DateUtils.getFormattedDateTimeWithSeconds(message.createdAt)} maxWidth="none">
								<time aria-label={formattedDate} dateTime={new Date(message.createdAt).toISOString()}>
									<i className="absolute inline-block not-italic opacity-0">{' — '}</i>
									{formattedDate}
								</time>
							</Tooltip>

							{author.pronouns && (
								<>
									<div
										aria-hidden={true}
										className="mx-1 inline-block h-1 w-1 rounded-full bg-text-chat-muted align-middle"
									/>
									<Tooltip delay={750} text={i18n.Messages.PRONOUNS}>
										<span>{author.pronouns}</span>
									</Tooltip>
								</>
							)}
						</span>
					</h3>
				</>
			);
		}

		return (
			<span
				className={clsx(
					'absolute left-0 z-[1] h-[1.375rem] w-[56px] select-none text-right text-[.6875rem] text-text-chat-muted leading-[1.375rem]',
					styles.timestampVisibleOnHover,
				)}
			>
				<Tooltip delay={750} text={DateUtils.getFormattedDateTimeWithSeconds(message.createdAt)} maxWidth="none">
					<time aria-label={formattedDate} dateTime={new Date(message.createdAt).toISOString()}>
						<i className="absolute inline-block not-italic opacity-0">{'['}</i>
						{DateUtils.getFormattedTime(message.createdAt)}
						<i className="absolute inline-block not-italic opacity-0">{']'}</i>
					</time>
				</Tooltip>
			</span>
		);
	};

	const renderMessageContent = () => {
		if (isEditing && !isPreview) {
			return (
				<EditingMessageInput
					channel={channel}
					onCancel={cancelEditing}
					onSubmit={onSubmit}
					textareaRef={textareaRef}
					value={value}
					setValue={setValue}
				/>
			);
		}

		if (shouldHideContent) return null;

		return (
			<div className={clsx(markupStyles.markup, markupStyles.message)}>
				<SafeMarkdown
					content={message.content}
					options={{
						context: MarkdownContext.STANDARD_WITH_JUMBO,
						messageId: message.id,
						channelId: message.channelId,
					}}
				/>
				{message.editedAt && (
					<span className="ml-px inline-block h-5 cursor-default align-baseline font-medium text-text-chat-muted text-xs leading-[1.375rem]">
						<Tooltip delay={750} text={DateUtils.getFormattedDateTimeWithSeconds(message.editedAt)} maxWidth="none">
							<time
								aria-label={`Edited ${DateUtils.getRelativeDateString(message.editedAt)}`}
								dateTime={new Date(message.editedAt).toISOString()}
							>
								<span className="select-none font-normal text-[.625rem] leading-none"> {i18n.Messages.EDITED}</span>
							</time>
						</Tooltip>
					</span>
				)}
			</div>
		);
	};

	return (
		<>
			{message.messageReference && (
				<ReplyPreview message={message} channelId={channel.id} animateEmoji={animateEmoji} />
			)}

			<div className="static ml-0 pl-0 indent-0">
				{renderAuthorInfo()}

				<div
					className={clsx(
						'relative select-text overflow-hidden whitespace-break-spaces break-words pl-[72px] leading-[1.375rem]',
						MessageStateToClassName[message.state],
						shouldAppearAuthorless ? '-ml-[120px]' : '-ml-[72px]',
					)}
				>
					{renderMessageContent()}
				</div>
			</div>

			<div className={clsx(styles.container, shouldAppearAuthorless && '-ml-[48px]')}>
				{message.invites.map((code) => (
					<Invite code={code} key={code} />
				))}

				{message.attachments.map((attachment) => (
					<Attachment attachment={attachment} key={attachment.id} isPreview={isPreview} />
				))}

				{UserSettingsStore.getRenderEmbeds() &&
					!message.shouldSuppressEmbeds &&
					message.embeds.map((embed) => <Embed embed={embed} key={embed.id} message={message} />)}

				{UserSettingsStore.getRenderReactions() && message.reactions.length > 0 && (
					<MessageReactions message={message} />
				)}
			</div>
		</>
	);
};

const getMessageComponent = (
	channel: ChannelRecord,
	message: MessageRecord,
	handleDelete: (bypassConfirm?: boolean) => void,
	isHovering: boolean,
	shouldGroup: boolean,
	isPreview?: boolean,
) => {
	switch (message.type) {
		case MessageTypes.USER_JOIN:
			return <GuildJoinMessage message={message} />;
		case MessageTypes.CHANNEL_PINNED_MESSAGE:
			return <PinSystemMessage message={message} />;
		default:
			return (
				<UserMessage
					channel={channel}
					message={message}
					handleDelete={handleDelete}
					isHovering={isHovering}
					shouldGroup={shouldGroup}
					isPreview={isPreview}
				/>
			);
	}
};

const renderFirstMessageOfDay = (channel: ChannelRecord, message: MessageRecord, prevMessage?: MessageRecord) => {
	if (channel.type !== ChannelTypes.GUILD_DOCUMENT && isFirstMessageOfDay(message, prevMessage)) {
		return (
			<div className="pointer-events-none relative right-auto left-auto z-10 mt-[1.5rem] mr-[.875rem] mb-[.5rem] ml-[1rem] flex h-0 items-center justify-center border-background-header-secondary border-t-[2px]">
				<span className="-mt-px flex-none rounded-md bg-background-chat-primary px-[4px] py-[2px] font-semibold text-text-chat-muted text-xs leading-[13px]">
					{DateUtils.getFormattedDateWithFullMonth(message.createdAt)}
				</span>
			</div>
		);
	}
	return null;
};

const renderReadSeparator = (
	message: MessageRecord,
	prevMessage: MessageRecord | null,
	readState: ReadState | null,
	shouldGroup: boolean,
) => {
	if (ReadStateUtils.shouldRenderSeparator(message, prevMessage, readState)) {
		return (
			<div
				className={clsx(
					'pointer-events-none relative z-[1] mr-[.875rem] ml-4 flex h-0 items-center justify-end border-status-danger border-t',
					shouldGroup ? 'my-1' : 'top-2',
				)}
			>
				<div className="relative flex items-center justify-center rounded-full bg-status-danger px-1 py-0.5 font-medium text-[10px] text-brand-primary-fill uppercase leading-none">
					{i18n.Messages.NEW}
				</div>
			</div>
		);
	}
	return null;
};

const renderFirstMessageOfDayWithReadState = (message: MessageRecord) => (
	<div className="pointer-events-none relative right-auto left-auto z-10 mt-[1.5rem] mr-[.875rem] mb-[.5rem] ml-[1rem] flex h-0 items-center border-status-danger border-t-[2px]">
		<div className="-top-[14px] -translate-x-1/2 absolute left-1/2 transform">
			<span className="-mt-px flex-none rounded-md bg-background-chat-primary px-[4px] py-[2px] font-semibold text-status-danger text-xs leading-[13px]">
				{DateUtils.getFormattedDateWithFullMonth(message.createdAt)}
			</span>
		</div>
		<div className="relative ml-auto flex items-center justify-center rounded-full bg-status-danger px-1 py-0.5 font-medium text-[10px] text-brand-primary-fill uppercase leading-none">
			{i18n.Messages.NEW}
		</div>
	</div>
);

export const Message = ({
	channel,
	message,
	prevMessage,
	isPreview,
}: {
	channel: ChannelRecord;
	message: MessageRecord;
	prevMessage?: MessageRecord;
	isPreview?: boolean;
}) => {
	const [hoverRef, isHovering] = useHover();
	const isEditing = MessageEditStore.useIsEditing(message.channelId, message.id);
	const isReplying = MessageReplyStore.useIsReplying(message.channelId, message.id);
	const isHighlight = MessageReplyStore.useIsHighlight(message.id);
	const readState = ReadStateStore.useReadState(channel.id);
	const shouldGroup = shouldGroupMessages(channel, message, prevMessage);
	const isNewDay = isFirstMessageOfDay(message, prevMessage);

	const handleAltClick = React.useCallback(
		(event: React.MouseEvent) => handleAltClickEvent(event, message, prevMessage),
		[message, prevMessage],
	);
	const handleDelete = React.useCallback(
		(bypassConfirm = false) => handleDeleteMessage(bypassConfirm, message),
		[message],
	);

	const messageComponent = React.useMemo(
		() => getMessageComponent(channel, message, handleDelete, isHovering, shouldGroup && !isNewDay, isPreview),
		[channel, message, handleDelete, isHovering, shouldGroup, isNewDay, isPreview],
	);

	const baseMessageClass = 'relative select-text break-words py-[.125rem] pr-12 pl-[72px]';
	const spacingBefore = isPreview || (shouldGroup && !isNewDay) ? '' : 'mt-[1.0625rem]';
	const hoverBackground =
		!(isPreview || isEditing) && channel.type !== ChannelTypes.GUILD_DOCUMENT
			? 'hover:bg-background-modifier-hover'
			: '';
	const highlightBefore =
		!isPreview && (message.isMentioned() || isReplying || isHighlight)
			? 'before:pointer-events-none before:absolute before:top-0 before:bottom-0 before:left-0 before:block before:w-[2px]'
			: '';
	const highlightClasses =
		isReplying || isHighlight
			? 'bg-blue-500/10 transition-colors duration-200 ease-in-out before:bg-blue-500 hover:bg-blue-500/10'
			: '';
	const mentionedClass = message.isMentioned() ? 'bg-yellow-500/10 before:bg-yellow-500 hover:bg-yellow-500/10' : '';

	const combinedClasses = clsx(
		styles.message,
		baseMessageClass,
		spacingBefore,
		hoverBackground,
		highlightBefore,
		isPreview ? null : highlightClasses || mentionedClass,
	);

	const firstMessageOfDayElement = !isPreview && isNewDay && renderFirstMessageOfDay(channel, message, prevMessage);
	const readSeparator =
		!isPreview && renderReadSeparator(message, prevMessage ?? null, readState, shouldGroup && !isNewDay);

	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div onClick={handleAltClick}>
			{firstMessageOfDayElement && readSeparator ? (
				renderFirstMessageOfDayWithReadState(message)
			) : (
				<>
					{firstMessageOfDayElement}
					{readSeparator}
				</>
			)}
			<div id={`message-${channel.id}-${message.id}`} className={combinedClasses} ref={hoverRef}>
				{messageComponent}
				{!isPreview && message.state !== MessageStates.SENDING && !isEditing && (
					<MessageActionBar message={message} handleDelete={handleDelete} />
				)}
			</div>
		</div>
	);
};
