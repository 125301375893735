import clsx from 'clsx';
import React from 'react';
import markupStyles from '~/styles/Markup.module.css';
import type {RendererProps} from '..';
import type {FormattingNode, NodeType} from '../../parser';

/**
 * Renders a strong (bold) formatting node
 */
export function StrongRenderer({node, key, renderChildren}: RendererProps<FormattingNode>): React.ReactElement {
	return <strong key={key}>{renderChildren(node.children)}</strong>;
}

/**
 * Renders an emphasis (italic) formatting node
 */
export function EmphasisRenderer({node, key, renderChildren}: RendererProps<FormattingNode>): React.ReactElement {
	return <em key={key}>{renderChildren(node.children)}</em>;
}

/**
 * Renders an underline formatting node
 */
export function UnderlineRenderer({node, key, renderChildren}: RendererProps<FormattingNode>): React.ReactElement {
	return <u key={key}>{renderChildren(node.children)}</u>;
}

/**
 * Renders a strikethrough formatting node
 */
export function StrikethroughRenderer({node, key, renderChildren}: RendererProps<FormattingNode>): React.ReactElement {
	return <s key={key}>{renderChildren(node.children)}</s>;
}

/**
 * Type extension for spoiler nodes to track if they're block-level
 */
interface SpoilerNode extends FormattingNode {
	type: NodeType.Spoiler;
	isBlock: boolean;
}

/**
 * Renders a spoiler formatting node
 */
export function SpoilerRenderer({node, key, renderChildren}: RendererProps<SpoilerNode>): React.ReactElement {
	const [revealed, setRevealed] = React.useState(false);

	const handleClick = React.useCallback(() => {
		if (!revealed) {
			setRevealed(true);
		}
		// No action needed if already revealed - let text selection work naturally
	}, [revealed]);

	const handleKeyDown = React.useCallback(
		(e: React.KeyboardEvent) => {
			if (!revealed && (e.key === 'Enter' || e.key === ' ')) {
				e.preventDefault();
				setRevealed(true);
			}
		},
		[revealed],
	);

	const isBlock = node.isBlock;
	const WrapperTag = isBlock ? 'div' : 'span';
	const SpoilerTag = isBlock ? 'div' : 'span';

	return (
		<WrapperTag
			key={key}
			className={clsx(markupStyles.spoilerWrapper, {
				[markupStyles.blockSpoilerWrapper]: isBlock,
			})}
		>
			<SpoilerTag
				className={clsx(markupStyles.spoiler, {
					[markupStyles.blockSpoiler]: isBlock,
				})}
				data-revealed={revealed}
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				role={revealed ? undefined : 'button'}
				tabIndex={revealed ? -1 : 0}
				aria-label={revealed ? 'Revealed spoiler' : 'Click to reveal spoiler'}
				aria-pressed={revealed}
			>
				<span className={markupStyles.spoilerContent}>{renderChildren(node.children)}</span>
			</SpoilerTag>
		</WrapperTag>
	);
}

/**
 * Renders a combined bold+italic (triple asterisk) node
 */
export function TripleAsteriskRenderer({node, key, renderChildren}: RendererProps<FormattingNode>): React.ReactElement {
	return (
		<strong key={key}>
			<em>{renderChildren(node.children)}</em>
		</strong>
	);
}

/**
 * Renders a combined bold+italic (triple underscore) node
 */
export function TripleUnderscoreRenderer({
	node,
	key,
	renderChildren,
}: RendererProps<FormattingNode>): React.ReactElement {
	return (
		<strong key={key}>
			<em>{renderChildren(node.children)}</em>
		</strong>
	);
}
