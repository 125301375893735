import {CircleWavyWarning, Info, LightbulbFilament, Warning, WarningCircle} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import markupStyles from '~/styles/Markup.module.css';
import {MarkdownContext, type RendererProps} from '..';
import {
	type AlertNode,
	AlertType,
	type BlockquoteNode,
	type HeadingNode,
	type ListNode,
	type SequenceNode,
	type SubtextNode,
	TableAlignment,
	type TableNode,
} from '../../parser';

/**
 * Renders a blockquote node
 */
export function BlockquoteRenderer({node, key, renderChildren}: RendererProps<BlockquoteNode>): React.ReactElement {
	return (
		<div key={key} className={markupStyles.blockquoteContainer}>
			<div className={markupStyles.blockquoteDivider} />
			<blockquote className={markupStyles.blockquoteContent}>{renderChildren(node.children)}</blockquote>
		</div>
	);
}

/**
 * Renders a list node
 */
export function ListRenderer({node, key, renderChildren, options}: RendererProps<ListNode>): React.ReactElement {
	const Tag = node.ordered ? 'ol' : 'ul';
	const isInlineContext = options.context === MarkdownContext.RESTRICTED_INLINE_REPLY;

	return (
		<Tag key={key} className={clsx(isInlineContext && markupStyles.inlineFormat)}>
			{node.items.map((item, i) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
				<li key={`${key}-item-${i}`} className={clsx(isInlineContext && markupStyles.inlineFormat)}>
					{renderChildren(item.children)}
				</li>
			))}
		</Tag>
	);
}

/**
 * Renders a heading node
 */
export function HeadingRenderer({node, key, renderChildren, options}: RendererProps<HeadingNode>): React.ReactElement {
	const Tag = `h${node.level}` as keyof React.JSX.IntrinsicElements;
	const isInlineContext = options.context === MarkdownContext.RESTRICTED_INLINE_REPLY;

	return (
		<Tag key={key} className={clsx(isInlineContext && markupStyles.inlineFormat)}>
			{renderChildren(node.children)}
		</Tag>
	);
}

/**
 * Renders a subtext (small) node
 */
export function SubtextRenderer({node, key, renderChildren, options}: RendererProps<SubtextNode>): React.ReactElement {
	const isInlineContext = options.context === MarkdownContext.RESTRICTED_INLINE_REPLY;

	return (
		<small key={key} className={clsx(isInlineContext && markupStyles.inlineFormat)}>
			{renderChildren(node.children)}
		</small>
	);
}

/**
 * Renders a sequence node
 */
export function SequenceRenderer({node, key, renderChildren}: RendererProps<SequenceNode>): React.ReactElement {
	return <React.Fragment key={key}>{renderChildren(node.children)}</React.Fragment>;
}

/**
 * Renders a table node
 */
export function TableRenderer({node, key, renderChildren}: RendererProps<TableNode>): React.ReactElement {
	return (
		<div key={key} className={markupStyles.tableContainer}>
			<table className={markupStyles.table}>
				<thead>
					<tr>
						{node.header.cells.map((cell, i) => (
							<th
								// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
								key={`header-${i}`}
								className={clsx(markupStyles.tableCell, markupStyles.tableHeader, {
									[markupStyles.alignLeft]: node.alignments[i] === TableAlignment.Left,
									[markupStyles.alignCenter]: node.alignments[i] === TableAlignment.Center,
									[markupStyles.alignRight]: node.alignments[i] === TableAlignment.Right,
								})}
							>
								{renderChildren(cell.children)}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{node.rows.map((row, rowIndex) => (
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						<tr key={`row-${rowIndex}`}>
							{row.cells.map((cell, cellIndex) => (
								<td
									// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
									key={`cell-${rowIndex}-${cellIndex}`}
									className={clsx(markupStyles.tableCell, {
										[markupStyles.alignLeft]: node.alignments[cellIndex] === TableAlignment.Left,
										[markupStyles.alignCenter]: node.alignments[cellIndex] === TableAlignment.Center,
										[markupStyles.alignRight]: node.alignments[cellIndex] === TableAlignment.Right,
									})}
								>
									{renderChildren(cell.children)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

/**
 * Renders an alert node
 */
export function AlertRenderer({node, key, renderChildren}: RendererProps<AlertNode>): React.ReactElement {
	const alertConfig: Record<
		AlertType,
		{
			Icon: React.ComponentType<{className?: string}>;
			className: string;
		}
	> = {
		[AlertType.Note]: {Icon: Info, className: markupStyles.alertNote},
		[AlertType.Tip]: {Icon: LightbulbFilament, className: markupStyles.alertTip},
		[AlertType.Important]: {Icon: Warning, className: markupStyles.alertImportant},
		[AlertType.Warning]: {Icon: CircleWavyWarning, className: markupStyles.alertWarning},
		[AlertType.Caution]: {Icon: WarningCircle, className: markupStyles.alertCaution},
	};

	const {Icon, className} = alertConfig[node.alertType] || alertConfig[AlertType.Note];

	return (
		<div key={key} className={clsx(markupStyles.alert, className)}>
			<Icon className={markupStyles.alertIcon} />
			<div className={markupStyles.alertContent}>{renderChildren(node.children)}</div>
		</div>
	);
}
