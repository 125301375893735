export interface OperationMetric {
	count: number;
	totalTime: number;
	averageTime: number;
	max: number;
	min: number;
	percentage: number;
}

/**
 * A simple utility for tracking performance metrics in the parser
 */
export class PerformanceMetrics {
	private metrics: Record<
		string,
		{
			count: number;
			totalTime: number;
			max: number;
			min: number;
		}
	> = {};
	private startTimes: Record<string, number> = {};
	private enabled = false; // Disabled by default
	private stack: Array<string> = [];

	/**
	 * Begins timing an operation
	 *
	 * @param operation - The name of the operation to time
	 */
	startOperation(operation: string): void {
		if (!this.enabled) return;

		// Use performance.now() for high-resolution timing
		this.startTimes[operation] = performance.now();
		this.stack.push(operation);
	}

	/**
	 * Ends timing an operation and records the metrics
	 *
	 * @param operation - The name of the operation to end timing for (optional)
	 */
	endOperation(operation?: string): void {
		if (!this.enabled || this.stack.length === 0) return;

		// If no operation is specified, use the most recent one from the stack
		const currentOp = operation || this.stack.pop();
		if (!currentOp || this.startTimes[currentOp] === undefined) return;

		const endTime = performance.now();
		const startTime = this.startTimes[currentOp];
		const elapsedTime = endTime - startTime;

		// Initialize metric if it doesn't exist
		if (!this.metrics[currentOp]) {
			this.metrics[currentOp] = {
				count: 0,
				totalTime: 0,
				max: 0,
				min: Number.MAX_VALUE,
			};
		}

		// Update metrics
		const metric = this.metrics[currentOp];
		metric.count++;
		metric.totalTime += elapsedTime;
		metric.max = Math.max(metric.max, elapsedTime);
		metric.min = Math.min(metric.min, elapsedTime);

		// Clear the start time
		delete this.startTimes[currentOp];

		// If operation was specified and it's not the top of the stack,
		// remove it from anywhere in the stack
		if (operation && this.stack[this.stack.length - 1] !== operation) {
			const index = this.stack.lastIndexOf(operation);
			if (index !== -1) {
				this.stack.splice(index, 1);
			}
		}
	}

	/**
	 * Returns all collected metrics
	 */
	getMetrics(): Record<string, OperationMetric> {
		const result: Record<string, OperationMetric> = {};
		let total = 0;

		// First pass: Calculate total time and add average
		for (const [operation, metric] of Object.entries(this.metrics)) {
			total += metric.totalTime;
			result[operation] = {
				...metric,
				averageTime: metric.count > 0 ? metric.totalTime / metric.count : 0,
				percentage: 0, // Will be calculated in the next pass
			};
		}

		// Second pass: Add percentages
		for (const [operation, metric] of Object.entries(result)) {
			result[operation].percentage = total > 0 ? (metric.totalTime / total) * 100 : 0;
		}

		// Return metrics sorted by total time (descending)
		return Object.fromEntries(Object.entries(result).sort((a, b) => b[1].totalTime - a[1].totalTime));
	}

	/**
	 * Resets all collected metrics
	 */
	reset(): void {
		this.metrics = {};
		this.startTimes = {};
		this.stack = [];
	}

	/**
	 * Enables or disables metrics collection
	 */
	setEnabled(enabled: boolean): void {
		this.enabled = enabled;
		if (enabled) {
			this.reset(); // Start fresh when enabling
		}
	}

	/**
	 * Checks if metrics collection is enabled
	 */
	isEnabled(): boolean {
		return this.enabled;
	}
}

// Create a singleton instance for global use
export const performanceMetrics = new PerformanceMetrics();
