import {MAX_INLINE_DEPTH} from '../types/constants';

/**
 * FormattingContext keeps track of the state of formatting while parsing.
 * It helps maintain a stack of active formatting markers to properly handle
 * nested formatting and prevent conflicting formats.
 */
export class FormattingContext {
	// Use a Map for O(1) lookups instead of array scans
	private readonly activeFormattingTypes = new Map<string, boolean>();

	// Keep the stack for proper tracking of nesting order
	private readonly formattingStack: Array<[string, boolean]> = [];

	// Cache the underscore presence for quick checks
	private hasUnderscores = false;

	// Keep current nesting depth as a direct counter
	private currentDepth = 0;

	/**
	 * Determines if a new formatting delimiter can be entered
	 *
	 * @param delimiter - The formatting delimiter character (e.g., '*', '_')
	 * @param isDouble - Whether this is a double delimiter (e.g., '**', '__')
	 * @returns Whether the formatting can be entered
	 */
	canEnterFormatting(delimiter: string, isDouble: boolean): boolean {
		// Quick check for active formatting - O(1) lookup instead of O(n) scan
		const key = this.getFormattingKey(delimiter, isDouble);
		if (this.activeFormattingTypes.has(key)) return false;

		// Avoid regex operations for underscore checks by using cached flag
		if (delimiter === '_' && isDouble && this.hasUnderscores) return false;

		// Enforce maximum nesting depth
		return this.currentDepth < MAX_INLINE_DEPTH;
	}

	/**
	 * Checks if a specific formatting type is already active
	 *
	 * @param delimiter - The formatting delimiter character
	 * @param isDouble - Whether this is a double delimiter
	 * @returns Whether this formatting type is active
	 */
	isFormattingActive(delimiter: string, isDouble: boolean): boolean {
		// O(1) lookup instead of O(n) array scan
		return this.activeFormattingTypes.has(this.getFormattingKey(delimiter, isDouble));
	}

	/**
	 * Adds a formatting marker to the stack
	 *
	 * @param delimiter - The formatting delimiter character
	 * @param isDouble - Whether this is a double delimiter
	 */
	pushFormatting(delimiter: string, isDouble: boolean): void {
		this.formattingStack.push([delimiter, isDouble]);
		this.activeFormattingTypes.set(this.getFormattingKey(delimiter, isDouble), true);
		this.currentDepth++;
	}

	/**
	 * Removes the last formatting marker when closing a format section
	 *
	 * @returns The removed formatting marker or undefined if stack was empty
	 */
	popFormatting(): [string, boolean] | undefined {
		const removed = this.formattingStack.pop();
		if (removed) {
			this.activeFormattingTypes.delete(this.getFormattingKey(removed[0], removed[1]));
			this.currentDepth--;
		}
		return removed;
	}

	/**
	 * Sets the current text content for context-aware decisions
	 *
	 * @param text - The current text being processed
	 */
	setCurrentText(text: string): void {
		// Only check for underscores and cache the result, avoiding regex when possible
		this.hasUnderscores = text.includes('_');
	}

	/**
	 * Creates a unique key for a formatting type
	 *
	 * @param delimiter - The formatting delimiter character
	 * @param isDouble - Whether this is a double delimiter
	 * @returns A unique string key
	 */
	private getFormattingKey(delimiter: string, isDouble: boolean): string {
		return `${delimiter}${isDouble ? '2' : '1'}`;
	}
}
