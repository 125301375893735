import {
	type AlertNode,
	type BlockquoteNode,
	type FormattingNode,
	type HeadingNode,
	type LinkNode,
	type ListNode,
	type Node,
	NodeType,
	type SequenceNode,
	type SubtextNode,
	type TableCellNode,
	type TableNode,
	type TableRowNode,
	type TextNode,
} from '../types';
import {performanceMetrics} from './performance-metrics';

/**
 * Utility functions for working with the AST
 */

// Cache NodeType values for faster comparisons
const NT_TEXT = NodeType.Text;
const NT_STRONG = NodeType.Strong;
const NT_EMPHASIS = NodeType.Emphasis;
const NT_UNDERLINE = NodeType.Underline;
const NT_STRIKETHROUGH = NodeType.Strikethrough;
const NT_SPOILER = NodeType.Spoiler;
const NT_SEQUENCE = NodeType.Sequence;
const NT_HEADING = NodeType.Heading;
const NT_SUBTEXT = NodeType.Subtext;
const NT_BLOCKQUOTE = NodeType.Blockquote;
const NT_LIST = NodeType.List;
const NT_LINK = NodeType.Link;
const NT_TABLE = NodeType.Table;
const NT_TABLE_ROW = NodeType.TableRow;
const NT_TABLE_CELL = NodeType.TableCell;
const NT_ALERT = NodeType.Alert;

// Formatter node types lookup for faster type checking (much faster than multiple comparisons)
const FORMATTING_NODE_TYPES = new Set([
	NT_STRONG,
	NT_EMPHASIS,
	NT_UNDERLINE,
	NT_STRIKETHROUGH,
	NT_SPOILER,
	NT_SEQUENCE,
]);

/**
 * Flattens and optimizes an AST by merging text nodes and handling special cases
 * This is a performance-critical function that has been highly optimized
 *
 * @param nodes - Array of nodes to flatten
 */
export function flattenAST(nodes: Array<Node>): void {
	const metric = 'ASTUtils.flattenAST';
	performanceMetrics.startOperation(metric);

	// Quick return for empty or single-node arrays
	const nodeCount = nodes.length;
	if (nodeCount <= 1) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// Process each node first (depth-first)
	for (let i = 0; i < nodeCount; i++) {
		flattenNode(nodes[i]);
	}

	// Then process the array itself
	flattenChildren(nodes, false);

	performanceMetrics.endOperation(metric);
}

/**
 * Flattens a single node by processing its children recursively
 * Optimized for performance with minimal function calls
 *
 * @param node - Node to flatten
 */
export function flattenNode(node: Node): void {
	const metric = 'ASTUtils.flattenNode';
	performanceMetrics.startOperation(metric);

	const nodeType = node.type;

	// Fast path for leaf nodes (like text nodes)
	if (nodeType === NT_TEXT) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// Fast path for formatting nodes (most common case)
	if (FORMATTING_NODE_TYPES.has(nodeType)) {
		const formattingNode = node as FormattingNode;
		const children = formattingNode.children;
		const childCount = children.length;

		// Skip processing if there are no children
		if (childCount === 0) {
			performanceMetrics.endOperation(metric);
			return;
		}

		// Process each child
		for (let i = 0; i < childCount; i++) {
			flattenNode(children[i]);
		}

		flattenChildren(children, false);
		performanceMetrics.endOperation(metric);
		return;
	}

	// Handle other node types with a switch
	switch (nodeType) {
		case NT_HEADING:
		case NT_SUBTEXT: {
			const typedNode = node as HeadingNode | SubtextNode;
			const children = typedNode.children;
			const childCount = children.length;

			for (let i = 0; i < childCount; i++) {
				flattenNode(children[i]);
			}

			flattenChildren(children, false);
			break;
		}

		case NT_BLOCKQUOTE: {
			const blockquoteNode = node as BlockquoteNode;
			const children = blockquoteNode.children;
			const childCount = children.length;

			for (let i = 0; i < childCount; i++) {
				flattenNode(children[i]);
			}

			// Always merge text nodes inside blockquotes
			flattenChildren(children, true);
			break;
		}

		case NT_LIST: {
			const listNode = node as ListNode;
			const items = listNode.items;
			const itemCount = items.length;

			for (let i = 0; i < itemCount; i++) {
				const item = items[i];
				const itemChildren = item.children;
				const itemChildCount = itemChildren.length;

				for (let j = 0; j < itemChildCount; j++) {
					flattenNode(itemChildren[j]);
				}

				flattenChildren(itemChildren, false);
			}
			break;
		}

		case NT_LINK: {
			const linkNode = node as LinkNode;
			const text = linkNode.text;
			if (text) {
				flattenNode(text);
				if (text.type === NT_SEQUENCE) {
					const sequenceNode = text as SequenceNode;
					const seqChildren = sequenceNode.children;
					const seqChildCount = seqChildren.length;

					for (let i = 0; i < seqChildCount; i++) {
						flattenNode(seqChildren[i]);
					}

					flattenChildren(seqChildren, false);
				}
			}
			break;
		}

		case NT_TABLE: {
			const tableNode = node as TableNode;
			flattenTableRow(tableNode.header);

			const rows = tableNode.rows;
			const rowCount = rows.length;
			for (let i = 0; i < rowCount; i++) {
				flattenTableRow(rows[i]);
			}
			break;
		}

		case NT_TABLE_ROW:
			flattenTableRow(node as TableRowNode);
			break;

		case NT_TABLE_CELL: {
			const cellNode = node as TableCellNode;
			const cellChildren = cellNode.children;
			const cellChildCount = cellChildren.length;

			for (let i = 0; i < cellChildCount; i++) {
				flattenNode(cellChildren[i]);
			}

			flattenChildren(cellChildren, false);
			break;
		}

		case NT_ALERT: {
			const alertNode = node as AlertNode;
			const alertChildren = alertNode.children;
			const alertChildCount = alertChildren.length;

			for (let i = 0; i < alertChildCount; i++) {
				flattenNode(alertChildren[i]);
			}

			flattenChildren(alertChildren, false);
			break;
		}
	}

	performanceMetrics.endOperation(metric);
}

/**
 * Helper function to flatten table rows - optimized for performance
 *
 * @param row - Table row to flatten
 */
export function flattenTableRow(row: TableRowNode): void {
	const metric = 'ASTUtils.flattenTableRow';
	performanceMetrics.startOperation(metric);

	const cells = row.cells;
	const cellCount = cells.length;

	for (let i = 0; i < cellCount; i++) {
		const cell = cells[i];
		const cellChildren = cell.children;
		const childCount = cellChildren.length;

		// Skip if no children to process
		if (childCount === 0) continue;

		for (let j = 0; j < childCount; j++) {
			flattenNode(cellChildren[j]);
		}

		flattenChildren(cellChildren, false);
	}

	performanceMetrics.endOperation(metric);
}

/**
 * Flattens an array of nodes, merging adjacent text nodes
 * Optimized for fewer allocations and iteration passes
 *
 * @param nodes - Array of nodes to flatten
 * @param insideBlockquote - Whether these nodes are inside a blockquote
 */
export function flattenChildren(nodes: Array<Node>, insideBlockquote = false): void {
	const metric = 'ASTUtils.flattenChildren';
	performanceMetrics.startOperation(metric);

	const nodeCount = nodes.length;
	if (nodeCount <= 1) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// First pass: flatten formatting nodes to avoid multiple passes
	flattenFormattingNodes(nodes);

	// Second pass: combine adjacent text nodes efficiently
	combineAdjacentTextNodes(nodes, insideBlockquote);

	// Final pass: special handling for alerts
	removeEmptyTextNodesBetweenAlerts(nodes);

	performanceMetrics.endOperation(metric);
}

/**
 * Flattens formatting nodes with the same type as their children
 * Optimized version using direct array access and fewer iterations
 *
 * @param nodes - Array of nodes to process
 */
export function flattenFormattingNodes(nodes: Array<Node>): void {
	const metric = 'ASTUtils.flattenFormattingNodes';
	performanceMetrics.startOperation(metric);

	// Skip processing if there are few nodes
	if (nodes.length <= 1) {
		performanceMetrics.endOperation(metric);
		return;
	}

	let i = 0;
	while (i < nodes.length) {
		const node = nodes[i];
		// Use Set lookup for faster type checking
		if (FORMATTING_NODE_TYPES.has(node.type)) {
			const formattingNode = node as FormattingNode;
			flattenSameType(formattingNode.children, node.type);
		}
		i++;
	}

	performanceMetrics.endOperation(metric);
}

/**
 * Checks if a node is a formatting node using fast Set lookup
 *
 * @param node - Node to check
 * @returns Whether the node is a formatting node
 */
export function isFormattingNode(node: Node): boolean {
	return FORMATTING_NODE_TYPES.has(node.type);
}

/**
 * Flattens nodes of the same type by pulling up their children
 * Optimized for fewer array modifications with pre-calculated indices
 *
 * @param children - Array of nodes to process
 * @param nodeType - Type of node to flatten
 */
export function flattenSameType(children: Array<Node>, nodeType: NodeType): void {
	const metric = 'ASTUtils.flattenSameType';
	performanceMetrics.startOperation(metric);

	// Skip processing if there are few nodes
	if (children.length <= 1) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// Pre-scan to find nodes to flatten and calculate new array size
	let needsFlattening = false;
	for (let i = 0; i < children.length; i++) {
		if (children[i].type === nodeType) {
			needsFlattening = true;
			break;
		}
	}

	// Fast path for arrays without nodes to flatten
	if (!needsFlattening) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// Use a more efficient approach for batch processing
	let i = 0;
	const result: Array<Node> = [];

	while (i < children.length) {
		const child = children[i];
		if (child.type === nodeType && 'children' in child) {
			// Spread the child's children into the result
			const innerNodes = (child as FormattingNode).children;
			for (let j = 0; j < innerNodes.length; j++) {
				result.push(innerNodes[j]);
			}
		} else {
			result.push(child);
		}
		i++;
	}

	// Now replace the original array contents (faster than splice)
	children.length = 0;
	for (let i = 0; i < result.length; i++) {
		children.push(result[i]);
	}

	performanceMetrics.endOperation(metric);
}

/**
 * Combines adjacent text nodes into a single text node
 * Highly optimized version that avoids unnecessary string operations
 *
 * @param nodes - Array of nodes to process
 * @param insideBlockquote - Whether these nodes are inside a blockquote
 */
export function combineAdjacentTextNodes(nodes: Array<Node>, insideBlockquote = false): void {
	const metric = 'ASTUtils.combineAdjacentTextNodes';
	performanceMetrics.startOperation(metric);

	const nodeCount = nodes.length;
	if (nodeCount <= 1) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// Pre-scan to check if there are adjacent text nodes to combine
	let hasAdjacentTextNodes = false;
	let lastWasText = false;

	for (let i = 0; i < nodeCount; i++) {
		const isText = nodes[i].type === NT_TEXT;
		if (isText && lastWasText) {
			hasAdjacentTextNodes = true;
			break;
		}
		lastWasText = isText;
	}

	// Fast path - return if no adjacent text nodes
	if (!hasAdjacentTextNodes && !insideBlockquote) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// Regular processing for blockquotes or when we need to combine text nodes
	const result: Array<Node> = [];
	let currentText = '';
	let nonTextNodeSeen = false;

	// Special case for blockquotes to consolidate all text nodes
	if (insideBlockquote) {
		for (let i = 0; i < nodeCount; i++) {
			const node = nodes[i];
			const isTextNode = node.type === NT_TEXT;

			if (isTextNode) {
				// If we've seen a non-text node and now have text again, start fresh
				if (nonTextNodeSeen) {
					if (currentText) {
						result.push({type: NT_TEXT, content: currentText});
						currentText = '';
					}
					nonTextNodeSeen = false;
				}
				currentText += (node as TextNode).content;
			} else {
				if (currentText) {
					result.push({type: NT_TEXT, content: currentText});
					currentText = '';
				}
				result.push(node);
				nonTextNodeSeen = true;
			}
		}

		if (currentText) {
			result.push({type: NT_TEXT, content: currentText});
		}
	} else {
		// For non-blockquote content, optimize for special cases
		let currentTextNode: TextNode | null = null;

		for (let i = 0; i < nodeCount; i++) {
			const node = nodes[i];
			if (node.type === NT_TEXT) {
				const textNode = node as TextNode;
				const content = textNode.content;

				// Micro-optimization: check for special cases only when needed
				// Check for # or -# only if the content starts with these characters
				let isMalformedContent = false;
				if (content && (content[0] === '#' || (content[0] === '-' && content.length > 1 && content[1] === '#'))) {
					const trimmed = content.trim();
					isMalformedContent = trimmed.startsWith('#') || trimmed.startsWith('-#');
				}

				if (isMalformedContent) {
					// Special content that should be kept as separate nodes
					if (currentTextNode) {
						result.push(currentTextNode);
						currentTextNode = null;
					}
					result.push({type: NT_TEXT, content});
				} else if (currentTextNode) {
					// Fast check for double newlines - most common stopping point
					const hasDoubleNewline = content.includes('\n\n');

					if (hasDoubleNewline) {
						result.push(currentTextNode);
						result.push({type: NT_TEXT, content});
						currentTextNode = null;
					} else {
						// Just append for regular text
						currentTextNode.content += content;
					}
				} else {
					currentTextNode = {type: NT_TEXT, content};
				}
			} else {
				if (currentTextNode) {
					result.push(currentTextNode);
					currentTextNode = null;
				}
				result.push(node);
			}
		}

		if (currentTextNode) {
			result.push(currentTextNode);
		}
	}

	// Replace the array in-place (faster than splice operations)
	nodes.length = 0;
	for (let i = 0; i < result.length; i++) {
		nodes.push(result[i]);
	}

	performanceMetrics.endOperation(metric);
}

/**
 * Removes empty text nodes between alert nodes
 * Optimized version with pre-scan to avoid unnecessary processing
 *
 * @param nodes - Array of nodes to process
 */
export function removeEmptyTextNodesBetweenAlerts(nodes: Array<Node>): void {
	const metric = 'ASTUtils.removeEmptyTextNodesBetweenAlerts';
	performanceMetrics.startOperation(metric);

	const nodeCount = nodes.length;
	if (nodeCount < 3) {
		// Need at least 3 nodes for an alert-text-alert pattern
		performanceMetrics.endOperation(metric);
		return;
	}

	// Quick pre-scan to check if we have alerts and text nodes
	let hasAlert = false;
	let hasTextNode = false;

	for (let i = 0; i < nodeCount; i++) {
		const type = nodes[i].type;
		hasAlert ||= type === NT_ALERT;
		hasTextNode ||= type === NT_TEXT;

		if (hasAlert && hasTextNode) break;
	}

	// Fast path - if we don't have both alerts and text nodes, nothing to remove
	if (!hasAlert || !hasTextNode) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// Optimized filtering
	let emptyTextBetweenAlerts = false;
	for (let i = 1; i < nodeCount - 1; i++) {
		const current = nodes[i];
		if (
			current.type === NT_TEXT &&
			nodes[i - 1].type === NT_ALERT &&
			nodes[i + 1].type === NT_ALERT &&
			(current as TextNode).content.trim() === ''
		) {
			emptyTextBetweenAlerts = true;
			break;
		}
	}

	// Fast path - nothing to remove
	if (!emptyTextBetweenAlerts) {
		performanceMetrics.endOperation(metric);
		return;
	}

	// Perform actual filtering - only allocate a new array when needed
	const result: Array<Node> = [];

	for (let i = 0; i < nodeCount; i++) {
		const current = nodes[i];

		// Skip empty text nodes between alerts
		if (
			i > 0 &&
			i < nodeCount - 1 &&
			current.type === NT_TEXT &&
			(current as TextNode).content.trim() === '' &&
			nodes[i - 1].type === NT_ALERT &&
			nodes[i + 1].type === NT_ALERT
		) {
			continue;
		}

		result.push(current);
	}

	// Replace the original array contents
	nodes.length = 0;
	for (let i = 0; i < result.length; i++) {
		nodes.push(result[i]);
	}

	performanceMetrics.endOperation(metric);
}

/**
 * Merges adjacent text nodes - optimized version with pre-scanning
 *
 * @param nodes - Array of nodes to process
 * @returns Array with merged text nodes
 */
export function mergeTextNodes(nodes: Array<Node>): Array<Node> {
	const metric = 'ASTUtils.mergeTextNodes';
	performanceMetrics.startOperation(metric);

	const nodeCount = nodes.length;
	if (nodeCount <= 1) {
		performanceMetrics.endOperation(metric);
		return nodes;
	}

	// Pre-scan to check if merging is needed
	let hasConsecutiveTextNodes = false;
	let prevWasText = false;

	for (let i = 0; i < nodeCount; i++) {
		const isText = nodes[i].type === NT_TEXT;
		if (isText && prevWasText) {
			hasConsecutiveTextNodes = true;
			break;
		}
		prevWasText = isText;
	}

	// Fast path - nothing to merge
	if (!hasConsecutiveTextNodes) {
		performanceMetrics.endOperation(metric);
		return nodes;
	}

	// Only allocate a new array if we need to merge
	const mergedNodes: Array<Node> = [];
	let currentText = '';

	for (let i = 0; i < nodeCount; i++) {
		const node = nodes[i];
		if (node.type === NT_TEXT) {
			currentText += (node as TextNode).content;
		} else {
			if (currentText) {
				mergedNodes.push({type: NT_TEXT, content: currentText});
				currentText = '';
			}
			mergedNodes.push(node);
		}
	}

	if (currentText) {
		mergedNodes.push({type: NT_TEXT, content: currentText});
	}

	performanceMetrics.endOperation(metric);
	return mergedNodes;
}

/**
 * Adds a text node to an array of nodes if the text is not empty
 * Simple helper function with slight optimization
 *
 * @param nodes - Array of nodes to add to
 * @param text - Text content to add
 */
export function addTextNode(nodes: Array<Node>, text: string): void {
	if (text && text.length > 0) {
		nodes.push({type: NT_TEXT, content: text});
	}
}
