import React from 'react';
import markupStyles from '~/styles/Markup.module.css';
import {Parser} from './parser';
import {
	MarkdownContext,
	type MarkdownParseOptions,
	getParserFlagsForContext,
	render,
	wrapRenderedContent,
} from './renderers';

/**
 * Error boundary for safely rendering markdown content
 */
class MarkdownErrorBoundary extends React.Component<
	{children: React.ReactNode},
	{hasError: boolean; error: Error | null}
> {
	constructor(props: {children: React.ReactNode}) {
		super(props);
		this.state = {hasError: false, error: null};
	}

	static getDerivedStateFromError(error: Error) {
		return {hasError: true, error};
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		console.error('Error rendering markdown:', error, info);
	}

	render() {
		if (this.state.hasError) {
			return <span className={markupStyles.error}>Error rendering content</span>;
		}

		return this.props.children;
	}
}

/**
 * Main function for parsing markdown content
 *
 * @param content The markdown content to parse
 * @param options Parsing options
 * @returns Rendered React components
 */
export function parseMarkdown(
	content: string,
	options: MarkdownParseOptions = {context: MarkdownContext.STANDARD_WITHOUT_JUMBO},
): React.ReactNode {
	try {
		// Get appropriate parser flags for the context
		const flags = getParserFlagsForContext(options.context);

		// Parse the content
		const parser = new Parser(content, flags);
		const {nodes} = parser.parse();

		// Render the nodes
		const renderedContent = render(nodes, options);

		// Wrap the content based on context
		return wrapRenderedContent(renderedContent, options.context);
	} catch (error) {
		console.error(`Error parsing markdown (${options.context}):`, error);
		return <span>{content}</span>;
	}
}

/**
 * Safely renders markdown content with error boundary
 */
export function SafeMarkdown({
	content,
	options = {context: MarkdownContext.STANDARD_WITHOUT_JUMBO},
}: {
	content: string;
	options?: MarkdownParseOptions;
}): React.ReactElement {
	return <MarkdownErrorBoundary>{parseMarkdown(content, options)}</MarkdownErrorBoundary>;
}
