import {ArrowsDownUp, Clipboard, SortAscending, SortDescending} from '@phosphor-icons/react';
import clsx from 'clsx';
import highlight from 'highlight.js';
import React, {useState} from 'react';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import * as Modal from '~/components/modals/Modal';
import type {Node} from '~/lib/markdown/parser';
import type {OperationMetric} from '~/lib/markdown/parser/utils/performance-metrics';
import markupStyles from '~/styles/Markup.module.css';

const debugModalStyles = {
	container: 'flex flex-col h-full overflow-hidden',
	scrollContainer: 'flex-1 overflow-auto',
	codeWrapper: 'relative h-full w-full',
	pre: 'h-full w-full m-0',
	codeContainer: 'relative h-full w-full',
	copyButton: 'absolute top-2 right-2 z-10',
	tabContainer: 'flex border-b border-gray-700',
	tab: 'px-4 py-2 cursor-pointer',
	activeTab: 'font-bold border-b-2 border-brand-primary',
	metricsTable: 'w-full border-collapse',
	metricsHeaderRow: 'bg-gray-800 sticky top-0',
	metricsHeaderCell: 'text-left p-2 border-b border-gray-700 font-bold',
	metricsHeaderCellSortable: 'text-left p-2 border-b border-gray-700 font-bold cursor-pointer hover:bg-gray-700',
	metricsCell: 'p-2 border-b border-gray-700',
	metricsHighlight: 'font-bold text-brand-primary',
	sortIcon: 'ml-1 flex-shrink-0 min-w-4',
	sortIconContainer: 'inline-flex items-center',
	chartContainer: 'mt-4 border border-gray-700 p-4 rounded-md',
	chartTitle: 'text-lg font-bold mb-2',
	chartBar: 'relative h-6 my-1 bg-brand-primary rounded-sm',
	chartLabel: 'absolute left-2 top-0 h-full flex items-center text-white text-sm',
	chartValue: 'absolute right-2 top-0 h-full flex items-center text-white text-sm',
	summaryContainer: 'mt-4 border border-gray-700 p-4 rounded-md mb-4',
	summaryTitle: 'text-lg font-bold mb-2',
	summaryItem: 'flex justify-between py-1',
	summaryLabel: 'font-medium',
	summaryValue: 'font-mono',
	controlsContainer: 'flex justify-between items-center mb-4',
	sortContainer: 'flex items-center space-x-2',
	sortButton: 'px-3 py-1 rounded-md bg-gray-700 hover:bg-gray-600 flex items-center',
	sortButtonActive: 'px-3 py-1 rounded-md bg-gray-600 hover:bg-gray-500 flex items-center border border-brand-primary',
	sortLabel: 'mr-1',
} as const;

type SortField = 'operation' | 'count' | 'totalTime' | 'averageTime' | 'percentage' | 'max' | 'min';
type SortDirection = 'asc' | 'desc';

/**
 * A simplified modal for displaying AST data with performance metrics
 */
export const PerformanceDebugModal = ({
	title,
	astData,
	performanceMetrics,
}: {
	title: string;
	astData: Array<Node>;
	performanceMetrics: Record<string, OperationMetric>;
}) => {
	const [activeTab, setActiveTab] = useState<'data' | 'metrics'>(performanceMetrics ? 'metrics' : 'data');
	const [sortField, setSortField] = useState<SortField>('totalTime');
	const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

	// Toggle sort direction when clicking the same field
	const handleSort = (field: SortField) => {
		if (field === sortField) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortField(field);
			setSortDirection('desc');
		}
	};

	// Format the AST data for JSON display
	const jsonData = React.useMemo(() => {
		// Create a deep copy to avoid modifying the original
		const displayData = JSON.parse(JSON.stringify(astData));

		// Remove performance metrics to make the AST data easier to read
		if (Array.isArray(displayData) && 'performanceMetrics' in displayData) {
			displayData.performanceMetrics = undefined;
		}

		return displayData;
	}, [astData]);

	// Format metrics for display and sort them
	const formattedMetrics = React.useMemo(() => {
		if (!performanceMetrics) return [];

		// Convert to array for sorting
		const metrics = Object.entries(performanceMetrics).map(([key, value]) => ({
			operation: key,
			count: value.count,
			totalTime: Number(value.totalTime.toFixed(2)),
			averageTime: Number(value.averageTime.toFixed(2)),
			percentage: Number(value.percentage.toFixed(2)),
			max: Number(value.max.toFixed(2)),
			min: Number(value.min.toFixed(2)),
		}));

		// Sort metrics
		return metrics.sort((a, b) => {
			if (sortField === 'operation') {
				return sortDirection === 'asc'
					? a.operation.localeCompare(b.operation)
					: b.operation.localeCompare(a.operation);
			}
			return sortDirection === 'asc' ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
		});
	}, [performanceMetrics, sortField, sortDirection]);

	// Calculate total execution time for summary
	const totalTime = React.useMemo(() => {
		if (!formattedMetrics.length) return 0;
		return formattedMetrics.reduce((sum, metric) => sum + metric.totalTime, 0);
	}, [formattedMetrics]);

	// Highlight the JSON data
	const highlighted = React.useMemo(
		() =>
			highlight.highlight(JSON.stringify(jsonData, null, 2), {
				language: 'json',
				ignoreIllegals: true,
			}),
		[jsonData],
	);

	const codeContent = (
		<code
			className={clsx(markupStyles.hljs, 'json', 'block w-full')}
			// biome-ignore lint/security/noDangerouslySetInnerHtml: highlight.js output
			dangerouslySetInnerHTML={{__html: highlighted.value}}
		/>
	);

	// Render the sort icon for the header cell
	const renderSortIcon = (field: SortField) => {
		if (field !== sortField) {
			return <ArrowsDownUp className={debugModalStyles.sortIcon} size={16} />;
		}

		return sortDirection === 'asc' ? (
			<SortAscending className={debugModalStyles.sortIcon} size={16} />
		) : (
			<SortDescending className={debugModalStyles.sortIcon} size={16} />
		);
	};

	// Render a sortable header cell
	const SortableHeaderCell = ({field, label}: {field: SortField; label: string}) => (
		<th
			className={debugModalStyles.metricsHeaderCellSortable}
			onClick={() => handleSort(field)}
			onKeyDown={(e) => {
				if (e.key === 'Enter') handleSort(field);
			}}
			role="columnheader"
		>
			<div className="flex items-center justify-between">
				<span>{label}</span>
				<span className={debugModalStyles.sortIconContainer}>{renderSortIcon(field)}</span>
			</div>
		</th>
	);

	// Summary section with key metrics
	const MetricsSummary = () => {
		if (!formattedMetrics.length) return null;

		// Find the operation with the highest average time
		const slowestAverage = [...formattedMetrics].sort((a, b) => b.averageTime - a.averageTime)[0];

		// Find the most frequently called operation
		const mostCalled = [...formattedMetrics].sort((a, b) => b.count - a.count)[0];

		// Find the operation with highest percentage
		const topOperation = formattedMetrics[0]; // Already sorted by the selected field

		return (
			<div className={debugModalStyles.summaryContainer}>
				<h3 className={debugModalStyles.summaryTitle}>Metrics Summary</h3>
				<div className={debugModalStyles.summaryItem}>
					<span className={debugModalStyles.summaryLabel}>Total Parsing Time:</span>
					<span className={debugModalStyles.summaryValue}>{totalTime.toFixed(2)} ms</span>
				</div>
				<div className={debugModalStyles.summaryItem}>
					<span className={debugModalStyles.summaryLabel}>Operations Tracked:</span>
					<span className={debugModalStyles.summaryValue}>{formattedMetrics.length}</span>
				</div>
				<div className={debugModalStyles.summaryItem}>
					<span className={debugModalStyles.summaryLabel}>Slowest Operation:</span>
					<span className={debugModalStyles.summaryValue}>
						{topOperation?.operation} ({topOperation?.totalTime.toFixed(2)} ms)
					</span>
				</div>
				<div className={debugModalStyles.summaryItem}>
					<span className={debugModalStyles.summaryLabel}>Highest Average Time:</span>
					<span className={debugModalStyles.summaryValue}>
						{slowestAverage?.operation} ({slowestAverage?.averageTime.toFixed(2)} ms)
					</span>
				</div>
				<div className={debugModalStyles.summaryItem}>
					<span className={debugModalStyles.summaryLabel}>Most Called Operation:</span>
					<span className={debugModalStyles.summaryValue}>
						{mostCalled?.operation} ({mostCalled?.count} times)
					</span>
				</div>
			</div>
		);
	};

	// Display metrics content with basic table
	const metricsContent = (
		<div className="p-4">
			<div className={debugModalStyles.controlsContainer}>
				<div className={debugModalStyles.sortContainer}>
					<span>Sort by:</span>
					<button
						type="button"
						className={sortField === 'totalTime' ? debugModalStyles.sortButtonActive : debugModalStyles.sortButton}
						onClick={() => handleSort('totalTime')}
					>
						<span className={debugModalStyles.sortLabel}>Total Time</span>
						{sortField === 'totalTime' &&
							(sortDirection === 'asc' ? <SortAscending size={16} /> : <SortDescending size={16} />)}
					</button>
					<button
						type="button"
						className={sortField === 'percentage' ? debugModalStyles.sortButtonActive : debugModalStyles.sortButton}
						onClick={() => handleSort('percentage')}
					>
						<span className={debugModalStyles.sortLabel}>Percentage</span>
						{sortField === 'percentage' &&
							(sortDirection === 'asc' ? <SortAscending size={16} /> : <SortDescending size={16} />)}
					</button>
					<button
						type="button"
						className={sortField === 'count' ? debugModalStyles.sortButtonActive : debugModalStyles.sortButton}
						onClick={() => handleSort('count')}
					>
						<span className={debugModalStyles.sortLabel}>Count</span>
						{sortField === 'count' &&
							(sortDirection === 'asc' ? <SortAscending size={16} /> : <SortDescending size={16} />)}
					</button>
				</div>
			</div>

			{formattedMetrics.length > 0 ? (
				<>
					<MetricsSummary />

					<div className="overflow-x-auto">
						<table className={debugModalStyles.metricsTable}>
							<thead className={debugModalStyles.metricsHeaderRow}>
								<tr>
									<SortableHeaderCell field="operation" label="Operation" />
									<SortableHeaderCell field="count" label="Count" />
									<SortableHeaderCell field="totalTime" label="Total Time (ms)" />
									<SortableHeaderCell field="averageTime" label="Avg Time (ms)" />
									<SortableHeaderCell field="max" label="Max (ms)" />
									<SortableHeaderCell field="min" label="Min (ms)" />
									<SortableHeaderCell field="percentage" label="% of Total" />
								</tr>
							</thead>
							<tbody>
								{formattedMetrics.map((metric) => (
									<tr key={metric.operation}>
										<td className={debugModalStyles.metricsCell}>{metric.operation}</td>
										<td className={debugModalStyles.metricsCell}>{metric.count}</td>
										<td
											className={clsx(
												debugModalStyles.metricsCell,
												metric.percentage > 10 && debugModalStyles.metricsHighlight,
											)}
										>
											{metric.totalTime.toFixed(2)}
										</td>
										<td
											className={clsx(
												debugModalStyles.metricsCell,
												metric.averageTime > 1 && debugModalStyles.metricsHighlight,
											)}
										>
											{metric.averageTime.toFixed(2)}
										</td>
										<td className={debugModalStyles.metricsCell}>{metric.max.toFixed(2)}</td>
										<td className={debugModalStyles.metricsCell}>{metric.min.toFixed(2)}</td>
										<td
											className={clsx(
												debugModalStyles.metricsCell,
												metric.percentage > 10 && debugModalStyles.metricsHighlight,
											)}
										>
											{metric.percentage.toFixed(2)}%
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</>
			) : (
				<div className="p-4 text-center">No performance metrics available. Try enabling metrics collection.</div>
			)}
		</div>
	);

	return (
		<Modal.Root label={title} size="xlarge">
			<Modal.Header title={title} />

			<Modal.Content className="min-h-0 flex-1 p-0">
				<div className={debugModalStyles.container}>
					{performanceMetrics && (
						<div className={debugModalStyles.tabContainer}>
							<div
								className={clsx(debugModalStyles.tab, activeTab === 'metrics' && debugModalStyles.activeTab)}
								onClick={() => setActiveTab('metrics')}
								onKeyDown={(e) => e.key === 'Enter' && setActiveTab('metrics')}
								role="tab"
								tabIndex={0}
								aria-selected={activeTab === 'metrics'}
							>
								Performance Metrics
							</div>
							<div
								className={clsx(debugModalStyles.tab, activeTab === 'data' && debugModalStyles.activeTab)}
								onClick={() => setActiveTab('data')}
								onKeyDown={(e) => e.key === 'Enter' && setActiveTab('data')}
								role="tab"
								tabIndex={0}
								aria-selected={activeTab === 'data'}
							>
								AST Data
							</div>
						</div>
					)}

					<div className={debugModalStyles.scrollContainer}>
						{activeTab === 'data' ? (
							<div className={debugModalStyles.codeWrapper}>
								<div className={clsx(markupStyles.markup, 'h-full')}>
									<pre className={debugModalStyles.pre} style={{maxWidth: '100%'}}>
										<div className={debugModalStyles.codeContainer}>
											<div className={debugModalStyles.copyButton}>
												<button
													type="button"
													className="rounded-md bg-brand-primary p-2"
													onClick={() => TextCopyActionCreators.copy(JSON.stringify(jsonData, null, 2))}
												>
													<Clipboard className="h-4 w-4" />
												</button>
											</div>
											{codeContent}
										</div>
									</pre>
								</div>
							</div>
						) : (
							metricsContent
						)}
					</div>
				</div>
			</Modal.Content>
		</Modal.Root>
	);
};
