/**
 * Utility functions for string manipulation and checks
 */

// Cache common string prefixes
const HTTP_PREFIX = 'http://';
const HTTPS_PREFIX = 'https://';

/**
 * Checks if a character at the given position is an underscore inside a word
 * Used to prevent formatting _inside_words_like_this
 *
 * @param chars - Array of characters
 * @param pos - Position to check
 * @returns Whether this is a word-internal underscore
 */
export function isWordUnderscore(chars: Array<string>, pos: number): boolean {
	// Early return check
	if (chars[pos] !== '_') return false;

	// Bounds checking using logical AND for short-circuit evaluation
	const prevChar = (pos > 0 && chars[pos - 1]) || '\0';
	const nextChar = (pos + 1 < chars.length && chars[pos + 1]) || '\0';

	// Use cached function results to avoid redundant regex testing
	return isWordChar(prevChar) && isWordChar(nextChar);
}

// Lookup tables for common character checks (much faster than regex for single chars)
const WORD_CHARS = new Set('abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_');
const ESCAPABLE_CHARS = new Set('[]()\\*_~`@!#$%^&+={}|:;"\'<>,.?/');
const URL_TERMINATION_CHARS = new Set(' \t\n\r)]\'"');

// Cache function for single character tests
const isWordChar = (char: string): boolean => WORD_CHARS.has(char);
const isEscapableChar = (char: string): boolean => ESCAPABLE_CHARS.has(char);
const isUrlTermChar = (char: string): boolean => URL_TERMINATION_CHARS.has(char);

/**
 * Checks if a character is escapable in Markdown
 *
 * @param char - Character to check
 * @returns Whether the character is escapable
 */
export function isEscapableCharacter(char: string): boolean {
	// Use Set-based lookup for single characters (faster than regex)
	return char.length === 1 && isEscapableChar(char);
}

/**
 * Checks if a character is a word character (letter, digit, or underscore)
 *
 * @param char - Character to check
 * @returns Whether the character is a word character
 */
export function isWordCharacter(char: string): boolean {
	// Use Set-based lookup for single characters (faster than regex)
	return char.length === 1 && isWordChar(char);
}

/**
 * Checks if a character would terminate a URL in plain text
 *
 * @param char - Character to check
 * @returns Whether the character would terminate a URL
 */
export function isUrlTerminationChar(char: string): boolean {
	// Use Set-based lookup for single characters (faster than regex)
	return char.length === 1 && isUrlTermChar(char);
}

/**
 * Checks if text starts with a URL
 * Only checks for http and https schemes
 *
 * @param text - Text to check
 * @returns Whether the text starts with a URL
 */
export function startsWithUrl(text: string): boolean {
	// Early reject for too short inputs
	if (text.length < 8) return false;

	// Use startsWith comparison with constants for better performance
	const isHttp = text.startsWith(HTTP_PREFIX);
	const isHttps = !isHttp && text.startsWith(HTTPS_PREFIX);

	if (isHttp || isHttps) {
		// Calculate offset based on prefix length
		const offset = isHttp ? 7 : 8;

		// Check for quotes only within relevant range
		// lastIndexOf is faster than regex for this simple check
		return text.lastIndexOf('"', offset) === -1 && text.lastIndexOf("'", offset) === -1;
	}
	return false;
}

/**
 * Checks if a sequence of characters matches a given marker
 *
 * @param chars - Array of characters to check
 * @param pos - Position to start checking from
 * @param marker - Marker to match
 * @returns Whether the characters match the marker
 */
export function matchMarker(chars: Array<string>, pos: number, marker: string): boolean {
	// Early bounds check for better performance
	const endPos = pos + marker.length;
	if (endPos > chars.length) return false;

	// For short markers (1-2 chars), direct comparison is faster
	if (marker.length === 1) {
		return chars[pos] === marker;
	}
	if (marker.length === 2) {
		return chars[pos] === marker[0] && chars[pos + 1] === marker[1];
	}

	// For longer markers, loop with early return
	for (let i = 0; i < marker.length; i++) {
		if (chars[pos + i] !== marker[i]) return false;
	}

	return true;
}
