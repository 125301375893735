import type React from 'react';
import markupStyles from '~/styles/Markup.module.css';
import {type Node, NodeType, Parser, ParserFlags} from '../parser';
import {shouldRenderJumboEmojis} from '../utils/jumbo-detector';

import {
	AlertRenderer,
	BlockquoteRenderer,
	HeadingRenderer,
	ListRenderer,
	SequenceRenderer,
	SubtextRenderer,
	TableRenderer,
} from './common/block-elements';
import {CodeBlockRenderer, InlineCodeRenderer} from './common/code-elements';
import {
	EmphasisRenderer,
	SpoilerRenderer,
	StrikethroughRenderer,
	StrongRenderer,
	TripleAsteriskRenderer,
	TripleUnderscoreRenderer,
	UnderlineRenderer,
} from './common/formatting-elements';
// Import all renderers
import {EmojiRenderer} from './emoji-renderer';
import {LinkRenderer} from './list-renderer';
import {MentionRenderer} from './mention-renderer';
import {TextRenderer} from './text-renderer';
import {TimestampRenderer} from './timestamp-renderer';

/**
 * Defines the context in which markdown content is being rendered
 */
export enum MarkdownContext {
	/** Standard message format */
	STANDARD_WITH_JUMBO = 0,

	/** Inline format, typically used for replies or compact views */
	RESTRICTED_INLINE_REPLY = 1,

	/** User biography format */
	RESTRICTED_USER_BIO = 2,

	/** Unfurled embed descriptions */
	RESTRICTED_EMBED_DESCRIPTION = 3,

	/** Any other context */
	STANDARD_WITHOUT_JUMBO = 4,
}

/**
 * Options for markdown parsing and rendering
 */
export interface MarkdownParseOptions {
	/** The context in which the markdown is being rendered */
	context: MarkdownContext;

	/** Whether to disable animated emoji in the output */
	disableAnimatedEmoji?: boolean;

	/** ID of the channel where this content appears */
	channelId?: string;

	/** ID of the message containing this content */
	messageId?: string;

	/** ID of the guild where this content appears */
	guildId?: string;
}

/**
 * Extended options used during the rendering phase
 */
export interface MarkdownRenderOptions extends MarkdownParseOptions {
	/** Whether emojis should be rendered in jumbo size */
	shouldJumboEmojis: boolean;
}

/**
 * Props passed to all renderers
 */
export interface RendererProps<T extends Node = Node> {
	/** The node to render */
	node: T;

	/** Unique key for React rendering */
	key: string;

	/** Function to render child nodes */
	renderChildren: (nodes: Array<Node>) => React.ReactNode;

	/** Rendering options and context */
	options: MarkdownRenderOptions;
}

/**
 * Default parser flags configuration - allows all markdown features
 */
const STANDARD_FLAGS =
	ParserFlags.ALLOW_SPOILERS |
	ParserFlags.ALLOW_HEADINGS |
	ParserFlags.ALLOW_LISTS |
	ParserFlags.ALLOW_CODE_BLOCKS |
	ParserFlags.ALLOW_MASKED_LINKS |
	ParserFlags.ALLOW_COMMAND_MENTIONS |
	ParserFlags.ALLOW_GUILD_NAVIGATIONS |
	ParserFlags.ALLOW_USER_MENTIONS |
	ParserFlags.ALLOW_ROLE_MENTIONS |
	ParserFlags.ALLOW_CHANNEL_MENTIONS |
	ParserFlags.ALLOW_EVERYONE_MENTIONS |
	ParserFlags.ALLOW_BLOCKQUOTES |
	ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES |
	ParserFlags.ALLOW_SUBTEXT |
	ParserFlags.ALLOW_TABLES |
	ParserFlags.ALLOW_ALERTS |
	ParserFlags.ALLOW_AUTOLINKS;

/**
 * Parser flags for inline content - disables block elements
 */
const RESTRICTED_INLINE_REPLY_FLAGS =
	STANDARD_FLAGS &
	~(
		ParserFlags.ALLOW_BLOCKQUOTES |
		ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES |
		ParserFlags.ALLOW_SUBTEXT |
		ParserFlags.ALLOW_TABLES |
		ParserFlags.ALLOW_ALERTS
	);

/**
 * Parser flags for user biography content - disables certain elements
 */
const RESTRICTED_USER_BIO_FLAGS =
	STANDARD_FLAGS &
	~(
		ParserFlags.ALLOW_HEADINGS |
		ParserFlags.ALLOW_CODE_BLOCKS |
		ParserFlags.ALLOW_ROLE_MENTIONS |
		ParserFlags.ALLOW_EVERYONE_MENTIONS |
		ParserFlags.ALLOW_SUBTEXT |
		ParserFlags.ALLOW_TABLES |
		ParserFlags.ALLOW_ALERTS
	);

/**
 * Parser flags for embed descriptions - disables certain elements
 */
export const RESTRICTED_EMBED_DESCRIPTION_FLAGS =
	STANDARD_FLAGS &
	~(ParserFlags.ALLOW_HEADINGS | ParserFlags.ALLOW_TABLES | ParserFlags.ALLOW_ALERTS | ParserFlags.ALLOW_AUTOLINKS);

/**
 * Gets the appropriate parser flags for the specified context
 */
export function getParserFlagsForContext(context: MarkdownContext): number {
	switch (context) {
		case MarkdownContext.RESTRICTED_INLINE_REPLY:
			return RESTRICTED_INLINE_REPLY_FLAGS;
		case MarkdownContext.RESTRICTED_USER_BIO:
			return RESTRICTED_USER_BIO_FLAGS;
		case MarkdownContext.RESTRICTED_EMBED_DESCRIPTION:
			return RESTRICTED_EMBED_DESCRIPTION_FLAGS;
		default:
			return STANDARD_FLAGS;
	}
}

/**
 * Parses Markdown content with performance metrics enabled
 *
 * @param content - The Markdown content to parse
 * @param context - The markdown context to use for determining parser flags
 * @returns The parsed AST with performance metrics attached
 */
export function parseWithMetrics({content, context}: {content: string; context: MarkdownContext}) {
	const flags = getParserFlagsForContext(context);
	const parser = new Parser(content, flags, {collectMetrics: true});
	return parser.parse();
}

/**
 * Standard parse function for normal use (without metrics overhead)
 *
 * @param content - The Markdown content to parse
 * @param context - The markdown context to use for determining parser flags
 * @returns The parsed AST
 */
export function parse({content, context}: {content: string; context: MarkdownContext}) {
	const flags = getParserFlagsForContext(context);
	const parser = new Parser(content, flags);
	return parser.parse();
}

/**
 * Registry of all node renderers
 */
const renderers: Record<NodeType, any> = {
	[NodeType.Sequence]: SequenceRenderer,
	[NodeType.Text]: TextRenderer,
	[NodeType.Strong]: StrongRenderer,
	[NodeType.Emphasis]: EmphasisRenderer,
	[NodeType.Underline]: UnderlineRenderer,
	[NodeType.Strikethrough]: StrikethroughRenderer,
	[NodeType.Spoiler]: SpoilerRenderer,
	[NodeType.Timestamp]: TimestampRenderer,
	[NodeType.Blockquote]: BlockquoteRenderer,
	[NodeType.CodeBlock]: CodeBlockRenderer,
	[NodeType.InlineCode]: InlineCodeRenderer,
	[NodeType.Link]: LinkRenderer,
	[NodeType.Mention]: MentionRenderer,
	[NodeType.Emoji]: EmojiRenderer,
	[NodeType.List]: ListRenderer,
	[NodeType.Heading]: HeadingRenderer,
	[NodeType.Subtext]: SubtextRenderer,
	[NodeType.TripleAsterisk]: TripleAsteriskRenderer,
	[NodeType.TripleUnderscore]: TripleUnderscoreRenderer,
	[NodeType.Table]: TableRenderer,
	[NodeType.TableRow]: () => null, // Handled by TableRenderer
	[NodeType.TableCell]: () => null, // Handled by TableRenderer
	[NodeType.Alert]: AlertRenderer,
};

/**
 * Renders a single node
 */
function renderNode(node: Node, key: string, options: MarkdownRenderOptions): React.ReactNode {
	const renderer = renderers[node.type];
	if (!renderer) {
		console.warn(`No renderer found for node type: ${node.type}`);
		return null;
	}

	// Create a function that can be passed to renderers to handle their children
	const renderChildrenFn = (children: Array<Node>) =>
		children.map((child, i) => renderNode(child, `${key}-${i}`, options));

	return renderer({
		node,
		key,
		renderChildren: renderChildrenFn,
		options,
	});
}

/**
 * Renders an array of nodes
 */
export function render(nodes: Array<Node>, options: MarkdownParseOptions): React.ReactNode {
	// Determine if emojis should be jumbo size
	// (only in message context and if content is eligible)
	const shouldJumboEmojis = options.context === MarkdownContext.STANDARD_WITH_JUMBO && shouldRenderJumboEmojis(nodes);

	// Create extended options with jumbo emoji flag
	const renderOptions: MarkdownRenderOptions = {
		...options,
		shouldJumboEmojis,
	};

	// Render each node in the array
	return nodes.map((node, i) => renderNode(node, `${options.context}-${i}`, renderOptions));
}

/**
 * Wraps rendered content based on context
 */
export function wrapRenderedContent(content: React.ReactNode, context: MarkdownContext): React.ReactNode {
	if (context === MarkdownContext.RESTRICTED_INLINE_REPLY) {
		return <div className={markupStyles.inlineFormat}>{content}</div>;
	}

	return content;
}
