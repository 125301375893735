import {BugBeetle} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import type {FC} from 'react';
import invariant from 'tiny-invariant';
import {MessageEmbedMediaFlags, MessageEmbedTypes} from '~/Constants';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import EmbedAudio from '~/components/channel/embeds/media/EmbedAudio';
import {EmbedGif, EmbedGifv} from '~/components/channel/embeds/media/EmbedGifv';
import {EmbedImage} from '~/components/channel/embeds/media/EmbedImage';
import EmbedVideo from '~/components/channel/embeds/media/EmbedVideo';
import {EmbedYouTube} from '~/components/channel/embeds/media/EmbedYouTube';
import {PerformanceDebugModal} from '~/components/modals/PerformanceDebugModal';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {SafeMarkdown} from '~/lib/markdown';
import {MarkdownContext, parseWithMetrics} from '~/lib/markdown/renderers';
import type {
	EmbedAuthor as EmbedAuthorData,
	EmbedField as EmbedFieldType,
	EmbedFooter as EmbedFooterData,
	EmbedMedia,
	MessageEmbed,
	MessageRecord,
} from '~/records/MessageRecord';
import markupStyles from '~/styles/Markup.module.css';
import * as ColorUtils from '~/utils/ColorUtils';
import * as DateUtils from '~/utils/DateUtils';
import {createCalculator} from '~/utils/DimensionUtils';
import styles from './Embed.module.css';

const MEDIA_MAX_WIDTH = 400;
const MEDIA_MAX_HEIGHT = 300;
const THUMBNAIL_SIZE = 80;

type EmbedProps = {
	embed: MessageEmbed;
	message: MessageRecord;
};

type LinkComponentProps = {
	url: string;
	children: React.ReactNode;
	className?: string;
};

type MediaDimensions = {
	width: number;
	height: number;
};

const mediaCalculator = createCalculator({
	maxWidth: MEDIA_MAX_WIDTH,
	maxHeight: MEDIA_MAX_HEIGHT,
});

const thumbnailCalculator = createCalculator({
	maxWidth: THUMBNAIL_SIZE,
	maxHeight: THUMBNAIL_SIZE,
	forceScale: true,
});

const isValidMedia = (media?: Partial<EmbedMedia>): media is Required<EmbedMedia> => {
	return !!(
		media &&
		typeof media.proxy_url === 'string' &&
		typeof media.url === 'string' &&
		typeof media.width === 'number' &&
		typeof media.height === 'number'
	);
};

const calculateMediaDimensions = (media: Required<EmbedMedia>, calculator = mediaCalculator): MediaDimensions => {
	const {dimensions} = calculator.calculate({
		width: media.width,
		height: media.height,
	});
	return dimensions;
};

const shouldRenderAsInlineThumbnail = (media?: EmbedMedia): boolean => {
	if (!isValidMedia(media)) return false;

	const {width: thumbnailWidth} = calculateMediaDimensions(media, thumbnailCalculator);
	const {width: normalWidth} = calculateMediaDimensions(media);

	return normalWidth < 300 && thumbnailWidth >= 40;
};

const getBorderColor = (color: number | undefined) => {
	if (color === undefined || color === 0) {
		return 'var(--background-header-secondary)';
	}
	return ColorUtils.int2rgb(color);
};

const LinkComponent: FC<LinkComponentProps> = ({url, children, className}) => (
	<a className={clsx(styles.embedLink, className)} href={url} rel="noopener noreferrer" target="_blank">
		{children}
	</a>
);

const EmbedProvider: FC<{provider?: EmbedAuthorData}> = ({provider}) => {
	if (!provider) return null;

	return (
		<div className={styles.embedProvider}>
			{provider.url ? <LinkComponent url={provider.url}>{provider.name}</LinkComponent> : <span>{provider.name}</span>}
		</div>
	);
};

const EmbedAuthor: FC<{author?: EmbedAuthorData}> = ({author}) => {
	if (!author) return null;

	return (
		<div className={styles.embedAuthor}>
			{author.proxy_icon_url && (
				<img alt="" className={styles.embedAuthorIcon} src={author.proxy_icon_url} width={24} height={24} />
			)}
			{author.url ? (
				<LinkComponent className={clsx(styles.embedAuthorName, styles.embedAuthorNameLink)} url={author.url}>
					{author.name}
				</LinkComponent>
			) : (
				<span className={styles.embedAuthorName}>{author.name}</span>
			)}
		</div>
	);
};

const EmbedTitle: FC<{title?: string; url?: string}> = ({title, url}) => {
	if (!title) return null;

	return (
		<div className={styles.embedTitle}>
			{url ? (
				<LinkComponent url={url}>
					<SafeMarkdown content={title} options={{context: MarkdownContext.RESTRICTED_INLINE_REPLY}} />
				</LinkComponent>
			) : (
				<span>
					<SafeMarkdown content={title} options={{context: MarkdownContext.RESTRICTED_INLINE_REPLY}} />
				</span>
			)}
		</div>
	);
};

const EmbedDescription: FC<{
	messageId?: string;
	channelId?: string;
	description?: string;
}> = ({messageId, channelId, description}) => {
	if (!description) return null;

	return (
		<div className={styles.embedDescription}>
			<SafeMarkdown
				content={description}
				options={{
					context: MarkdownContext.RESTRICTED_EMBED_DESCRIPTION,
					messageId,
					channelId,
				}}
			/>
		</div>
	);
};

const EmbedFields: FC<{fields: Array<EmbedFieldType>}> = ({fields}) => {
	if (!fields?.length) return null;

	const groupFields = (fields: Array<EmbedFieldType>): Array<Array<EmbedFieldType>> => {
		const groupedFields: Array<Array<EmbedFieldType>> = [];
		let currentGroup: Array<EmbedFieldType> = [];

		for (const field of fields) {
			if (field.inline) {
				currentGroup.push(field);
			} else {
				if (currentGroup.length > 0) {
					groupedFields.push(currentGroup);
					currentGroup = [];
				}
				groupedFields.push([field]);
			}
		}

		if (currentGroup.length > 0) {
			groupedFields.push(currentGroup);
		}

		return groupedFields;
	};

	const groupedFields = groupFields(fields);

	return (
		<div className={styles.embedFields}>
			{groupedFields.map((group, groupIndex) => {
				const groupLength = group.length;
				return group.map(({name, value}, index) => {
					const gridColumnStart = index * (12 / groupLength) + 1;
					const gridColumnEnd = gridColumnStart + 12 / groupLength;
					return (
						<div
							className={styles.embedField}
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							key={`${groupIndex}-${index}`}
							style={{gridColumn: `${gridColumnStart} / ${gridColumnEnd}`}}
						>
							<div className={styles.embedFieldName}>
								<SafeMarkdown content={name} options={{context: MarkdownContext.RESTRICTED_INLINE_REPLY}} />
							</div>
							<div className={styles.embedFieldValue}>
								<SafeMarkdown content={value} options={{context: MarkdownContext.RESTRICTED_EMBED_DESCRIPTION}} />
							</div>
						</div>
					);
				});
			})}
		</div>
	);
};

const EmbedFooter: FC<{
	timestamp?: number;
	footer?: EmbedFooterData;
}> = ({timestamp, footer}) => {
	const formattedTimestamp = timestamp ? DateUtils.getRelativeDateString(timestamp) : undefined;
	if (!(footer || formattedTimestamp)) return null;

	return (
		<div className={clsx(styles.embedFooter, footer?.proxy_icon_url && styles.hasThumbnail)}>
			{footer?.proxy_icon_url && (
				<img alt="" className={styles.embedFooterIcon} src={footer.proxy_icon_url} width={20} height={20} />
			)}
			<div className={styles.embedFooterText}>
				{footer?.text}
				{formattedTimestamp && (
					<>
						<div className={styles.embedFooterSeparator} />
						<span>{formattedTimestamp}</span>
					</>
				)}
			</div>
		</div>
	);
};

const EmbedMediaRenderer: FC<{embed: MessageEmbed}> = ({embed}) => {
	const {video, image, thumbnail} = embed;

	if (!isValidMedia(video) && !isValidMedia(image) && !isValidMedia(thumbnail)) {
		return null;
	}

	if (isValidMedia(video) && embed.provider?.url && new URL(embed.provider.url).hostname === 'www.youtube.com') {
		return <EmbedYouTube embed={embed} />;
	}

	if (isValidMedia(video)) {
		const {width, height} = calculateMediaDimensions(video);
		return <EmbedVideo src={video.proxy_url} width={width} height={height} placeholder={video.placeholder} />;
	}

	if (isValidMedia(image)) {
		const {width, height} = calculateMediaDimensions(image);
		return (
			<EmbedImage
				src={image.proxy_url}
				originalSrc={image.url}
				naturalWidth={image.width}
				naturalHeight={image.height}
				width={width}
				height={height}
				placeholder={image.placeholder}
				constrain={true}
			/>
		);
	}

	if (isValidMedia(thumbnail)) {
		const {width, height} = calculateMediaDimensions(thumbnail);
		return (
			<EmbedImage
				src={thumbnail.proxy_url}
				originalSrc={thumbnail.url}
				naturalWidth={thumbnail.width}
				naturalHeight={thumbnail.height}
				width={width}
				height={height}
				placeholder={thumbnail.placeholder}
				constrain={true}
			/>
		);
	}

	return null;
};

const RichEmbed: FC<EmbedProps> = ({embed, message}) => {
	const [showDebugButton, setShowDebugButton] = React.useState(false);
	const hasVideo = isValidMedia(embed.video);
	const hasImage = isValidMedia(embed.image);
	const hasThumbnail = isValidMedia(embed.thumbnail);
	const hasAnyMedia = hasVideo || hasImage || hasThumbnail;
	const isInlineThumbnail = !hasVideo && hasThumbnail && shouldRenderAsInlineThumbnail(embed.thumbnail);
	const isYouTubeEmbed = embed.provider?.url && new URL(embed.provider.url).hostname === 'www.youtube.com';
	const useNarrowWidth = hasAnyMedia && !isInlineThumbnail;
	const hasDescription = !!embed.description;

	const handleDebugClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		if (!embed.description) return;

		ModalActionCreators.push(() => {
			invariant(embed.description, 'Embed description is missing');
			const {nodes, performanceMetrics} = parseWithMetrics({
				content: embed.description,
				context: MarkdownContext.RESTRICTED_EMBED_DESCRIPTION,
			});
			invariant(performanceMetrics, 'Performance metrics are missing');
			return (
				<PerformanceDebugModal
					title="Embed Description AST with Performance Metrics"
					astData={nodes}
					performanceMetrics={performanceMetrics}
				/>
			);
		});
	};

	const handleDebugKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			handleDebugClick(e as unknown as React.MouseEvent);
		}
	};

	return (
		<article
			className={clsx(
				styles.embed,
				styles.embedFull,
				styles.embedWrapper,
				styles.embedFlexGrow,
				hasAnyMedia && styles.justifyAuto,
				markupStyles.markup,
			)}
			style={{
				borderLeft: `4px solid ${getBorderColor(embed.color)}`,
				maxWidth: useNarrowWidth ? '432px' : '516px',
				position: 'relative',
			}}
			onMouseEnter={() => setShowDebugButton(true)}
			onMouseLeave={() => setShowDebugButton(false)}
		>
			<div className={styles.gridContainer}>
				<div className={clsx(styles.grid, isInlineThumbnail && styles.hasThumbnail)}>
					<div className={styles.embedContent}>
						<EmbedProvider provider={embed.provider} />
						<EmbedAuthor author={embed.author} />
						<EmbedTitle title={embed.title} url={embed.url} />
						{!isYouTubeEmbed && (
							<EmbedDescription description={embed.description} messageId={message.id} channelId={message.channelId} />
						)}
						<EmbedFields fields={embed.fields?.slice() ?? []} />

						{!isInlineThumbnail && hasAnyMedia && (
							<div className={clsx(styles.embedMedia, styles.embedWrapper, styles.embedImage)}>
								<EmbedMediaRenderer embed={embed} />
							</div>
						)}

						{embed.footer && (
							<div className={styles.embedFooterContainer}>
								<EmbedFooter footer={embed.footer} timestamp={embed.timestamp} />
							</div>
						)}
					</div>

					{isInlineThumbnail && embed.thumbnail && isValidMedia(embed.thumbnail) && (
						<div className={styles.embedThumbnail}>
							<EmbedImage
								src={embed.thumbnail.proxy_url}
								originalSrc={embed.thumbnail.url}
								naturalWidth={embed.thumbnail.width}
								naturalHeight={embed.thumbnail.height}
								width={Math.min(80, Math.round((80 * embed.thumbnail.width) / embed.thumbnail.height))}
								height={80}
								placeholder={embed.thumbnail.placeholder}
								constrain={true}
								isInline={true}
							/>
						</div>
					)}
				</div>
			</div>

			{/* Debug button absolutely positioned in bottom-right corner */}
			{hasDescription && showDebugButton && (
				<Tooltip text="Debug Embed Description AST">
					<div
						onClick={handleDebugClick}
						onKeyDown={handleDebugKeyDown}
						role="button"
						tabIndex={0}
						aria-label="Debug Embed Description AST"
						style={{
							position: 'absolute',
							bottom: '8px',
							right: '8px',
							width: '24px',
							height: '24px',
							borderRadius: '4px',
							backgroundColor: 'var(--background-secondary)',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'pointer',
							zIndex: 1,
							border: '1px solid var(--background-tertiary)',
							boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
						}}
					>
						<BugBeetle weight="bold" style={{color: 'var(--interactive-normal)', width: '16px', height: '16px'}} />
					</div>
				</Tooltip>
			)}
		</article>
	);
};

export const Embed: FC<EmbedProps> = ({embed, message}) => {
	const hasRichContent = !!(
		embed.title ||
		embed.description ||
		embed.author ||
		embed.footer ||
		embed.fields?.length ||
		(embed.provider &&
			!(
				embed.type === MessageEmbedTypes.GIFV &&
				embed.provider.url &&
				new URL(embed.provider.url).hostname === 'tenor.com'
			))
	);

	if (!hasRichContent) {
		if (embed.type === MessageEmbedTypes.AUDIO && embed.audio?.proxy_url) {
			return <EmbedAudio src={embed.audio.proxy_url} title={embed.title} />;
		}

		if (embed.type === MessageEmbedTypes.VIDEO && isValidMedia(embed.video)) {
			if (embed.provider?.url && new URL(embed.provider.url).hostname === 'www.youtube.com') {
				return <EmbedYouTube embed={embed} />;
			}

			const {width, height} = calculateMediaDimensions(embed.video);
			return (
				<EmbedVideo src={embed.video.proxy_url} width={width} height={height} placeholder={embed.video.placeholder} />
			);
		}

		const {thumbnail} = embed;
		if (
			embed.type === MessageEmbedTypes.IMAGE &&
			isValidMedia(thumbnail) &&
			(thumbnail.flags & MessageEmbedMediaFlags.ANIMATED) === MessageEmbedMediaFlags.ANIMATED
		) {
			return (
				<EmbedGif
					embedURL={thumbnail.url}
					proxyURL={thumbnail.proxy_url}
					naturalWidth={thumbnail.width}
					naturalHeight={thumbnail.height}
					placeholder={thumbnail.placeholder}
				/>
			);
		}

		if (embed.type === MessageEmbedTypes.GIFV && isValidMedia(embed.video) && isValidMedia(thumbnail) && embed.url) {
			return (
				<EmbedGifv
					embedURL={embed.url}
					videoProxyURL={embed.video.proxy_url}
					videoURL={embed.video.url}
					naturalWidth={thumbnail.width}
					naturalHeight={thumbnail.height}
					placeholder={thumbnail.placeholder}
				/>
			);
		}

		if (isValidMedia(thumbnail)) {
			const {width, height} = calculateMediaDimensions(thumbnail);
			return (
				<EmbedImage
					src={thumbnail.proxy_url}
					originalSrc={thumbnail.url}
					naturalWidth={thumbnail.width}
					naturalHeight={thumbnail.height}
					width={width}
					height={height}
					placeholder={thumbnail.placeholder}
					constrain={true}
				/>
			);
		}

		return null;
	}

	return <RichEmbed embed={embed} message={message} />;
};
