import {type Node, NodeType, ParserFlags, type ParserResult} from '../types';
import {MAX_LINK_URL_LENGTH} from '../types/constants';
import {performanceMetrics} from '../utils/performance-metrics';
import * as StringUtils from '../utils/string-utils';
import * as URLUtils from '../utils/url-utils';

// Pre-compiled regex patterns for better performance
const SPOOFED_LINK_PATTERN = /^\[https?:\/\/[^\s\[\]]+\]\(https?:\/\/[^\s\[\]]+\)$/;
const CONTAINS_LINK_SYNTAX_PATTERN = /\[[^\]]*\]\([^)]*\)/;
const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const URL_DOMAIN_PATTERN =
	/^(?:https?:\/\/)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(?:\/[^\s\[\]]*)?$/;

// Character codes for faster comparisons
const OPEN_BRACKET = 91; // '['
const CLOSE_BRACKET = 93; // ']'
const OPEN_PAREN = 40; // '('
const CLOSE_PAREN = 41; // ')'
const BACKSLASH = 92; // '\'
const LESS_THAN = 60; // '<'
const GREATER_THAN = 62; // '>'
const DOUBLE_QUOTE = 34; // '"'
const SINGLE_QUOTE = 39; // "'"

// Cache for domain extraction
const domainCache = new Map<string, string>();
const MAX_DOMAIN_CACHE_SIZE = 200;

/**
 * Parses a Markdown-style link [text](url)
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param parseInline - Function to parse inline content
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no link found
 */
export function parseLink(
	text: string,
	_parserFlags: number,
	parseInline: (text: string) => Array<Node>,
	collectMetrics = false,
): ParserResult | null {
	// Fast check for first character
	if (text.charCodeAt(0) !== OPEN_BRACKET) return null;

	if (collectMetrics) {
		performanceMetrics.startOperation('LinkParsers.extractLinkParts');
	}

	const linkParts = extractLinkParts(text);

	if (collectMetrics) {
		performanceMetrics.endOperation('LinkParsers.extractLinkParts');
	}

	if (!linkParts) {
		// Check for potential link spoofing patterns
		const spoofedLinkMatch = SPOOFED_LINK_PATTERN.test(text);

		if (spoofedLinkMatch) {
			// Treat the entire text as plain text for spoofed links
			return {
				node: {type: NodeType.Text, content: text},
				advance: text.length,
			};
		}

		// Find the closing bracket to determine the extent of this non-link bracket expression
		const bracketResult = findClosingBracket(text);

		if (bracketResult) {
			const {bracketPosition, linkText} = bracketResult;

			// Check for nested link syntax in what would be the link text
			// This catches cases like [text [nested](url) more](url)
			if (linkText.includes('[') && linkText.includes('](')) {
				return {
					node: {type: NodeType.Text, content: text},
					advance: text.length,
				};
			}

			// For bracket text like [1], treat it as text and advance past it
			return {
				node: {type: NodeType.Text, content: text.slice(0, bracketPosition + 1)},
				advance: bracketPosition + 1,
			};
		}

		return null;
	}

	try {
		if (collectMetrics) {
			performanceMetrics.startOperation('URLUtils.isValidUrl');
		}

		const isValid = URLUtils.isValidUrl(linkParts.url);

		if (collectMetrics) {
			performanceMetrics.endOperation('URLUtils.isValidUrl');
		}

		if (isValid) {
			if (collectMetrics) {
				performanceMetrics.startOperation('URLUtils.convertToAsciiUrl');
			}

			const finalUrl = URLUtils.convertToAsciiUrl(linkParts.url);

			if (collectMetrics) {
				performanceMetrics.endOperation('URLUtils.convertToAsciiUrl');
				performanceMetrics.startOperation('parseInline for link text');
			}

			const inlineNodes = parseInline(linkParts.linkText);

			if (collectMetrics) {
				performanceMetrics.endOperation('parseInline for link text');
			}

			return {
				node: {
					type: NodeType.Link,
					text: inlineNodes.length === 1 ? inlineNodes[0] : {type: NodeType.Sequence, children: inlineNodes},
					url: finalUrl,
					escaped: linkParts.isEscaped,
				},
				advance: linkParts.advanceBy,
			};
		}
	} catch {
		return {
			node: {type: NodeType.Text, content: text.slice(0, linkParts.advanceBy)},
			advance: linkParts.advanceBy,
		};
	}

	return null;
}

/**
 * Extracts the components of a Markdown link
 * Optimized to avoid character array operations
 *
 * @param text - The text to parse
 * @returns Link parts object or null if not a valid link
 */
export function extractLinkParts(
	text: string,
): {linkText: string; url: string; isEscaped: boolean; advanceBy: number} | null {
	const bracketResult = findClosingBracket(text);
	if (!bracketResult) return null;

	const {bracketPosition, linkText} = bracketResult;

	// Check for opening parenthesis after closing bracket
	if (bracketPosition + 1 >= text.length || text.charCodeAt(bracketPosition + 1) !== OPEN_PAREN) return null;

	const trimmedLinkText = linkText.trim();

	// Check for nested links using pre-compiled regex
	const containsLinkSyntax = CONTAINS_LINK_SYNTAX_PATTERN.test(trimmedLinkText);

	// Check for email spoofing using pre-compiled regex
	const isEmailSpoofing = EMAIL_PATTERN.test(trimmedLinkText);

	// If it contains link syntax or is an email, reject it
	if (containsLinkSyntax || isEmailSpoofing) {
		return null;
	}

	const urlInfo = extractUrl(text, bracketPosition + 2);
	if (!urlInfo) return null;

	// Don't parse links with quoted URLs
	if (urlInfo.url.includes('"') || urlInfo.url.includes("'")) {
		return null;
	}

	// Check if the link text looks like a URL or domain-like with path
	const isUrlOrDomainLike = URL_DOMAIN_PATTERN.test(trimmedLinkText);

	if (isUrlOrDomainLike) {
		try {
			// Extract domains from both link text and URL
			const textDomain = extractDomainFromString(trimmedLinkText);
			const urlDomain = extractDomainFromString(urlInfo.url);

			// If domains don't match, reject the link
			if (textDomain && urlDomain && textDomain !== urlDomain) {
				return null;
			}
		} catch {
			// If domain extraction fails, be safe and reject the link
			return null;
		}
	}

	return {
		linkText,
		...urlInfo,
	};
}

/**
 * Finds the closing bracket in a Markdown link
 * Optimized to avoid character array operations
 *
 * @param text - The text to search
 * @returns Position of the closing bracket and the text inside, or null if not found
 */
export function findClosingBracket(text: string): {bracketPosition: number; linkText: string} | null {
	let position = 1;
	let nestedBrackets = 0;
	const textLength = text.length;

	while (position < textLength) {
		const currentChar = text.charCodeAt(position);

		if (currentChar === OPEN_BRACKET) {
			nestedBrackets++;
			position++;
		} else if (currentChar === CLOSE_BRACKET) {
			if (nestedBrackets > 0) {
				nestedBrackets--;
				position++;
			} else {
				return {
					bracketPosition: position,
					linkText: text.slice(1, position),
				};
			}
		} else if (currentChar === BACKSLASH && position + 1 < textLength) {
			position += 2; // Skip escaped character
		} else {
			position++;
		}

		if (position > MAX_LINK_URL_LENGTH) break;
	}

	return null;
}

/**
 * Extracts the URL portion of a Markdown link
 * Optimized to use string operations instead of array operations
 *
 * @param text - The text to search
 * @param startPos - The starting position (after the opening parenthesis)
 * @returns URL information or null if not a valid URL
 */
export function extractUrl(
	text: string,
	startPos: number,
): {url: string; isEscaped: boolean; advanceBy: number} | null {
	if (startPos >= text.length) return null;

	return text.charCodeAt(startPos) === LESS_THAN
		? extractEscapedUrl(text, startPos + 1)
		: extractUnescapedUrl(text, startPos);
}

/**
 * Extracts an escaped URL from angle brackets
 * Optimized to use string operations
 *
 * @param text - The text to search
 * @param urlStart - The starting position (after the opening angle bracket)
 * @returns URL information or null if not a valid URL
 */
export function extractEscapedUrl(
	text: string,
	urlStart: number,
): {url: string; isEscaped: boolean; advanceBy: number} | null {
	const textLength = text.length;
	let currentPos = urlStart;

	while (currentPos < textLength) {
		if (text.charCodeAt(currentPos) === GREATER_THAN) {
			const url = text.slice(urlStart, currentPos);

			currentPos++;
			// Find closing parenthesis
			while (currentPos < textLength && text.charCodeAt(currentPos) !== CLOSE_PAREN) {
				currentPos++;
			}

			return {
				url,
				isEscaped: true,
				advanceBy: currentPos + 1,
			};
		}
		currentPos++;
	}

	return null;
}

/**
 * Extracts an unescaped URL (not in angle brackets)
 * Optimized to use string operations
 *
 * @param text - The text to search
 * @param urlStart - The starting position
 * @returns URL information or null if not a valid URL
 */
export function extractUnescapedUrl(
	text: string,
	urlStart: number,
): {url: string; isEscaped: boolean; advanceBy: number} | null {
	const textLength = text.length;
	let currentPos = urlStart;
	let nestedParens = 0;

	while (currentPos < textLength) {
		const currentChar = text.charCodeAt(currentPos);

		if (currentChar === OPEN_PAREN) {
			nestedParens++;
			currentPos++;
		} else if (currentChar === CLOSE_PAREN) {
			if (nestedParens > 0) {
				nestedParens--;
				currentPos++;
			} else {
				// Get URL and remove trailing punctuation
				let url = text.slice(urlStart, currentPos);

				// Remove trailing punctuation efficiently
				const lastChar = url.length > 0 ? url.charCodeAt(url.length - 1) : 0;
				if (lastChar && ',.:;!?'.indexOf(String.fromCharCode(lastChar)) !== -1) {
					url = url.slice(0, -1);
				}

				return {
					url,
					isEscaped: false,
					advanceBy: currentPos + 1,
				};
			}
		} else {
			currentPos++;
		}
	}

	return null;
}

/**
 * Parses a URL segment in plain text
 * Optimized for faster URL detection
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no URL found
 */
export function extractUrlSegment(text: string, parserFlags: number, collectMetrics = false): ParserResult | null {
	// Check for ALLOW_AUTOLINKS flag
	if (!(parserFlags & ParserFlags.ALLOW_AUTOLINKS)) return null;

	// Fast path for HTTP/HTTPS prefixes
	let prefixLength = 0;
	if (text.startsWith('https://')) {
		prefixLength = 8;
	} else if (text.startsWith('http://')) {
		prefixLength = 7;
	} else {
		return null;
	}

	// Find end of URL
	let end = prefixLength;
	const textLength = text.length;

	while (end < textLength && !StringUtils.isUrlTerminationChar(text[end])) {
		end++;
		if (end - prefixLength > MAX_LINK_URL_LENGTH) break;
	}

	// Get URL and remove trailing punctuation
	let urlString = text.slice(0, end);

	// Efficiently trim punctuation from end
	let lastChar = urlString.length > 0 ? urlString.charCodeAt(urlString.length - 1) : 0;
	while (lastChar && ',.:;!?'.indexOf(String.fromCharCode(lastChar)) !== -1) {
		urlString = urlString.slice(0, -1);
		lastChar = urlString.length > 0 ? urlString.charCodeAt(urlString.length - 1) : 0;
	}

	// Check if this URL is surrounded by quotes - matching original logic exactly
	// Note: This is important for setting the escaped flag correctly
	const isInQuotes = (end < textLength && text.charAt(end) === '"') || text.substring(0, prefixLength).includes('"');

	if (collectMetrics) {
		performanceMetrics.startOperation('URLUtils.isValidUrl for extractUrlSegment');
	}

	const isValid = URLUtils.isValidUrl(urlString);

	if (collectMetrics) {
		performanceMetrics.endOperation('URLUtils.isValidUrl for extractUrlSegment');
	}

	if (isValid) {
		// Extra check to ensure we don't auto-link mailto URLs
		if (urlString.startsWith('mailto:')) return null;

		if (collectMetrics) {
			performanceMetrics.startOperation('URLUtils.convertToAsciiUrl for extractUrlSegment');
		}

		const finalUrl = URLUtils.convertToAsciiUrl(urlString);

		if (collectMetrics) {
			performanceMetrics.endOperation('URLUtils.convertToAsciiUrl for extractUrlSegment');
		}

		return {
			node: {type: NodeType.Link, text: undefined, url: finalUrl, escaped: isInQuotes},
			advance: urlString.length,
		};
	}

	return null;
}

/**
 * Parses an autolink in angle brackets <https://example.com>
 * Optimized for direct character code comparisons
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no autolink found
 */
export function parseAutolink(text: string, parserFlags: number, collectMetrics = false): ParserResult | null {
	// Check for ALLOW_AUTOLINKS flag
	if (!(parserFlags & ParserFlags.ALLOW_AUTOLINKS)) return null;

	// Fast path - must start with '<'
	if (text.charCodeAt(0) !== LESS_THAN) return null;

	// Don't parse auto-links with quotes after the opening bracket
	if (text.length > 1 && (text.charCodeAt(1) === DOUBLE_QUOTE || text.charCodeAt(1) === SINGLE_QUOTE)) {
		return null;
	}

	// Check if content after '<' starts with a URL
	if (!StringUtils.startsWithUrl(text.slice(1))) return null;

	// Find closing angle bracket
	const end = text.indexOf('>', 1);
	if (end === -1) return null;

	const urlString = text.slice(1, end);
	if (urlString.length > MAX_LINK_URL_LENGTH) return null;

	if (collectMetrics) {
		performanceMetrics.startOperation('URLUtils.isValidUrl for parseAutolink');
	}

	const isValid = URLUtils.isValidUrl(urlString);

	if (collectMetrics) {
		performanceMetrics.endOperation('URLUtils.isValidUrl for parseAutolink');
	}

	if (isValid) {
		// Explicitly block mailto URLs in angle brackets - those should only be handled by parseEmailLink
		if (urlString.startsWith('mailto:')) return null;

		if (collectMetrics) {
			performanceMetrics.startOperation('URLUtils.convertToAsciiUrl for parseAutolink');
		}

		const finalUrl = URLUtils.convertToAsciiUrl(urlString);

		if (collectMetrics) {
			performanceMetrics.endOperation('URLUtils.convertToAsciiUrl for parseAutolink');
		}

		return {
			node: {type: NodeType.Link, text: undefined, url: finalUrl, escaped: true},
			advance: end + 1,
		};
	}

	return null;
}

/**
 * Parses an email link in angle brackets <user@example.com>
 * Optimized for faster validation
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param collectMetrics - Whether to collect performance metrics
 * @returns Parser result or null if no email link found
 */
export function parseEmailLink(text: string, parserFlags: number, collectMetrics = false): ParserResult | null {
	// Check for ALLOW_AUTOLINKS flag
	if (!(parserFlags & ParserFlags.ALLOW_AUTOLINKS)) return null;

	// Fast path - must start with '<'
	if (text.charCodeAt(0) !== LESS_THAN) return null;

	// Find closing angle bracket
	const end = text.indexOf('>', 1);
	if (end === -1) return null;

	// Extract the content between the angle brackets
	const content = text.slice(1, end);

	// Fast check for URLs - they should be handled by parseAutolink
	if (content.startsWith('http://') || content.startsWith('https://')) return null;

	// Fast check for @ sign - required for emails
	if (content.indexOf('@') === -1) return null;

	// Check if the content is a valid email address
	if (collectMetrics) {
		performanceMetrics.startOperation('URLUtils.isValidEmail');
	}

	const isValid = URLUtils.isValidEmail(content);

	if (collectMetrics) {
		performanceMetrics.endOperation('URLUtils.isValidEmail');
	}

	if (isValid) {
		return {
			node: {
				type: NodeType.Link,
				text: {type: NodeType.Text, content: content},
				url: `mailto:${content}`,
				escaped: true,
			},
			advance: end + 1,
		};
	}

	return null;
}

/**
 * Extracts and normalizes domain from a string
 * Optimized with caching for repeated domains
 *
 * @param input - The input string that may contain a domain
 * @returns Normalized domain or empty string if extraction fails
 */
export function extractDomainFromString(input: string): string {
	// Check cache first
	if (domainCache.has(input)) {
		return domainCache.get(input)!;
	}

	try {
		// Ensure it has a protocol for URL parsing
		const urlString = input.startsWith('http') ? input : `http://${input}`;
		const url = new URL(urlString);
		let domain = url.hostname;

		// Remove www. prefix if present
		if (domain.startsWith('www.')) {
			domain = domain.substring(4);
		}

		// Cache the result
		domainCache.set(input, domain);

		// Keep cache size in check
		if (domainCache.size > MAX_DOMAIN_CACHE_SIZE) {
			// Remove oldest entries
			const keysToDelete = Array.from(domainCache.keys()).slice(0, 50);
			for (const key of keysToDelete) {
				domainCache.delete(key);
			}
		}

		return domain;
	} catch {
		// If URL parsing fails, cache empty string to avoid repeated attempts
		domainCache.set(input, '');
		return '';
	}
}
