import clsx from 'clsx';
import type React from 'react';
import {GUILD_TEXT_CHANNEL_TYPES} from '~/Constants';
import {PreloadableUserPopout} from '~/components/channel/PreloadableUserPopout';
import {i18n} from '~/i18n';
import ChannelStore from '~/stores/ChannelStore';
import GuildStore from '~/stores/GuildStore';
import UserStore from '~/stores/UserStore';
import markupStyles from '~/styles/Markup.module.css';
import * as ChannelUtils from '~/utils/ChannelUtils';
import * as ColorUtils from '~/utils/ColorUtils';
import * as NicknameUtils from '~/utils/NicknameUtils';
import * as RouterUtils from '~/utils/RouterUtils';
import type {RendererProps} from '.';
import {GuildNavKind, MentionKind, type MentionNode} from '../parser';

/**
 * Gets information about a user based on their ID and channel context
 */
interface UserInfo {
	userId: string | null;
	name: string | null;
}

function getUserInfo(userId: string, channelId?: string): UserInfo {
	if (!userId) {
		return {userId: null, name: null};
	}

	const user = UserStore.getUser(userId);
	if (!user) {
		return {userId, name: userId};
	}

	let name = user.displayName;

	// If in a guild, use nickname if available
	if (channelId) {
		const channel = ChannelStore.getChannel(channelId);
		if (channel?.guildId) {
			name = NicknameUtils.getNickname(user, channel.guildId) || name;
		}
	}

	return {userId: user.id, name};
}

/**
 * Renders a mention node
 */
export function MentionRenderer({node, key, options}: RendererProps<MentionNode>): React.ReactElement {
	const {kind} = node;
	const {channelId} = options;

	switch (kind.kind) {
		case MentionKind.User: {
			const {userId, name} = getUserInfo(kind.id, channelId);

			// No user info, render plain mention
			if (!userId) {
				return (
					<span key={key} className={markupStyles.mention}>
						@{name || kind.id}
					</span>
				);
			}

			const user = UserStore.getUser(userId);
			if (!user) {
				return (
					<span key={key} className={markupStyles.mention}>
						@{name || kind.id}
					</span>
				);
			}

			// Get guild context for the mention
			const channel = channelId ? ChannelStore.getChannel(channelId) : undefined;
			const guildId = channel?.guildId || '';

			// Return user mention with popout
			return (
				<span key={key} className="inline-flex items-center">
					<PreloadableUserPopout user={user} isWebhook={false} guildId={guildId} position="right-start">
						<span className={clsx(markupStyles.mention, markupStyles.interactive)}>
							<span className="-mt-[.2em] inline-flex items-center align-middle">@{name || user.displayName}</span>
						</span>
					</PreloadableUserPopout>
				</span>
			);
		}

		case MentionKind.Role: {
			const guild = GuildStore.getGuild(kind.id);
			const role = guild?.roles[kind.id];
			if (!role) {
				return (
					<span key={key} className={markupStyles.mention}>
						{i18n.Messages.UNKNOWN_ROLE_PLACEHOLDER}
					</span>
				);
			}

			// Return role mention with appropriate styling
			return (
				<span
					key={key}
					className={markupStyles.mention}
					style={{
						color: ColorUtils.int2rgb(role.color),
						backgroundColor: ColorUtils.int2rgba(role.color, 0.1),
					}}
				>
					@{role.name}
				</span>
			);
		}

		case MentionKind.Channel: {
			const channel = ChannelStore.getChannel(kind.id);
			if (!channel || !GUILD_TEXT_CHANNEL_TYPES.has(channel.type as any)) {
				return (
					<span key={key} className={markupStyles.mention}>
						{i18n.Messages.UNKNOWN_CHANNEL_PLACEHOLDER}
					</span>
				);
			}

			// Return interactive channel mention
			return (
				<span
					key={key}
					className={markupStyles.mention}
					onClick={() => RouterUtils.transitionTo(`/channels/${channel.guildId}/${channel.id}`)}
					onKeyDown={(e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							RouterUtils.transitionTo(`/channels/${channel.guildId}/${channel.id}`);
						}
					}}
					role="button"
					tabIndex={0}
				>
					<div className="-mt-[.2em] inline-flex items-center align-middle">
						{ChannelUtils.getIcon(channel.type, {className: 'mr-px h-4 w-4'})}
						{channel.name}
					</div>
				</span>
			);
		}

		case MentionKind.Everyone: {
			return (
				<span key={key} className={markupStyles.mention}>
					@everyone
				</span>
			);
		}

		case MentionKind.Here: {
			return (
				<span key={key} className={markupStyles.mention}>
					@here
				</span>
			);
		}

		case MentionKind.Command: {
			const {name, subcommandGroup, subcommand, id} = kind;
			return (
				<span key={key} className={markupStyles.mention}>
					{`</${name}${subcommandGroup ? ` ${subcommandGroup}` : ''}${subcommand ? ` ${subcommand}` : ''}:${id}>`}
				</span>
			);
		}

		case MentionKind.GuildNavigation: {
			const {navigationType} = kind;

			switch (navigationType) {
				case GuildNavKind.Customize:
					return (
						<span key={key} className={markupStyles.mention}>
							{'<id:customize>'}
						</span>
					);

				case GuildNavKind.Browse:
				case GuildNavKind.Guide:
					return (
						<span key={key} className={markupStyles.mention}>
							{`<id:${navigationType}>`}
						</span>
					);

				case GuildNavKind.LinkedRoles: {
					// GuildNavKind.LinkedRoles may have an optional 'id'
					const linkedRolesId = (kind as {navigationType: GuildNavKind.LinkedRoles; id?: string}).id;

					if (linkedRolesId) {
						return (
							<span key={key} className={markupStyles.mention}>
								{`<id:linked-roles:${linkedRolesId}>`}
							</span>
						);
					}

					return (
						<span key={key} className={markupStyles.mention}>
							{'<id:linked-roles>'}
						</span>
					);
				}

				default:
					return (
						<span key={key} className={markupStyles.mention}>
							{`<id:${navigationType}>`}
						</span>
					);
			}
		}

		default:
			return (
				<span key={key} className={markupStyles.mention}>
					{'<unknown-mention>'}
				</span>
			);
	}
}
